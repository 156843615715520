import React, { useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import Colors from "../../components/Colors";
import { useOrderService } from "../../contexts/orderService";
import { FiShoppingBag } from "react-icons/fi";
import { BiBarcodeReader } from "react-icons/bi";
import Helper from "../../utils/Helper";
import { getSubDomain, isEnvProduction } from "../../utils/url";
import { useColor } from "../../contexts/colors";
import CodeReaderScreen from "./CodeReaderScreen/CodeReaderScreen";

export default function Bag({ history }) {
  const { bag } = useOrderService();
  let company = getSubDomain();
  const { buttonColor, buttonTextColor } = useColor();
  const [showCodeReader, setShowCodeReader] = useState(false);

  let price = 0;

  bag.forEach(({ item, amount, complements }) => {
    price += Helper.calculateValueItem(item, amount, complements);
  });

  const navigateToDetailOrder = () => {
    const path = isEnvProduction()
      ? `/detailOrder/`
      : `/${company}/detailOrder/`;
    history.push(path, { bag });
  };

  return (
    <TouchableOpacity
      onPress={navigateToDetailOrder}
      style={[styles.container, { backgroundColor: buttonColor }]}
    >
      <View style={styles.iconContainer}>
        {/*  <TouchableOpacity onPress={() => setShowCodeReader(true)} style={styles.iconTextContainer}>
          <Text style={[styles.icon, { color: buttonTextColor }]}><BiBarcodeReader /></Text>
          <Text style={[styles.iconLabel, { color: buttonTextColor }]}>Ler Código</Text>
        </TouchableOpacity> */}
        <View style={styles.iconTextContainer}>
          <Text style={[styles.icon, { color: buttonTextColor }]}>
            <FiShoppingBag />
          </Text>
          {/*           <Text style={[styles.iconLabel, { color: buttonTextColor }]}>Sacola</Text>
           */}
        </View>
      </View>
      <View style={styles.labelContainer}>
        <Text style={[styles.iconLabel, { color: buttonTextColor }]}>
          <b>Sacola</b>
        </Text>
      </View>
      <View style={styles.priceContainer}>
        <Text
          style={[styles.price, { fontWeight: "bold", color: buttonTextColor }]}
        >
          R$ {Helper.maskMoney(price)}
        </Text>
        <Text style={[styles.subText, { color: buttonTextColor }]}>
          {bag.reduce((a, item) => a + item.amount, 0)} Produtos
        </Text>
      </View>
      {showCodeReader && (
        <CodeReaderScreen onClose={() => setShowCodeReader(false)} />
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    zIndex: 10,
    width: "100%",
    padding: 15,
    alignItems: "center",
    justifyContent: "space-between",
    position: "fixed",
    flexDirection: "row",
    bottom: 0,
  },
  iconContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  iconTextContainer: {
    alignItems: "center",
    marginRight: 20,
  },
  icon: {
    fontSize: 35,
  },
  iconLabel: {
    marginTop: 5,
    fontSize: 14,
  },
  labelContainer: {
    flex: 1,
    alignItems: "center",
  },
  priceContainer: {
    alignItems: "flex-end",
  },
  price: {
    fontSize: 16,
  },
  subText: {
    fontSize: 12,
    marginTop: 8,
  },
});
