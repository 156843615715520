import React, { useState, useEffect, useContext } from "react";
import {
  Image,
  ScrollView,
  StyleSheet,
  View,
  TouchableWithoutFeedback,
  SafeAreaView,
  Text,
} from "react-native";
import { InputCountItem } from "../../components/form/InputCountItem";
import { useOrderService } from "../../contexts/orderService";
import Helper from "../../utils/Helper";
import GroupComplement from "../../components/GroupComplement";
import ItemObservation from "../../components/ItemObservation";
import Colors from "../../components/Colors";
import NavBar from "../../components/NavBar";
import apiDelivery from "../../Api/apiDelivery";
import notPicture from "../../assets/images/notPicture.jpg";
import { getSubDomain, isEnvProduction } from "../../utils/url";
import { useParams } from "react-router-dom";
import { useColor } from "../../contexts/colors";
import AnimatedImage from "./AnimatedImage";
import styles from "./ItemDetail.styles";
import { useProduct } from "../../contexts/ProductContext";
import { getApiUrl } from "../../utils/urlHelper"; 

export default function ItemDetail({ location, history }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [isDirectionAccess, setIsDirectionAccess] = useState(false);
  const { scannerProduct } = useProduct();
  const { productId } = useParams();

  const company = getSubDomain();

  useEffect(() => {
    async function getDataProduct() {
      if (location.state) {
        setData({ ...location.state });
      } else {
        const {
          data: { response },
        } = await apiDelivery.get(
          getApiUrl(`product/${scannerProduct ? scannerProduct : productId}`)
        );
        setData({
          item: response,
          amountSelected: null,
          selectedComplements: null,
          id: null,
          observationItem: null,
          restaurant: null,
        });

        setIsDirectionAccess(true);
      }

      setLoading(true);
    }

    getDataProduct();
  }, [location.state, scannerProduct, productId]);

  return loading ? (
    <ItemScreen
      history={history}
      isDirectionAccess={isDirectionAccess}
      {...data}
    />
  ) : null;
}

function ItemScreen({
  item,
  amountSelected,
  selectedComplements,
  id,
  observationItem,
  restaurant,
  history,
  isDirectionAccess,
}) {
  const {
    addItem,
    addEstablishment,
    editItem,
    setVoucherValue,
    isHigherValue,
  } = useOrderService();

  useEffect(() => {
    setVoucherValue([]);
  }, []);

  const company = getSubDomain();

  const [amount, setAmount] = useState(1);
  const [complements, setComplements] = useState([]);
  const [groupComplement, setGroupComplement] = useState([]);
  const [observation, setObservation] = useState(observationItem || "");
  const [loading, setLoading] = useState(false);
  let isDisabled = checkItemsRequired();
  const { buttonColor, buttonTextColor } = useColor();
  const { scannerProduct } = useProduct();

  useEffect(() => {
    async function getDetailProduct() {
      const {
        data: { response },
      } = await apiDelivery.get(
        getApiUrl(`product/group_complements/${scannerProduct ? scannerProduct : item.id}`)
      );
      setGroupComplement(response);
      if (amountSelected != null) setAmount(amountSelected);
      if (selectedComplements != null) setComplements(selectedComplements);
    }

    getDetailProduct();
  }, [scannerProduct, item.id, amountSelected, selectedComplements]);

  function checkItemsRequired() {
    return (
      groupComplement
        .filter((group) => {
          return group.required == true;
        })
        .filter((group) => {
          const groupComplementSelected = complements.find((complement) => {
            return complement.groupId === group.id;
          });

          if (!groupComplementSelected) return true;

          return groupComplementSelected.items.length < group.minimum_quantity;
        }).length != 0
    );
  }

  function onChangeItems(items, id, name) {
    const groupComplementSelected = complements.find((complement) => {
      return complement.groupId == id;
    });

    if (!groupComplementSelected) {
      setComplements([...complements, { groupId: id, items, name }]);
    } else {
      setComplements(
        complements.map((item) => {
          if (item.groupId === id) item.items = items;
          return item;
        })
      );
    }
  }

  function handleBack() {
    if (isEnvProduction()) {
      history.push(`/`);
    } else {
      history.push(`/${company}`);
    }
  }

  function addNewItem() {
    addEstablishment(restaurant);
    addItem(item, amount, complements, observation, isHigherValue);
    handleBack();
  }

  function editItemSelected() {
    editItem(id, item, amount, complements, observation, isHigherValue);
    handleBack();
  }

  return (
    <View>
      <SafeAreaView style={styles.container}></SafeAreaView>
      <View style={{ width: "100%" }}>
        <NavBar
          history={history}
          restaurant={restaurant}
          onBackPage={handleBack}
        />
      </View>
      <ScrollView
        style={styles.containerScroll}
        keyboardShouldPersistTaps="handled"
      >
        <View
          style={[
            styles.imageContainer,
            { overflow: "hidden", marginVertical: 5 },
          ]}
        >
          <AnimatedImage
            style={styles.logo}
            source={{ uri: !item.picture ? notPicture :  `https://bucket-zappedis.nyc3.digitaloceanspaces.com/${item.picture}` }}
          />
        </View>
        <View style={styles.content}>
          <Text h4 style={{ marginHorizontal: 8 }}>
            {item.name}
          </Text>
          <Text style={styles.descriptionItem}>{item.description}</Text>
          {parseFloat(item.sale_price) > 0 && (
            <Text style={styles.priceItem}>
              A partir de R$ {Helper.maskMoney(parseFloat(item.sale_price))}
            </Text>
          )}
        </View>
        <View style={{ marginVertical: 10 }}>
          {groupComplement.map((group, index) => {
            return (
              <GroupComplement
                key={index}
                group={group}
                highValue={group.is_higher_value}
                onChangeItems={onChangeItems}
                selectedComplements={selectedComplements}
              />
            );
          })}
        </View>
        {loading ? null : (
          <ItemObservation
            initValue={observation}
            onChange={(text) => {
              setObservation(text);
            }}
            description="Alguma observação?"
          />
        )}
      </ScrollView>
      {loading ? (
        <></>
      ) : (
        <View style={styles.footer}>
          <InputCountItem
            initValue={amountSelected}
            changeValue={(value) => {
              setAmount(value);
            }}
          />
          <TouchableWithoutFeedback
            style={styles.btnContainerAddItem}
            onPress={() => {
              if (selectedComplements == null) {
                addNewItem();
              } else {
                editItemSelected();
              }
            }}
            disabled={isDisabled}
          >
            <View
              style={
                isDisabled
                  ? [styles.btnAddItem, { backgroundColor: "#666" }]
                  : [
                      styles.btnAddItem,
                      { backgroundColor: buttonColor, color: buttonTextColor },
                    ]
              }
            >
              <Text style={[styles.btnTextItemAdd, { color: buttonTextColor }]}>
                {amountSelected != null ? "Atualizar" : "Adicionar"}
              </Text>
              <Text style={{ fontWeight: "bold", color: buttonTextColor }}>
                R$
                {Helper.maskMoney(
                  Helper.calculateValueItem(item, amount, complements)
                )}
              </Text>
            </View>
          </TouchableWithoutFeedback>
        </View>
      )}
    </View>
  );
}
