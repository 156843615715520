import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import Colors from "../../components/Colors";
import { useOrderService } from "../../contexts/orderService";
import { FiShoppingBag } from "react-icons/fi";
import Helper from "../../utils/Helper";
import { getSubDomain, isEnvProduction } from "../../utils/url";

export default function Bag({ history }) {
  const { bag } = useOrderService();
  let company = getSubDomain();

  if (bag.length === 0) return null;

  let price = 0;

  bag.map(({ item, amount, complements }) => {
    price += Helper.calculateValueItem(item, amount, complements);
    return [];
  });

  return (
    <TouchableOpacity
      onPress={() => {
        if(isEnvProduction()) {
          history.push(`/menu/detailOrder/`, { bag });
        } else {
          history.push(`/${company}/menu/detailOrder/`, { bag });
        }
       
      }}
    >
      <View style={styles.container}>
        <Text style={{ fontSize: 30, color: "#fff" }}>
          {/* <FiShoppingBag /> */}
        </Text>
        <Text style={styles.text}>Visualizar Pedido</Text>
        {/* <Text style={styles.price}>R$ {Helper.maskMoney(price)}</Text> */}
      </View>
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  container: {
    zIndex: 10,
    width: "100%",
    padding: 15,
    backgroundColor: Colors.PRIMARY,
    alignItems: "center",
    justifyContent: "space-between",
    position: "fixed",
    flexDirection: "row",
    bottom: 0,
    height: "50px"
  },
  text: {
    color: "#fff",
    fontSize: 16,
    position: "absolute",
    textAlign: "center",
    left: 0,
    right: 0,
    fontWeight: "bold",
  },
  price: {
    color: "#fff",
    fontSize: 16,
  },
});
