export default class Helper {
  static isEmailValid(email) {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  static maskMoney(value) {
    let number = value;
    number = number.toFixed(2).split(".");
    number[0] = number[0].split(/(?=(?:...)*$)/).join(".");
    return number.join(",");
  }

  static removeMaskMoney(value) {
    return parseFloat(value.replace(/\./g, "").replace(/\,/g, "."));
  }
  static validatePhone(phone) {
    const regex =
      /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;
    return regex.test(phone);
  }

  static calculateValueItem(item, amount, complements) {
    let price = parseFloat(item.sale_price);

    if (complements.length > 0) {
      const items = [];
      const highestPrevails = [];
      complements.map((complementGroup) => {
        complementGroup.items &&
          complementGroup.items.map((item) => {
            item.isHigherValue && highestPrevails.push(parseFloat(item.price));
            item.isHigherValue == false && items.push(parseFloat(item.price));
          });

        complementGroup.product_complement &&
          items.push(parseFloat(complementGroup.product_complement.price));
      });

      if (items.length > 0) {
        const value = items.reduce(
          (accumulator, currentValue) => accumulator + currentValue
        );
        price += value;
      }

      if (highestPrevails.length > 0) {
        const value = Math.max(...highestPrevails);
        price += value;
      }
    }

    return price * amount;
  }

  static sanitizeString(str){
    str = str.replace(/[^a-z0-9áéíóúñü .,-]/gim,"");
    const text = str.trim();
    const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/,:;'
    const b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
    const p = new RegExp(a.split('').join('|'), 'g')
    return text.toString().toLowerCase().trim()
      .replace(p, c => b.charAt(a.indexOf(c)))
      .replace(/&/g, '-and-')
      .replace(/[\s\W-]+/g, '-')
  }

  static getComplementByQuantity(group) {
    const accumulatorComplements = [];
    group.map(({ items }) => {
      items.map((complement) => {
        const item = accumulatorComplements.find(({ deliveryComplementId }) => {
          return deliveryComplementId == complement.id;
        });

        if (item != null) {
          item.quantity += 1;
        } else {
          accumulatorComplements.push({
            deliveryComplementId: complement.id,
            quantity: 1,
            higherValue: complement.isHigherValue,
          });
        }
      });
    });

    return accumulatorComplements;
  }

  static onlyNumbers(value) {
    return value.replace(/\D/g, "");
  }
}
