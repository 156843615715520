import firebase from "firebase"

const firebaseConfig = {
  apiKey: "AIzaSyAFN4bV4bSZV5goUqB9m0K3KkkAkwQbB-8",
  authDomain: "zappedis-front.firebaseapp.com",
  projectId: "zappedis-front",
  storageBucket: "zappedis-front.appspot.com",
  messagingSenderId: "407491412302",
  appId: "1:407491412302:web:020bb98c606161b74dde1f"
};

firebase.initializeApp(firebaseConfig);
