import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import Modal from "modal-react-native-web";
import { useOrderService } from "../../contexts/orderService";
import Colors from "../Colors/";
import InputTextWithMask from "../../components/InputTextWithMask/InputTextWithMask";
import Helper from "../../utils/Helper";
import { toast, ToastContainer } from "react-toastify";
import { useColor } from "../../contexts/colors";

export default function ModalChange({ onClose, printVoucherValue }) {
  const {
    paymentForm,
    changeMoney,
    SelectedChangeMoney,
    bag,
    neighborhood,
    voucherSelected,
    totalVoucher,
    orderMode,
    visibleModalChange,
    isFreeShipping,
    setVisibleModalChange,
  } = useOrderService();
  const {buttonColor, buttonTextColor} = useColor();
  const [moneyBack, setMoneyBack] = useState(0);
  const [moneyBackLabel, setMoneyBackLabel] = useState("Não Preciso de Troco");
  const [loading, setLoading] = useState(true);

  function calculateTotalBag() {
    const priceItems = calculateTotalItems();
    if (neighborhood != null && orderMode === "1" && !isFreeShipping)
      return priceItems + parseFloat(neighborhood.price);

    return priceItems;
  }

  function calculateTotalItems() {
    const list = bag.map(({ item, amount, complements }) => {
      return Helper.calculateValueItem(item, amount, complements);
    });
    if (list.length > 0) {
      const value = list.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      });

      return value;
    }
    return 0;
  }

  const changeMoneyBack = (value) => {
    const price = Helper.removeMaskMoney(value);
    if (price > 0) {
      setMoneyBackLabel("Enviar");
      setMoneyBack(price);
      SelectedChangeMoney(price);
    } else {
      setMoneyBack(0);
      SelectedChangeMoney("");
      setMoneyBackLabel("Não Preciso de Troco");
    }
  };

  useEffect(() => {
    if (!loading)
      if (
        paymentForm != null &&
        changeMoney === 0 &&
        (orderMode === "1" || orderMode == null)
      ) {
        setMoneyBack(0);
        setVisibleModalChange(paymentForm.change);
      }

    setLoading(false);
  }, [paymentForm, changeMoney, orderMode]);

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visibleModalChange}
      onRequestClose={() => {
        setVisibleModalChange(false);
      }}
    >
      <View
        style={styles.container}
        onPress={() => {
          onClose();
        }}
      >
        <TouchableWithoutFeedback>
          <View style={styles.overlayModal}></View>
        </TouchableWithoutFeedback>
        <View style={styles.modalView}>
          <Text style={styles.textDescription}>
            {voucherSelected &&
              calculateTotalBag() > totalVoucher &&
              "Total: R$" +
                Helper.maskMoney(calculateTotalBag() - totalVoucher) +
                ", envie troco para"}
            {voucherSelected &&
              calculateTotalBag() < totalVoucher &&
              "Total: R$" +
                Helper.maskMoney(totalVoucher - calculateTotalBag()) +
                ", envie troco para"}
            {!voucherSelected &&
              "Total: R$" +
                Helper.maskMoney(calculateTotalBag()) +
                ", envie troco para"}
          </Text>
          <TouchableOpacity>
            <View style={styles.btnOptionItem}>
              <InputTextWithMask
                style={styles.textInputAdress}
                value={moneyBack}
                onChange={changeMoneyBack}
              />
            </View>
            <TouchableOpacity
              style={[styles.btn, {backgroundColor: buttonColor}]}
              onPress={() => {
                if (
                  (!voucherSelected &&
                    moneyBack <= calculateTotalBag() &&
                    moneyBack != 0) ||
                  (voucherSelected &&
                    moneyBack <= calculateTotalBag() - printVoucherValue &&
                    moneyBack != 0)
                )
                  return toast.dark("Valor menor ou igual ao total do pedido");
                SelectedChangeMoney(changeMoney || 0);
                setVisibleModalChange(false);
              }}
            >
              <Text style={{ color: buttonTextColor }}>{moneyBackLabel}</Text>
            </TouchableOpacity>
          </TouchableOpacity>
        </View>
      </View>
      <ToastContainer hideProgressBar={true} pauseOnHover />
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  overlayModal: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    backgroundColor: "#fff",
    paddingHorizontal: 15,
  },
  textDescription: {
    fontSize: 20,
    textAlign: "center",
    padding: 20,
    marginBottom: 5,
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
  },
  btnOptionItem: {
    borderBottomWidth: 1,
    borderColor: "#ddd",
    padding: 40,
    alignItems: "center",
  },
  btnOptionItemText: {
    color: Colors.PRIMARY,
    fontSize: 18,
  },
  btn: {
    width: "100%",
    height: 50,
    borderRadius: 5,
    backgroundColor: Colors.PRIMARY,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 10,
  },
  textInputAdress: {
    borderBottomWidth: 1,
    borderColor: "gray",
    fontSize: 20,
    textAlign: "center",
    padding: 5,
  },
  IconClose: {
    fontSize: 20,
  },
});
