import React, { useState, useEffect } from 'react';
import {Text, StyleSheet, View, TouchableOpacity} from 'react-native'
import { useOrderService } from "../../contexts/orderService";
import Colors from '../Colors'
import Helper from '../../utils/Helper';
import { useColor } from '../../contexts/colors';

export default function Change() {
 const { paymentForm,changeMoney, SelectedChangeMoney, orderMode,setVisibleModalChange, voucherSelected } = useOrderService();
    const [visible,setVisible] = useState(false);
    const {buttonColor} = useColor();
     
    useEffect(()=>{
        if(paymentForm != null){
            setVisible(paymentForm.change && (orderMode === "1" || orderMode == null))
        } else {
            setVisible(false);
        }
    },[paymentForm,orderMode])

  return (
    visible  ? 
        (<TouchableOpacity  style={styles.containerChange} 
        onPress={()=>{
            setVisibleModalChange(true)
        }}>
            <View style={styles.btnSelectedChange}>
                <Text style={[styles.titleChange]}>
                    Troco
                </Text>
                <Text style={[styles.titleSwitchChange, {color: buttonColor}]} numberOfLines={1}>
                    {changeMoney ?  Helper.maskMoney(changeMoney) : "Sem troco"}
                </Text>
            </View>
        </TouchableOpacity>) : null
  );
}


const styles = StyleSheet.create({
    containerChange: {
        marginTop: 10,

        backgroundColor: "#fff",
        paddingHorizontal: 15,
    },  
    btnSelectedChange: {
        display: "flex",
        paddingVertical: 20,
    },
    titleChange: {
        fontSize: 15
    },
    titleSwitchChange: {
        fontSize: 15,
        color: Colors.PRIMARY,
    }
})

