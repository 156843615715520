import React, {useState} from 'react';
import { View,
         ImageBackground,
         Image,
         StyleSheet,
         Text,
         TouchableOpacity
        } from 'react-native';
import ModalscheduleEstablishmentFashion from '../ModalscheduleEstablishmentFashion/index';
import ModalHourFashion from '../ModalHourFashion/Index';

export default function BannerProductFashion(){

  const [visibleModalschedule, setVisibleModalschedule] = useState(false);
  const [ visibleModalHour, setVisibleModalHour ] = useState(false);

    return(
    <View style={styles.containerItem}>
        <ImageBackground
          style={styles.bgImage}
          source={'https://pbs.twimg.com/profile_images/1108004580967763969/psVck60K.png'}
          resizeMode={"cover"}
          blurRadius={3}
        />
        <Image
          resizeMode={"center"}
          source={'https://pbs.twimg.com/profile_images/1108004580967763969/psVck60K.png'}
          style={styles.logoItem}
        />
         <View style={styles.contentRestaurant}>
            <ButtonOptionRestaurant
              title="Ver área de entrega"
              onPress={() => {
                setVisibleModalschedule(true)
              }}
            />
            <ButtonOptionRestaurant
              onPress={() => {
                setVisibleModalHour(true)
              }}
              title={"Fechado"}
              color={"red"}
            />
         </View>
         <ModalHourFashion
           visible={visibleModalHour}
            onClose={() => 
             { setVisibleModalHour(false)}}
          />
          <ModalscheduleEstablishmentFashion
            visible={visibleModalschedule}
            onClose={() => {
              setVisibleModalschedule(false)
            }}
          />
    </View>
)
}
function ButtonOptionRestaurant(props) {
  
    const { title, color, onPress } = props;
    return (
      <View style={styles.contentOptionBtn}>
        <TouchableOpacity onPress={onPress}>
          <View style={styles.optionBtn}>
            <Text
              style={{
                color: color || "#666",
                fontWeight: "500px",
                fontSize: 14,
              }}
            >
              {title}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  }

const styles = StyleSheet.create({
    containerItem: {
        width: "100%",
        backgroundColor: "#fff",
    },
    bgImage: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        color: "white",
        height: 170,
        width: "100%",
        marginTop: -40,
    },
    logoItem: {
        width: "27%",
        height: 95,
        marginTop: -45,
        backgroundColor: "white",
        margin: "auto",
        borderWidth: 1,
        borderRadius: 5,
        borderColor: "#eee",
        borderBottomWidth: 2,
        shadowColor: "#000",
        shadowOffset: { width: 1, height: 6 },
        shadowOpacity: 100,
        shadowRadius: 5,
        elevation: 1,
    },
    contentOptionBtn: {
        flex: 1,
        marginHorizontal: 5,
    },
    optionBtn: {
        flex: 1,
        borderWidth: 1,
        borderColor: "#ddd",
        borderRadius: 25,
        alignItems: "center",
        justifyContent: "center",
        padding: 10,
      },
      contentRestaurant: {
        display: "flex",
        flexDirection: "row",
        paddingVertical: 25,
        backgroundColor: "#fff",
        borderBottomColor: "#ddd",
        borderBottomWidth: 1,
      },
});