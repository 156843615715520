import React, {useState} from "react";
import {View, Text, StyleSheet, TouchableOpacity} from "react-native";
import Colors from "../../components/Colors";
import {useOrderService} from "../../contexts/orderService";
import {IoIosBarcode} from "react-icons/io";
import {AiOutlineShoppingCart} from "react-icons/ai";
import Helper from "../../utils/Helper";
import {getSubDomain, isEnvProduction} from "../../utils/url";
import {useColor} from "../../contexts/colors";
import { useHistory } from "react-router-dom";

export default function BagHonestMarket() {
    const history = useHistory();
    const {bag} = useOrderService();
    let company = getSubDomain();
    const {buttonColor, buttonTextColor} = useColor();

    let price = 0;

    bag.map(({item, amount, complements, higherValue}) => {
        price += Helper.calculateValueItem(item, amount, complements);
        return [];
    });

    return (
        <View style={
            [
                styles.container,
                bag.length > 0 && {justifyContent: 'space-between'}, {
                    backgroundColor: buttonColor,
                    color: buttonTextColor
                }
            ]
        }>
            <TouchableOpacity onPress={
                () => {
                    if (isEnvProduction()) {
                        history.push(`/product/scanner`);
                    } else {
                        history.push(`/${company}/product/scanner`);
                    }
                }
            }>
                <View style={
                    {
                        alignItems: 'center'
                    }
                }>
                    <IoIosBarcode size={40}
                        color={buttonTextColor}/>
                        <Text style={
                            {
                                fontSize: 8,
                                color: buttonTextColor
                            }
                        }>Ler codigo de barras</Text>
                </View>
            </TouchableOpacity>
            {
            bag.length > 0 && <TouchableOpacity onPress={
                () => {
                    if (isEnvProduction()) {
                        history.push(`/detailOrder/`, {bag});
                    } else {
                        history.push(`/${company}/detailOrder/`, {bag});
                    }
                }
            }>
                <View style={
                    styles.containerBag
                }>
                     <View style={
                    {
                        alignItems: 'center',
                        marginLeft: '-20%'
                    }
                }>
                    <AiOutlineShoppingCart size={30}
                        color={buttonTextColor}/>
                        
                    <Text style={[styles.text, {color: buttonTextColor}]}>Sacola</Text>
                </View>
                    <Text style={[styles.price, {color: buttonTextColor}]}>R$ {Helper.maskMoney(price)}</Text>
                </View>
            </TouchableOpacity>
        } </View>
    );
}
const styles = StyleSheet.create({
    container: {
        zIndex: 10,
        width: "100%",
        padding: 15,
        alignItems: "center",
        position: "fixed",
        justifyContent: 'center',
        flexDirection: "row",
        bottom: 0
    },
    containerBag: {
        flexDirection: "row",
        display: "flex",
        width: "100%",
        alignItems: 'center',
      },
    text: {
        color: "#fff",
        fontSize: 8,
        textAlign: "center",
        left: 0,
        right: 0,
    },
    price: {
        color: "#fff",
        fontSize: 16,
        marginLeft: '5%'
    }
});
