import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { FaEllipsisV } from "react-icons/fa";
import Colors from "../../components/Colors";
import OptionItemModal from "../../components/OptionItemModal";
import Helper from "../../utils/Helper";
import { useOrderService } from "../../contexts/orderService";
import FormSign from "../../components/FormSign/FormSign";
import OptionsPayment from "../../components/OptionsPayment";
import msgWhats from "../../components/MsgWhats/MsgWhats";
import { useCookies } from "react-cookie";
import NavBar from "../../components/NavBar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import apiDelivery from "../../Api/apiDelivery";
import { useHistory } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import SelectDeliveryMethod from "../../components/SelectDeliveryMethod/SelectDeliveryMethod";
import Change from "../../components/Change/Change";
import ModalChange from "../../components/Change/ModalChange";
import { getSubDomain, isEnvProduction } from "../../utils/url";

const windowHeight = Dimensions.get("window").height;

export default function BagDetailMenu({ route, navigation }) {
  const {
    bag,
    neighborhood,
    removeItem,
    paymentForm,
    changeMoney,
    orderMode,
    changeOrderMode,
    company: companyData,
    isFreeShipping,
    getOrderCode,
  } = useOrderService();

  const history = useHistory();
  const [product, setProduct] = useState(null);
  const [visibleModalPayment, setVisibleModalPayment] = useState(false);
  const [visibleFormSign, setVisibleFormSign] = useState(false);
  // const [phone, setPhone] = useState([]);
  const [cookies] = useCookies(["user"]);
  const [user, setUser] = useState(null);
  let company = getSubDomain();

  useEffect(() => {
    if (bag.length === 0) {
      history.goBack();
    }
    function getUserData() {
      if (!cookies.user) {
        setVisibleFormSign(true);
      } else {
        setUser(cookies.user);
      }
    }
    getUserData();
  }, [cookies]);

  
 

  async function sendOrderCode() {
    let items = bag.map((item) => {
      const complements = Helper.getComplementByQuantity(item.complements);
      return {
        quantity: item.amount,
        product_id: item.item.id,
        price: item.item.sale_price,
        obs: item.observation,
        complements: complements.map((complement) => {
          return {
            quantity: complement.quantity,
            product_complement_id: complement.deliveryComplementId,
          };
        }),
      };
    });

    return apiDelivery.post(`${company}/order`, {
      neighborhood_id: neighborhood.id, 
      company_id: companyData.id,
      total_order: calculateTotalBag(),
      is_free_shipping: isFreeShipping,
      change: changeMoney,
      customer_id: user.id,
      form_of_payment_id: paymentForm ? paymentForm.id : null,
      items: items,
    });
  }
  function calculateTotalBag() {
    const priceItems = calculateTotalItems();

    if (neighborhood != null && orderMode === "1" && !isFreeShipping)
      return priceItems + parseFloat(neighborhood.price);

    return priceItems;
  }
  function calculateTotalItems() {
    const list = bag.map(({ item, amount, complements }) => {
      return Helper.calculateValueItem(item, amount, complements);
    });

    if (list.length > 0) {
      const value = list.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      });
      return value;
    }
    return 0;
  }

  async function handleOrder() {
    toast.dismiss();

    if (user == null) return toast.dark("Informe os seus dados");
    if (orderMode == null || orderMode === 0 || orderMode === 1)
      return toast.dark("Informe o método de entrega");
    if (paymentForm == null) return toast.dark("Informe a forma de pagamento");

    sendOrderCode().then(({ data: { response } }) => {
      msgWhats({
        response,
        bag,
        companyData,
        paymentForm,
        user,
        changeMoney,
        neighborhood,
        orderMode,
        changeOrderMode,
        getOrderCode,
      });
      //MsgConfirmationUser(company, user)
      //history.goBack();
      //SelectedChangeMoney(0)
      //clearOrder();
    });
  }
  return (
    <View style={styles.container}>
      <FormSign
        company={companyData}
        visible={visibleFormSign}
        onClose={() => {
          setVisibleFormSign(false);
        }}
        history={history}
      />
      <ModalChange />
      <OptionsPayment
        visible={visibleModalPayment}
        onClose={() => setVisibleModalPayment(false)}
      />

      <OptionItemModal
        product={product}
        navigation={navigation}
        onEditItem={() => {
          if (isEnvProduction()) {
            history.push(`/product/`, {
              id: product.id,
              amountSelected: product.amount,
              item: product.item,
              selectedComplements: product.complements,
              observationItem: product.observation,
            });
          } else {
            history.push(`/${company}/product`, {
              id: product.id,
              amountSelected: product.amount,
              item: product.item,
              selectedComplements: product.complements,
              observationItem: product.observation,
            });
          }
        }}
        onRemove={() => {
          removeItem(product.id);
          if (isEnvProduction()) {
            history.push(`/`, {
              id: product.id,
              amountSelected: product.amount,
            });
          } else {
            history.push(`/${company}`, {
              id: product.id,
              amountSelected: product.amount,
            });
          }
        }}
        onClose={() => {
          setProduct(null);
        }}
      />
      <NavBar history={history} />
      <ScrollView style={styles.content}>
        
        
        <View style={styles.containerEstablishment}>
          <Text style={{ fontSize: 15, fontWeight: "600" }}>Pedido</Text>
        </View>
        <View style={styles.listItens}>
          {bag.map(({ item, amount, complements, id, observation }, index) => {
            const listComplements = [];
            complements.map((group) => {
              group.items.map((item) => {
                let objectSelected = listComplements.find((object) => {
                  return object.complement.id === item.id;
                });
                if (objectSelected != null) {
                  objectSelected.amount++;
                } else {
                  listComplements.push({ complement: item, amount: 1 });
                }
              });
            });

            return (
              <View key={index} style={styles.containerItem}>
                <View style={styles.contentTitleItem}>
                  <View style={styles.titleItem}>
                    <Text style={styles.titleAmountItem}>{amount}x </Text>
                    <Text style={styles.titleItemText}>{item.name}</Text>
                    <Text style={styles.titleAmountItem}>
                      R${" "}
                      {Helper.maskMoney(
                        Helper.calculateValueItem(item, amount, complements)
                      )}
                    </Text>
                  </View>
                  {listComplements.map(({ complement, amount }) => {
                    return (
                      <View style={styles.titleItem} key={complement.id}>
                        <Text style={styles.textComplementItem}>
                          - {amount}x {complement.name}
                        </Text>
                      </View>
                    );
                  })}
                  {observation.length > 0 ? (
                    <View style={styles.titleItem}>
                      <Text style={styles.textComplementItem}>
                        Observação: {observation}
                      </Text>
                    </View>
                  ) : null}
                </View>
                <TouchableOpacity
                  style={styles.btnOptionItem}
                  onPress={() => {
                    setProduct({ id, item, amount, complements, observation });
                  }}
                >
                  <FaEllipsisV />
                </TouchableOpacity>
              </View>
            );
          })}
        </View>
        <View style={styles.containerTotals}>
          {neighborhood != null && orderMode === "1" && !isFreeShipping ? (
            <View style={styles.contentItemTotals}>
              <Text style={styles.subtotalsText}>Entrega padrão</Text>
              <Text style={styles.subtotalsText}>
                {" "}
                R$ {Helper.maskMoney(parseFloat(neighborhood.price))}
              </Text>
            </View>
          ) : null}
          <View style={styles.contentItemTotals}>
            <Text style={styles.totalsText}>Total</Text>
            <Text style={styles.totalsText}>
              R$ {Helper.maskMoney(calculateTotalBag())}
            </Text>
          </View>
        </View>
        <Change />
        <View style={styles.containerPaymentForm}>
          <TouchableOpacity
            onPress={() => {
              setVisibleModalPayment(true);
            }}
          >
            {/* <View style={styles.btnSelectPaymentForm}>
              <Text style={styles.titlePaymentFormText}>
                Formas de pagamento
              </Text>
              <Text style={styles.titleSwitchPaymentForm} numberOfLines={1}>
                {paymentForm != null ? paymentForm.description : "Escolher"}
              </Text>
            </View> */}
          </TouchableOpacity>
        </View>
      </ScrollView>
      <View style={styles.footer}>
        <TouchableOpacity
          onPress={() => {
            handleOrder();
          }}
        >
          <View style={styles.btnFinish}>
            <Text style={styles.btnFinishText}>Confirmar Pedido</Text>
          </View>
        </TouchableOpacity>
      </View>
      <ToastContainer hideProgressBar={true} pauseOnHover />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: windowHeight,
  },
  content: {
    flex: 1,
    backgroundColor: "#ddd",
  },
  footer: {
    backgroundColor: "#fff",
    borderTopColor: "#ddd",
    borderTopWidth: 1,
  },
  header: {
    marginTop: 10,
    padding: 20,
    paddingHorizontal: 20,
    backgroundColor: "#fff",
  },
  btnLocation: {
    display: "flex",
    flexDirection: "row",
    marginVertical: 5,
    alignItems: "center",
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
    paddingHorizontal: 5,
  },
  iconLocal: {
    width: 150,
    left: -20,
  },
  contentLocal: {
    flex: 1,
  },
  titleDeliveryLocal: {
    fontSize: 14,
    color: "#555",
    bottom: -25,
    left: 15,
  },
  titleLocal: {
    fontSize: 18,
  },
  contentDescriptionInfoDelivery: {
    padding: 10,
  },
  descriptionTitleLocal: {
    fontSize: 14,
  },
  subtitleLocal: {
    fontSize: 13,
    color: "#666",
  },
  listItens: {
    flex: 1,
    backgroundColor: "#fff",
  },
  containerItem: {
    display: "flex",
    flexDirection: "row",
    marginHorizontal: 15,
    paddingVertical: 20,
    borderColor: "#ddd",
    borderBottomWidth: 1,
    alignItems: "center",
  },
  contentTitleItem: {
    flex: 1,
  },
  titleItem: {
    display: "flex",
    flexDirection: "row",
  },
  titleItemText: {
    fontSize: 16,
    flex: 1,
    left: 10,
    marginRight: 25,
  },
  titleAmountItem: {
    fontSize: 16,
  },
  textComplementItem: {
    fontSize: 11,
    color: "#999",
    marginLeft: 5,
  },
  btnOptionItem: {
    padding: 10,
  },
  containerTotals: {
    padding: 15,
    backgroundColor: "#fff",
  },
  contentItemTotals: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  subtotalsText: {
    color: "#555",
  },
  totalsText: {
    fontSize: 18,
  },
  containerPaymentForm: {
    marginTop: 10,
    marginBottom: 30,
    backgroundColor: "#fff",
    paddingHorizontal: 15,
  },
  titlePaymentForm: {
    fontSize: 18,
    paddingVertical: 20,
    borderBottomColor: "#ccc",
    borderBottomWidth: 1,
  },
  btnSelectPaymentForm: {
    display: "flex",
    paddingVertical: 30,
  },
  titlePaymentFormText: {
    fontSize: 15,
  },
  titleSwitchPaymentForm: {
    fontSize: 15,
    color: Colors.PRIMARY,
  },
  btnFinish: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    padding: 15,
    color: "#fff",
    backgroundColor: Colors.PRIMARY,
    alignItems: "center",
    justifyContent: "center",
  },
  btnFinishText: {
    color: "#fff",
    fontSize: 18,
  },
  containerEstablishment: {
    marginTop: 10,
    padding: 20,
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
  },
  textInput: {
    fontSize: 16,
    paddingTop: 5,
    color: "#555",
  },
});
