import React from "react";
import { View, StyleSheet, Text } from "react-native";
import Colors from "../Colors";
import { TouchableWithoutFeedback, TouchableOpacity } from "react-native";
import Modal from "modal-react-native-web";
import { useColor } from "../../contexts/colors";
export default function ModalConfirmationOrder(props) {
    const { visible, onClose, order } = props;
    const {buttonColor} = useColor();

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
        >
            <View style={styles.container}>
                <TouchableWithoutFeedback
                    onPress={() => {
                    onClose();
                    }}
                >
                <View style={styles.overlayModal}></View>
                </TouchableWithoutFeedback>

                <View style={styles.modalView}>
                    <Text style={styles.textDescription}>
                        Confirmar Pedido?
                    </Text>
                    <TouchableOpacity
                        onPress={() => order()}
                    >
                        <View style={styles.btnOptionItem}>
                            <Text style={[styles.btnOptionItemText, {color: buttonColor}]}>
                                Sim
                            </Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => onClose()}
                    >
                        <View style={styles.btnOptionItem}>
                            <Text style={[styles.btnOptionItemText, {color: buttonColor}]}>
                                Não
                            </Text>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    overlayModal: {
        flex: 1,
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    modalView: {
        backgroundColor: "#fff",
        paddingHorizontal: 15,
    },
    textDescription: {
        fontSize: 20,
        textAlign: "center",
        padding: 15,
        marginBottom: 5,
        borderBottomColor: "#ddd",
        borderBottomWidth: 1,
    },
    btnOptionItem: {
        borderBottomWidth: 1,
        borderColor: "#ddd",
        padding: 10,
        alignItems: "center",
    },
    btnOptionItemText: {
        color: Colors.PRIMARY,
        fontSize: 18,
    },
});
