import firebase from "firebase"
import "firebase/firebase-messaging"

export function getToken() {
  const messaging = firebase.messaging();
  return messaging.getToken({ vapidKey: 'BGr5Q6CYWejmTGKkbHfx5DBUnp2H9uQY-KUWF5gK_A8sYnOtnDcNrys6eayZyZ3922dbmTRZZSimRx-z6Ue-2-o' }).then((currentToken) => {
    if (currentToken) {
      return currentToken
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}
