import React, { useState, useEffect, useRef } from "react";
import {
  TextInput,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Picker,
  FlatList,
  ActivityIndicator,
} from "react-native";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import SelectNeighborhoods from "../SeletectNeighborhoods/SelectNeighborhoods";
import Modal from "modal-react-native-web";
import NavBar from "../NavBar";
import { useCookies } from "react-cookie";
import ModalNullNeightborhood from "../ModalNullNeightborhood/ModalNullNeightborhood";
import { useOrderService } from "../../contexts/orderService";
import InputPhoneMask from "../InputPhoneMask/InputPhoneMask";
import Helper from "../../utils/Helper";
import { toast, ToastContainer } from "react-toastify";
import apiDelivery from "../../Api/apiDelivery";
import ModalVerificationUser from "../ModalVerificationUser/ModalVerificationUser";
import { getSubDomain, isEnvProduction } from "../../utils/url";
import { useColor } from "../../contexts/colors";
import { debounce } from "lodash";
import { styles } from "./FormSign.style";
import { roles } from "./Role";
import ModalAccountExist from "../ModalAccountExist/ModalAccountExist";

export default function FormSign(props) {
  const {
    neighborhood,
    paymentForm,
    getToken,
    isEdgeOrChrome,
    isFirefox,
    company: companySelected,
    changeNeighborhood,
  } = useOrderService();

  const { buttonColor, buttonTextColor } = useColor();

  const [accountStorage, setAccountStorage] = useState(false);
  const [cookies, setCookies] = useCookies(["user"]);
  const [optionalEmail, setOptionalEmail] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [gender, setGender] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [zip, setZip] = useState("");
  const [addressNeighborhood, setAddressNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [profession, setProfession] = useState(null);
  const [complement, setComplement] = useState(null);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [referencePoint, setReferencePoint] = useState("");
  const [user, setUser] = useState([]);
  const [token, setToken] = useState(null);
  const [visibleModalNull, setVisibleModalNull] = useState(false);
  const [visibleModalUser, SetVisibleModalUser] = useState(false);
  const [userId, setUserId] = useState(0);

  const [search, setSearch] = useState("");
  const [clicked, setClicked] = useState(false);
  const [data, setData] = useState(roles);
  const [selectedRole, setSelectedRole] = useState("");
  const searchRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(paymentForm?.activate_acquirer){
      setOptionalEmail(true);
    }else {
      setOptionalEmail(false);
    }
  }, [paymentForm]);

  useEffect(() => {
    if(!cookies.user){
      setAccountStorage(true);
    }else {
      setAccountStorage(false);
    }
  }, [cookies.user]);

  const debouncedFetchAddress = useRef(debounce((inputAddress) => {
    if (!inputAddress) {
      setSuggestions([]);
      setIsLoading(false);
      return;
    }

    const autocompleteService = new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      { input: inputAddress },
      (predictions, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          setSuggestions(
            predictions.map((prediction) => ({
              id: prediction.place_id,
              description: prediction.description,
            }))
          );
        } else {
          setSuggestions([]);
        }
        setIsLoading(false);
      }
    );
  }, 1500)).current;

  const debouncedOnSearch = useRef(debounce((search) => {
    const filteredData = roles.filter((item) => {
      return item.name.toLowerCase().includes(search.toLowerCase());
    });

    if (filteredData.length === 0 && search !== "") {
      setData([{ id: -1, name: "Nenhuma profissão encontrada" }]);
    } else {
      setData(filteredData);
    }
  }, 1500)).current;

  let company = getSubDomain();
  const honestMarket = companySelected?.honest_market;

  useEffect(() => {
    if (cookies.user) {
      setName(cookies.user.name);
      setAddress(cookies.user.address);
      setStreet(cookies.user.address.split(",")[0]);
      setNumber(parseInt(cookies.user.address.split(",")[1].split("-")[0].trim()));
      setAddressNeighborhood(cookies.user.address.split(",")[1].split("-")[1]);
      setComplement(cookies.user.complement);
      setReferencePoint(cookies.user.referencePoint);
      setPhone(cookies.user.phone);
      setEmail(cookies.user.email);
      setGender(cookies.user.gender || null);
      setProfession(cookies.user.profession || null);
      if (cookies.user.neighborhood)
        changeNeighborhood(JSON.parse(cookies.user.neighborhood));
      setUser(cookies.user);
    }
  }, [cookies.user]);

  function handleSelectSuggestion(placeId) {
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    placesService.getDetails(
      {
        placeId: placeId,
        fields: ["address_components", "formatted_address", "geometry"],
      },
      (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const components = result.address_components;
          setStreet(findComponent(components, "route"));
          setNumber(findComponent(components, "street_number"));
          setZip(findComponent(components, "postal_code"));
          setAddressNeighborhood(
            findComponent(components, "sublocality_level_1") ||
              findComponent(components, "neighborhood")
          );

          let city = findComponent(components, "locality");
          if (!city) {
            city = findComponent(components, "administrative_area_level_2");
          }
          if (!city) {
            city = findComponent(components, "postal_town");
          }

          setCity(city);
          setState(findComponent(components, "administrative_area_level_1"));
          setCountry(findComponent(components, "country"));
          setLatitude(result.geometry.location.lat());
          setLongitude(result.geometry.location.lng());
          setAddress(result.formatted_address);
          setSuggestions([]);
        } else {
          setSuggestions([]);
        }
      }
    );
  }

  function findComponent(components, type) {
    return components.find((c) => c.types.includes(type))?.short_name || "";
  }

  async function validateUser() {
    return await apiDelivery.get(
      `${company}/customer/${Helper.onlyNumbers(phone)}`
    );
  }

  async function validateEmail() {
    return await apiDelivery.get(`${company}/customer/`)
  }

  useEffect(() => {
    if (isFirefox || isEdgeOrChrome) {
      getToken().then((e) => setToken(e));
    }
  }, []);

  function validateForm() {
    if (name === "" || name == null) {
      toast.dark("Nome Obrigatório");
      return false;
    }
    if (street === "" || street == null) {
      toast.dark("Rua Obrigatória");
      return false;
    }
    if (address === "" || address == null) {
      toast.dark("Endereço Obrigatório");
      return false;
    }
    if (neighborhood === null) {
      toast.dark("Bairro Obrigatório");
      return false;
    }
    if (phone === "") {
      toast.dark("Telefone Obrigatório");
      return false;
    }
    if (!Helper.validatePhone(phone)) {
      toast.dark("Telefone inválido");
      return false;
    }
    if(!email) {
      toast.dark("Email Obrigatório");
      return false;
    }
    if (email && email.length > 0 && !Helper.isEmailValid(email)) {
      toast.dark("E-mail inválido");
      return false;
    }
    return true;
  }

  function goBack() {
    let redirect = "/";

    if (!isEnvProduction()) {
      redirect = `/${company}`;
    }

    if (onClose != undefined) {
      return onClose();
    }

    if (onClose === undefined) {
      return history.push(redirect);
    }
  }

  async function putDataUser() {
    if (
      cookies.user.name === name &&
      cookies.user.address === address &&
      cookies.user.neighborhood === neighborhood &&
      cookies.user.phone === phone &&
      cookies.user.email === email &&
      cookies.user.street === street &&
      cookies.user.number === number &&
      cookies.user.zip === zip &&
      cookies.user.addressNeighborhood === addressNeighborhood &&
      cookies.user.city === city &&
      cookies.user.state === state &&
      cookies.user.country === country &&
      cookies.user.gender === gender &&
      cookies.user.profession === profession
    ) {
      return;
    }
    try {
      apiDelivery
        .put(`${company}/customer/${user.id}`, {
          _method: "PUT",
          id: user.id,
          full_name: name,
          gender: gender,
          profession: profession,
          latitude: latitude,
          longitude: longitude,
          street: street || user.street,
          number: !number ? null : number,
          zip: zip,
          neighborhood: addressNeighborhood.trim() !== "" ? addressNeighborhood : "s/bairro", 
          city: city,
          state: state,
          country: country,
          address: address || user.address,
          complement: complement,
          reference_point: referencePoint,
          device_token_id: token,
          phone_contact: Helper.onlyNumbers(phone),
          email: email || null,
          neighborhood_id: neighborhood.id,
          company_id: companySelected.id,
        })
        .then((res) => {
          if (res.status === 200) {
            const expires = new Date();
            expires.setDate(expires.getDate() + 365);
            setCookies(
              "user",
              JSON.stringify({
                id: userId,
                name,
                gender,
                profession,
                latitude,
                longitude,
                street,
                number,
                zip,
                addressNeighborhood,
                city,
                state,
                country,
                address,
                complement,
                referencePoint,
                email,
                phone,
                neighborhood: JSON.stringify(neighborhood),
              }),
              {
                expires,
                maxAge: 63072000,
              }
            );
          }
        });
    } catch (error) {
      return true;
    }
    return false;
  }

  async function saveDataUser() {
    try {
      const response = await apiDelivery.post(`${company}/customer`, {
        full_name: name,
        gender: gender,
        profession: profession,
        latitude: latitude,
        longitude: longitude,
        street: street,
        number: !number ? null : number,
        zip: zip,
        neighborhood: addressNeighborhood.trim() !== "" ? addressNeighborhood : "s/bairro", 
        city: city,
        state: state,
        country: country,
        address: address,
        complement: complement,
        reference_point: referencePoint,
        phone_contact: Helper.onlyNumbers(phone),
        email: email || null,
        device_token_id: token,
        neighborhood_id: neighborhood.id,
        company_id: companySelected.id,
      });
      return response;
    } catch (error) {
      console.error("Erro ao salvar os dados do usuário", error);
    }
  }

  const { visible, history, onClose } = props;

  const handleSelectClick = () => {
    if(honestMarket) {
      toast.dark("Não é necessário alterar este campo!");
    }
  }

  // Nova função para preencher os campos com os dados do cliente selecionado
  const handleSelectCustomer = (customer) => {
    setName(customer.full_name);
    setPhone(customer.phone_contact);
    setEmail(customer.email);
    setAddress(customer.address);
    setStreet(customer.street);
    setNumber(customer.number);
    setZip(customer.zip);
    setAddressNeighborhood(customer.neighborhood);
    setCity(customer.city);
    setState(customer.state);
    setCountry(customer.country);
    setLatitude(customer.latitude);
    setLongitude(customer.longitude);
    setProfession(customer.profession);
    setComplement(customer.complement);
    setReferencePoint(customer.reference_point);
    setGender(customer.gender);
    changeNeighborhood(customer.neighborhood);
  };

  return (  
    <Modal
      visible={visible}
      animationType="slide"
      onRequestClose={() => {
        onClose();
      }}
    >
      {accountStorage && <ModalAccountExist onSelectCustomer={handleSelectCustomer} />}
      <View style={styles.container}>
        <NavBar
          history={history}
          onBack={() => {
            onClose();
          }}
        />
        <ModalNullNeightborhood
          onClose={() => {
            setVisibleModalNull(false);
          }}
          company={companySelected}
          visible={visibleModalNull}
        />
        <ScrollView>
          <View style={styles.containerInput}>
            <Text style={styles.labelInput}>Nome</Text>
            <TextInput
              style={styles.input}
              value={name}
              onChangeText={(text) => setName(text)}
              placeholder="Nome Completo"
            />
          </View>
          <View style={styles.containerInput}>
            <Text style={styles.labelInput}>Gênero que se identifica</Text>
            <Text style={styles.subtitle}>
              Para possível uso de promoções, vouchers e benefícios (opcional)
            </Text>
            <View>
              <Picker
                style={{
                  height: "40px",
                  borderRadius: "5px",
                  backgroundColor: "#fff",
                }}
                selectedValue={gender}
                onValueChange={(itemValue, itemIndex) => setGender(itemValue)}
              >
                <Picker.Item
                  label="Selecione o gênero"
                  value=""
                  color="gray"
                  focus="transparent"
                  enabled={false}
                />
                <Picker.Item label="Masculino" value="Masculino" />
                <Picker.Item label="Feminino" value="Feminino" />
                <Picker.Item label="Outros" value="Outros" />
              </Picker>
            </View>
          </View>

          <View
            style={{
              flex: 1,
              paddingHorizontal: 10,
              paddingVertical: 10,
            }}
          >
            <Text style={styles.labelInput}>Profissão</Text>
            <Text style={styles.subtitle}>
              Para possível uso de promoções, vouchers e benefícios (opcional)
            </Text>
            <TouchableOpacity
              style={styles.dropdownSelector}
              onPress={() => {
                setClicked(!clicked);
              }}
            >
              <Text style={{ fontSize: 16 }}>
                {selectedRole == ""
                  ? cookies.user?.profession
                    ? cookies.user.profession
                    : "Selecione a profissão"
                  : selectedRole}
              </Text>
              {clicked ? (
                <MdKeyboardArrowUp size={18} />
              ) : (
                <MdKeyboardArrowDown size={18} />
              )}
            </TouchableOpacity>
            {clicked ? (
              <View style={styles.dropdownArea}>
                <TextInput
                  placeholder="Buscar.."
                  value={search}
                  ref={searchRef}
                  onChangeText={(txt) => {
                    setSearch(txt);
                    debouncedOnSearch(txt);
                  }}
                  style={styles.searchInput}
                />

                <FlatList
                  data={data}
                  style={{ paddingHorizontal: 10 }}
                  renderItem={({ item, index }) => {
                    const isLastItem = index === data.length - 1;
                    return (
                      <TouchableOpacity
                        style={[
                          styles.rolesItem,
                          isLastItem && { borderBottomWidth: 0 },
                        ]}
                        onPress={() => {
                          if (item.name !== "Nenhuma profissão encontrada") {
                            setProfession(item.name);
                            setSelectedRole(item.name);
                            setClicked(!clicked);
                            debouncedOnSearch("");
                            setSearch("");
                          }
                        }}
                      >
                        <Text key={item.id} style={{ fontWeight: "600" }}>
                          {item.name}
                        </Text>
                      </TouchableOpacity>
                    );
                  }}
                />
              </View>
            ) : null}
          </View>

          <View style={styles.containerInput}>
            <Text style={styles.labelInput}>Endereço</Text>
            <View style={styles.inputWithLoading} >
            <TextInput
              style={styles.input}
              value={address}
              onChangeText={(text) => {
                setAddress(text);
                setTimeout(() => {setIsLoading(true)}, 500);
                debouncedFetchAddress(text);
              }}
              placeholder="Digite seu Endereço"
            />
             {isLoading && <ActivityIndicator size={18} style={styles.loadingIndicator} />}
            </View>
            {suggestions.length > 0 && (
              <View style={styles.suggestionsContainer}>
                {suggestions.map((suggestion) => (
                  <TouchableOpacity
                    key={suggestion.id}
                    style={styles.suggestionItem}
                    onPress={() => handleSelectSuggestion(suggestion.id)}
                  >
                    <Text style={styles.suggestionText}>
                      {suggestion.description}
                    </Text>
                  </TouchableOpacity>
                ))}
              </View>
            )}
          </View>

          <View style={styles.containerInput}>
            <View style={styles.labelGroup}>
              <Text style={styles.labelInput}>Complemento</Text>
              <Text style={styles.labelOption}> (Opcional)</Text>
            </View>

            <TextInput
              style={styles.input}
              value={complement}
              onChangeText={(text) => setComplement(text)}
              placeholder="Apartamento, sala, coworking..."
            />
          </View>
          <View style={styles.containerInput}>
            <View style={styles.labelGroup}>
              <Text style={styles.labelInput}>Ponto de Referência</Text>
              <Text style={styles.labelOption}> (Opcional)</Text>
            </View>
            <TextInput
              style={styles.input}
              value={referencePoint}
              onChangeText={(text) => setReferencePoint(text)}
              placeholder="Próximo a..."
            />
          </View>
          <View>
            <Text style={styles.SelectNeighborhoodsLabel}>Bairro</Text>
          <TouchableOpacity onPress={handleSelectClick}>

            <SelectNeighborhoods
              honestMarket={honestMarket}
              value={neighborhood}
            />

            {!honestMarket ? (
              <TouchableOpacity>
                <Text
                  onPress={() => {
                    setVisibleModalNull(true);
                  }}
                  style={[styles.linkNeighBorHoods, { color: buttonColor }]}
                >
                  Meu bairro não consta na lista
                </Text>
              </TouchableOpacity>
            ) : (
              ""
            )}
          </TouchableOpacity>

          </View>
          <View style={styles.containerInput}>
            <Text style={styles.labelInput}>Telefone</Text>
            <InputPhoneMask
              style={styles.input}
              value={phone}
              onChangeText={(text) => setPhone(text)}
            />
          </View>
          <View style={styles.containerInput}>
            <View style={styles.labelGroup}>
              <Text style={styles.labelInput}>E-mail</Text>
              {
                optionalEmail && (
                  <Text style={styles.labelOption}> (Opcional)</Text>
                )
              }
            </View>
            <TextInput
              keyboardType="email-address"
              style={styles.input}
              value={email}
              onChangeText={(text) => setEmail(text)}
              placeholder="exemplo@email.com"
            />
          </View>
          <TouchableOpacity
            onPress={() => {
              if (!validateForm()) return;
              SetVisibleModalUser(false);

              const expires = new Date();
              expires.setDate(expires.getDate() + 365);

              validateUser()
                .then(({ data: { response } }) => {
                  if (response !== null && !cookies.user) {
                    setCookies(
                      "user",
                      JSON.stringify({
                        id: response.id,
                        name,
                        gender,
                        profession,
                        latitude,
                        longitude,
                        street,
                        number,
                        zip,
                        addressNeighborhood,
                        city,
                        state,
                        country,
                        address,
                        complement,
                        referencePoint,
                        email,
                        phone,
                        neighborhood: JSON.stringify(neighborhood),
                      }),
                      {
                        expires,
                        maxAge: 63072000,
                      }
                    );
                  }

                  if (response !== null) {
                    return response;
                  }
                  SetVisibleModalUser(false);
                  saveDataUser()
                    .then(({ data: { response } }) => {
                      setCookies(
                        "user",
                        JSON.stringify({
                          id: response.id,
                          name,
                          gender,
                          profession,
                          latitude,
                          longitude,
                          street,
                          number,
                          zip,
                          addressNeighborhood,
                          city,
                          state,
                          country,
                          address,
                          complement,
                          referencePoint,
                          email,
                          phone,
                          neighborhood: JSON.stringify(neighborhood),
                        }),
                        {
                          expires,
                          maxAge: 63072000,
                        }
                      );
                      onClose();
                    })
                    .catch((result) => {
                      console.error(result);
                    });
                })
                .then((response) => {
                  if (response == null) throw null;
                  setUserId(response.id);
                  SetVisibleModalUser(true);
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
          >
            <View
              style={[styles.btnContainer, { backgroundColor: buttonColor }]}
            >
              <Text style={[styles.btnText, { color: buttonTextColor }]}>
                Salvar
              </Text>
            </View>
          </TouchableOpacity>
          <ModalVerificationUser
            handleAccept={() => {
              goBack();
              putDataUser();
            }}
            company={companySelected}
            userData={user}
            visible={visibleModalUser}
          />
        </ScrollView>
        <ToastContainer hideProgressBar={true} pauseOnHover limit={1} />
      </View>
    </Modal>
  );
}
