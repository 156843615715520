import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Restaurant from "../pages/Restaurant";
import ItemDetail from "../pages/ItemDetail";
import ItemDetailMenu from "../pages/ItemDetailMenu";
import BagDetail from "../pages/BagDetail";
import BagDetailMenu from "../pages/BagDetailMenu";
import Menu from "../pages/Menu";
import NotFound from "../components/NotFound/NotFound";
import { isEnvProduction } from "../utils/url";
import Fashion from "../pages/Fashion/Index";
import ItemDetailFashion from "../pages/ItemDetailFashion/Index";
import FormSign from "../components/FormSign/FormSign";
import myOrders from "../pages/myOrders";
import MoreDetails from "../pages/MoreDetails";
import { useOrderService } from "../contexts/orderService.js";
import { Beforeunload } from "react-beforeunload";
import Payments from "../pages/Payments"
import ScannerProduct from "../pages/ScannerProduct";
export default function Routes() {
  const { showBeforeUnload } = useOrderService();
  function content() {
    return (
      <Switch>
        {
          isEnvProduction()  ?
          (
            <>
              <Route exact path="/" component={Restaurant} />
              <Route exact path="/product/" component={ItemDetail} />
              <Route exact path="/product/:productId" component={ItemDetail} />
              <Route exact path="/profile" component={FormSign} />
              <Route exact path="/myOrders" component={myOrders} />
              <Route exact path="/myOrders/:orderId" component={MoreDetails} />
              <Route exact path="/detailOrder/" component={BagDetail} />
              <Route exact path="/notfound/" component={NotFound} />
              <Route exact path="/fashion" component={Fashion}/>
              <Route exact path="/menu" component={Menu} />
              <Route exact path="/menu/product/" component={ItemDetailMenu} />
              <Route exact path="/menu/product/:productId" component={ItemDetailMenu} />
              <Route exact path="/menu/detailOrder/" component={BagDetailMenu} />
              <Route exact path="/payments" component={Payments} />
              <Route exact path="/product/scanner" component={ScannerProduct} />
           </>
          ) :
          (
            <>
              <Route exact path="/" component={Restaurant} />
              <Route exact path="/:company" component={Restaurant} />

              <Route exact path="/:company/menu" component={Menu} />
              <Route exact path="/:company/menu/product/" component={ItemDetailMenu} />
              <Route exact path="/:company/menu/product/:productId" component={ItemDetailMenu} />
              <Route exact path="/:company/menu/detailOrder/" component={BagDetailMenu} />

              <Route exact path="/:company/profile" component={FormSign} />
              <Route exact path="/:company/myOrders" component={myOrders} />
              <Route exact path="/:company/payments" component={Payments} />
              <Route exact path="/:company/myOrders/:orderId" component={MoreDetails} />
              <Route exact path="/:company/product/" component={ItemDetail} />
              <Route exact path="/:company/product/:productId" component={ItemDetail} />
              <Route exact path="/:company/detailOrder/" component={BagDetail} />
              <Route exact path="/:company/fashion" component={Fashion}/>
              <Route exact path='/detail' component={ItemDetailFashion} />
              <Route exact path="/:company/product/scanner" component={ScannerProduct} />
              <Route exact path="/notfound/" component={NotFound} />
            </>
          )

        }
        <Route exact path="/" component={Restaurant} />
        <Route exact path="/:company" component={Restaurant} />
        <Route component={NotFound} />
      </Switch>
    );
  }
  return (
    <Router>
      {
         showBeforeUnload ?
           <Beforeunload onBeforeunload={() => "Deseja realmente retornar?"}>
             {content()}
           </Beforeunload>
         :
        content()    
      }
    </Router>
  );
}