import React from 'react';
import { BiBorderRadius } from 'react-icons/bi';

const VideoComponent = ({ videoRef, children }) => {
  const videoStyles = {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    objectFit: 'cover'
  };

  return (
    <div style={{ position: 'relative' }}>
      <video ref={videoRef} style={videoStyles} autoPlay playsInline muted />
      {children}
    </div>
  );
};

export default VideoComponent;
