import React, { useState, useEffect } from "react";
import { View, TouchableOpacity, Image, Text, StyleSheet } from "react-native";
import Modal from "modal-react-native-web";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useOrderService } from "../../contexts/orderService";

export default function ModalPromotionBanner() {
  const {
    company,
    modalPromotion,
    changeVisibleModalPromotion,
    categories,
  } = useOrderService();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (modalPromotion && company != null && categories.length > 0) {
      setVisible(modalPromotion);
    }
  }, []);

  return (
    <>
      {company.publicity_picture != null && company.publicity_picture_status === true ? 
        <Modal animationType="slide" visible={visible} transparent={true}>
          <View style={styles.container} onClick={() => {changeVisibleModalPromotion(false)}}>
            <Image
              resizeMode={"contain"}
              style={styles.imgBanner}
              source={company != null ? company.publicity_picture : null  }
            />
            <TouchableOpacity>
              <Text
                style={styles.iconCose}
                onPress={() => changeVisibleModalPromotion(false)}
              >
                <AiOutlineCloseCircle />
              </Text>
            </TouchableOpacity>
          </View>
        </Modal> : ""}
    </>    
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `rgba(0,0,0,0.5)`,
  },
  imgBanner: {
    minHeight: 470,
    minWidth: 720,
  },
  iconCose: {
    color: "#fff",
    fontSize: 40,
    marginVertical: 15,
  },
});
