
import { StyleSheet } from 'react-native';
import Colors from "../../components/Colors/index";


let colorComplements = '#7C7C7C';
let fontComplements = '12px';

export const styles = StyleSheet.create({
    container:{
        backgroundColor: "#F0F0F0",
        flex:1,
        flexDirection:'row',
        justifyContent:"center",
        width:"100%",
        height: "100vh"
    },
    cards:{
        marginVertical: 20,
        backgroundColor: "#FFF",
        borderRadius: 8,
        width: "80%", 
        paddingBottom: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.36,
        shadowRadius: 6.68,

        elevation: 11,
    },
    header:{
        marginVertical: 15,
        alignItems: "center",
        justifyContent: "center"
    },
    title:{
        fontWeight: "bold",
        fontSize: 15
    },
    containerCards:{
        width: "100%", 
        alignItems: "center"
    },
    lineBottom:{
        borderBottomColor: "#e0e0e0",
        borderBottomWidth: 1,
        marginBottom: 10
      },
    contentItem: {
        flexDirection: "row", 
        paddingHorizontal: 25,
        marginVertical: 15,
        justifyContent: "space-between"
    },
    contentCard: {
        flexDirection: "row", 
        paddingHorizontal: 25,
        marginBottom: 5,
        justifyContent: "space-between"
    },
    contentComplements: {
        marginTop: 5,
        flexDirection: "row", 
        paddingHorizontal: 25,
        marginBottom: 5,
        justifyContent: "space-between"
    },
    showMoreContent:{
        width: "100%",
        alignItems: "center", 
        justifyContent: "center"        
    },
    showMoreButton:{
        width: "120px", 
        height: "30px", 
        margin: 20, 
        backgroundColor: Colors.PRIMARY,
        justifyContent: "center", 
        alignItems: "center",
        borderRadius: 10
    },
    showPages:{
        alignItems: "center", 
        justifyContent: "center",
        width: "100%",
        backgroundColor: Colors.PRIMARY, 
        height: "50px"
    },
    listComplementsTitle: {
        fontWeight: "bold",
        flex: 1, 
    },
    listComplementsDescription: {
        color: colorComplements,
        fontWeight: "bold",
        fontSize: fontComplements,
        paddingLeft: 10,
        flex: 1, 
        textAlign: 'left'
    },
    listComplementsQuantity: {
        color: colorComplements,
        fontWeight: "bold",
        fontSize: fontComplements,
        paddingRight: 10,
        flex: 1, 
        textAlign: 'center'
    },
    listComplementsPrice: {
        color: colorComplements,
        fontWeight: "bold",
        fontSize: fontComplements,
        flex: 1, 
        textAlign: 'right'
    },
    listProductsDescription: {
        flex: 1, 
        textAlign: 'left'
    },
    listProductsQuantity: {
        flex: 1, 
        textAlign: 'center'
    },
    containerListProducts:{
        justifyContent: "center",
    },
    labelListProducts:{
        justifyContent: "center",
        marginBottom: 12,
        fontWeight: "bold", 
        textAlign: "center"
    },
    listProductsPrice: {
        justifyContent: "center",
        flex: 1, 
        textAlign: 'right'
    },
    redoOrder: {
        width: "45%",
        borderRadius: 10,
        marginTop: 20,
        backgroundColor: Colors.PRIMARY,
        justifyContent: "center",
        alignItems: "center",
        height: 30
        
       
    }
}); 