export const maskCreditCard = (value) => {
  let card = null;
  card = value.replace(/\D/g, "");
  card = card.replace(/(\d{4})(\d)/, "$1 $2");
  card = card.replace(/(\d{4})(\d)/, "$1 $2");
  card = card.replace(/(\d{4})(\d)/, "$1 $2");
  return card;
};

export const maskExpiry = (value) => {
  let card = null;
  card = value.replace(/\D/g, "");
  card = card.replace(/(\d{2})(\d)/, "$1/$2");
  return card;
};

export const maskCpf = (value) => {
  let cpf = null;
  cpf = value.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})/, "$1-$2");
  cpf = cpf.replace(/(-\d{2})(\d)/, "$1");
  return cpf;
};
