import React, { useState, createContext, useEffect, useContext } from "react";
import apiDelivery from "../Api/apiDelivery";
import { useOrderService } from "./orderService";
import { getSubDomain } from "../utils/url";

const ColorContext = createContext({
  backgroundColor: "",
  textColor: "",
  buttonColor: "",
  buttonTextColor: "",
  menuColor: "",
  menuTextColor: "",
});

export const ColorProvider = ({ children }) => {
  let company = getSubDomain();
  const [backgroundColor, setBackgroundColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [buttonColor, setButtonColor] = useState("");
  const [buttonTextColor, setButtonTextColor] = useState("");
  const [menuColor, setMenuColor] = useState("");
  const [menuTextColor, setMenuTextColor] = useState("");

  const getColors = async () => {
    await apiDelivery.get(`${company}`).then((response) => {
      setBackgroundColor(response.data.response.background_color);
      setTextColor(response.data.response.text_color);
      setButtonColor(response.data.response.button_color);
      setButtonTextColor(response.data.response.button_text_color);
      setMenuColor(response.data.response.menu_color);
      setMenuTextColor(response.data.response.menu_text_color);
    });
  };

  useEffect(() => {
    getColors();
  }, []);

  return (
    <ColorContext.Provider
      value={{
        backgroundColor: backgroundColor,
        textColor: textColor,
        buttonColor: buttonColor,
        buttonTextColor: buttonTextColor,
        menuColor: menuColor,
        menuTextColor: menuTextColor,
      }}
    >
      {children}
    </ColorContext.Provider>
  );
};

export function useColor() {
  const context = useContext(ColorContext);

  return context;
}
