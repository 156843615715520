import React from 'react';
import {View, Text, ScrollView ,StyleSheet, TouchableOpacity}  from 'react-native';
import TouchableButton from '../TouchableButton';
import Colors from '../Colors'
import {AiOutlineRight} from 'react-icons/ai'
import { useColor } from '../../contexts/colors';

export default function ListProducts01({history}) {
    const {buttonColor, buttonTextColor} = useColor();
    return(
    <View style={styles.contain}>
        <ScrollView>
            <View style={styles.container}>
                <TouchableButton>
                   
                        <View style={styles.contentColumn}>
                            <TouchableOpacity>
                                <View style={styles.column1}>
                                    <Text style={styles.itemTitle}>
                                        Prod 1
                                    </Text> 
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity> 
                                <View style={styles.column2}>
                                    <Text style={styles.itemTitle}>
                                        Prod 2
                                    </Text>
                                </View>  
                            </TouchableOpacity>
                        </View>
                        <View style={styles.contentColumn}>
                            <TouchableOpacity> 
                                <View style={styles.column1}>
                                    <Text style={styles.itemTitle}>
                                        Prod 3
                                    </Text> 
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity> 
                                <View style={styles.column2}>
                                    <Text style={styles.itemTitle}>
                                        Prod 4
                                    </Text>
                                </View>  
                            </TouchableOpacity>
                        </View>
                        <View style={[styles.viewMore, {backgroundColor: buttonColor}]}>
                            <TouchableOpacity>
                                <Text style={[styles.viewMoreTitle, {color: buttonTextColor}]}>
                                    Ver mais
                                      <AiOutlineRight
                                       style={{
                                        marginLeft: "180"
                                        }} />
                                </Text>
                            </TouchableOpacity>
                        </View>
                   
                </TouchableButton>
            </View>
        </ScrollView>
    </View>
    )
}

const styles = StyleSheet.create({
  
    container: {
        display: "flex",
        flexDirection: 'row',
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 12,
        paddingTop: 5,
        borderWidth: 1,
        borderColor: "#ddd",
        borderRadius: 5
    },
    contentColumn: {
        flexDirection: 'row',
        alignItems: "center",
        marginTop: 3,
        borderBottomColor: '#ddd',
    },
    column1: {
        width: 50, 
        height: 130, 
        paddingHorizontal: 70,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: 5,
    },
    column2: {
        width: 50, 
        height: 130, 
        paddingHorizontal: 70,
        backgroundColor: '#fff',
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 7,
        marginLeft: 3
    }, 
    itemTitle: {
        width: 50,
        color: "black",
        fontWeight: 500,
    },
    viewMore: {
        display: "flex",        
        borderTopColor: "#ddd",
        borderTopWidth: 1,
        height: 40,
       justifyContent: "center",
       
    },
    viewMoreTitle: {
        paddingVertical: 7,
        paddingHorizontal: 5,
        color: Colors.PRIMARY,
        fontWeight: 600,
        fontSize: 18,
    },
 
   
    
});