


export default function NeighborhoodsWhats(companyPhone, neighborhood) {

    let allText = "";
    function addLine(text) {
      allText += encodeURIComponent(text);
    }
    function addLineN(text) {
      allText += `*${text}*`;
      addLine("\n");
    }
    // function addEmoji(emoji) {
    //   allText += `${emoji}`;
    //   addLine("");
    // }
  
    // function bkl() {
    //   allText += encodeURIComponent(" \n");
    // }
  
   function sendMessageNull() {
    addLineN(`Ops! Meu bairro ${neighborhood} não consta na lista\n`);
    
   

    window.open(
        "https://api.whatsapp.com/send?phone=55" +
        companyPhone +
          "&text=" +
          allText,
        "_blank"
      );
    }
    sendMessageNull();
  }
       
   


