export const transformMonth = (month) => {
  let months = {
    '01': 'Janeiro',
    '02': 'Fevereiro',
    '03': 'Março',
    '04': 'Abril',
    '05': 'Maio',
    '06': 'Junho',
    '07': 'Julho',
    '08': 'Agosto',
    '09': 'Setembro',
    '10': 'Outubro',
    '11': 'Novembro',
    '12': 'Dezembro',
  }
  return months[month]
};

export const verifyStatus = (status) => {
  let currentStatus = {
    "RECEIVED": { title: "Pedido Recebido", color: "#006A06"},
    "CONFIRMED": {title: "Pedido Confirmado", color: "#006A06"},
    "FINISHED": {title: "Pedido Finalizado", color: "#006A06"},
    "CANCELED": {title: "Pedido Cancelado", color: "#923"},
    "REFUSED": {title: "Pedido Recusado", color: "#923"},
  }
  return currentStatus[status]
};
