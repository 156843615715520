import Axios from "axios";

export default function sendNotification(establishmentId){
  Axios.post(
    `https://onesignal.com/api/v1/notifications`,
    {
      app_id: "49fa441c-0402-4267-8b16-0c0d23450682",
      include_external_user_ids: [establishmentId.toString()],
      data: { foo: "bar" },
      contents: { en: "Você recebeu um novo Pedido !" },
    },
    {
      headers: {
        Authorization: "Basic NWY4NTgyMGMtNGIyZC00NjY0LWE4MTctNGMxNGZlMjg3ZTRh",
      },
    }).then(response => {
        console.log(response)
    });
};
