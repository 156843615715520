import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from "react";
import {
  View,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
} from "react-native";

export default function TabSection({ childrenComponents, list,footerComponent }) {
  const activeAnimation = useRef(false);
  const itemPage = useRef({});
  const scrollTab = useRef();
  const [active, setActive] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", eventScroll);

    return () => {
      window.removeEventListener("scroll", eventScroll);
    };
  }, []);

  useEffect(() => {
    scrollTab.current.moveScroll(active);
  }, [active]);

  function eventScroll(active) {
    if (activeAnimation.current) return;
    const position = window.scrollY;
    let component = null;
    let index = 0;
    Object.keys(itemPage.current).map((attribute, indexPosition) => {
      const value = itemPage.current[attribute];
      if (component == null || position >= value - 200) {
        component = { id: attribute, position: value };
        index = indexPosition;
      }
    });

    if (component) {
      setActive(index);
    }
  }

  return (
    <>
      {childrenComponents}
      <Header
        itemPage={itemPage}
        activeSelected={active}
        ref={scrollTab}
        tabs={list.map((item) => item.name)}
        onChangeActive={() => {
          activeAnimation.current = true;
          setTimeout(() => {
            activeAnimation.current = false;
          }, 1000);
        }}
      />
      {list.map((item, index) => {
        return (
          <View
            onLayout={(event) => {
              const layout = event.nativeEvent.layout;
              itemPage.current[index] = layout.y;
            }}
            key={index}
          >
            {item.component}
          </View>
        );
      })}
      <View style={styles.footer}>
        {footerComponent}
      </View>
    </>
  );
}

const Header = forwardRef(
  ({ tabs, activeSelected, itemPage, onChangeActive }, ref) => {
    const [active, setActive] = useState(null);
    const itemTab = useRef({});

    useEffect(() => {
      setActive(activeSelected);
    }, [activeSelected]);

    const tabRef = useRef();
    useImperativeHandle(ref, () => ({
      getScroll: () => {
        return tabRef.current;
      },
      moveScroll: (index) => {
        tabRef.current.scrollTo({
          x: itemTab.current[index],
          y: 0,
          animated: true,
        });
      },
    }));

    return (
      <ScrollView
        showsHorizontalScrollIndicator={false}
        ref={tabRef}
        style={styles.containerTab}
        horizontal={true}
      >
        {tabs.map((tab, index) => {
          return (
            <TouchableOpacity
              onLayout={(event) => {
                const layout = event.nativeEvent.layout;
                itemTab.current[index] = layout.x;
              }}
              key={index}
              onPress={() => {
                tabRef.current.scrollTo({
                  x: itemTab.current[index],
                  y: 0,
                  animated: true,
                });
                window.scrollTo(0, itemPage.current[index]);
                setActive(index);
                if (onChangeActive) onChangeActive();
              }}
            >
              <View
                style={[
                  styles.tabItem,
                  active === index
                    ? { borderBottomColor: "#000", borderBottomWidth: 1 }
                    : null,
                ]}
              >
                <Text>{tab}</Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    );
  }
);

const styles = StyleSheet.create({
  containerTab: {
    backgroundColor: "#fff",
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
    position: "sticky",
    top: 0,
    zIndex: 5,
  },
  tabItem: {
    padding: 15,
  },
  footer:{
    position:"sticky",
    bottom:0,
    zIndex:1,
    marginTop:70
  }
});
