export function validarCPF(cpf) {
    if (!cpf || cpf.trim() === '') return false;

    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11) return false;

    const blockedList = ["00000000000", "11111111111", "22222222222", "33333333333", "44444444444", "55555555555", "66666666666", "77777777777", "88888888888", "99999999999"];
    if (blockedList.includes(cpf)) return false;

    function calculateCheckSum(factor, max) {
        let total = 0;
        for (let i = 0; i < max; i++) {
            total += parseInt(cpf.charAt(i)) * (factor - i);
        }
        const rest = total % 11;
        return (rest < 2) ? 0 : 11 - rest;
    }

    const digit1 = calculateCheckSum(10, 9);
    if (digit1 !== parseInt(cpf.charAt(9))) return false;

    const digit2 = calculateCheckSum(11, 10);
    if (digit2 !== parseInt(cpf.charAt(10))) return false;

    return true;
}
