import React, { useEffect, useRef, useState } from 'react';
import Quagga from 'quagga';
import { useOrderService } from '../../contexts/orderService';
import apiDelivery from '../../Api/apiDelivery';
import { toast, ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import './style.css'
import { getSubDomain, isEnvProduction } from '../../utils/url';

const BarCode = (props) => {
  const [barcode, setBarcode] = useState('');
  const { setErrorScanner } = useOrderService();
  let history = useHistory();
  const company = getSubDomain();

  useEffect(() => {
    startScanner();
  }, []);

  useEffect(() => {
    if (barcode) {
      setTimeout(() => { getProductByCode() }, 500)
    }
  }, [barcode]);

  const getProductByCode = () => {
    apiDelivery.get(`${company}/product/barcode/${barcode}`)
      .then((response) => {
        setBarcode("")
        if (response.data.response) {
          if (isEnvProduction()) {
            history.push(`/product/`, {
              item: response.data.response,
              company,
            })
          } else {
            history.push(`/${company}/product/`, {
              item: response.data.response,
              company,
            })
          }
        } else {
          return toast.dark("Código do produto não encontrado!");
        }
      });
  }

  const startScanner = () => {
    Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          target: document.querySelector('#scanner-container'),
          constraints: {
            heigth: 600,
            width: 600,
            facingMode: 'environment' // or user
          },
        },
        numOfWorkers: navigator.hardwareConcurrency,
        locate: true,
        frequency: 1,
        debug: {
          drawBoundingBox: true,
          showFrequency: true,
          drawScanline: true,
          showPattern: true
        },
        multiple: true,
        locator: {
          halfSample: false,
          patchSize: 'large', // x-small, small, medium, large, x-large
          debug: {
            showCanvas: false,
            showPatches: false,
            showFoundPatches: false,
            showSkeleton: false,
            showLabels: false,
            showPatchLabels: false,
            showRemainingPatchLabels: false,
            boxFromPatches: {
              showTransformed: false,
              showTransformedBox: false,
              showBB: false
            }
          }
        },
        decoder: {
          readers: [
            "ean_reader",
            "code_128_reader",
            "ean_8_reader",
          ]
        }
      },
      err => {
        if (err) {
          return setErrorScanner(true);
        }
        Quagga.start();
      }
    );
    Quagga.onDetected((result) => {
      if (Array.isArray(result)) {
        result.map((item) => {
          console.log(item);
          if (item.codeResult.code !== 'undefined' && item.codeResult.code !== null && item.codeResult.code.length >= 8) {
            if (item.codeResult.code) {
              console.log(item.codeResult.code);
              setBarcode(item.codeResult.code);
            }
          }
        })
      } else {
        setBarcode(result.codeResult.code);
      }
    });
  };

  const stopScanner = () => {
    Quagga.offProcessed();
    Quagga.offDetected();
    Quagga.stop();
  };

  return (
    <>
      <div id="scanner-container" />
      <ToastContainer hideProgressBar={true} pauseOnHover />
    </>
  )
}

export default BarCode;

