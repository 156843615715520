import React, { useState } from "react";
import { View, Text } from "react-native";
import { TouchableWithoutFeedback, TouchableOpacity } from "react-native";
import Modal from "modal-react-native-web";
import { styles } from "./styles";

export default function CardModal({
  toggleVisible,
  visible,
  idCard,
  setSearchCard,
}) {
  const handleRemoveCard = () => {
    let cards = JSON.parse(localStorage.getItem("history_card"));
    const listCard = cards.splice(idCard, 1);
    const removeArrayList = cards.filter((item) => {
      return item !== listCard[0];
    });
    setSearchCard(true);
    localStorage.setItem("history_card", JSON.stringify(removeArrayList));
  };

  return (
    <Modal animationType="slide" transparent={true} visible={visible}>
      <View style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            toggleVisible();
          }}
        >
          <View style={styles.overlayModal}></View>
        </TouchableWithoutFeedback>
        <View style={styles.modalView}>
          <Text style={styles.textDescription}>
            Deseja remover esse cartão?
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          ></View>

          <TouchableOpacity
            onPress={() => {
              toggleVisible();
              handleRemoveCard();
            }}
          >
            <View style={styles.btnOptionItem}>
              <Text style={styles.btnOptionItemText}>Sim</Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              toggleVisible();
            }}
          >
            <View style={styles.btnOptionItem}>
              <Text style={styles.btnOptionItemText}>Não</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}
