import React from 'react';
import { View,
         StyleSheet,
         Text,   
         TouchableOpacity
        } from 'react-native';
import NavBarFashion from '../../components/NavBarFashion/index'
import SwipeImage from '../../components/SwipeImage/index';
import Colors from '../../components/Colors';
import SelectProductAtribute from '../../components/SelectedProductAtribute/index';

export default function ItemDetailFashion({images}) {
    return(
      <>
        <View>
            <NavBarFashion/>
            <SwipeImage images={images}/>
        </View>
        <View style={styles.productName}>
          <Text style={{fontSize: 20, fontWeight: 500}}>
            Nome do produto
          </Text>
          <Text style={styles.productDescription}>
            Descrição do produto
          </Text>
        </View>
       <SelectProductAtribute/>
          <TouchableOpacity>
            <View style={styles.btnAddItem}>
              <Text style={styles.btnTextItemAdd}>
                Adicionar
              </Text>
            </View>
          </TouchableOpacity>
      </>
    )
}
const styles = StyleSheet.create({

  productName: {
    marginVertical: 10,
    marginHorizontal: 10,  
    borderBottomColor: "#ddd",
    borderBottomWidth: 2,
  },

  productDescription: {
    fontSize: 15,
    color: 'gray', 
    fontWeight: 500,
    marginVertical: 10
},
btnTextItemAdd: {
  flex: 1,
  color: "#fff",
  fontSize: 15,
  alignContent: "center",
  textAlign: 'center',
  fontWeight: 500
},
btnAddItem: {
  flex: 1,
  borderRadius: 5,
  backgroundColor: Colors.PRIMARY,
  alignItems: "center",
  flexDirection: "row",
  padding: 14,
  width: "70%", 
  marginRight: 'auto',
  marginLeft: 'auto',
  marginTop: 5
},

})
