
import { StyleSheet } from 'react-native';
import Colors from "../../components/Colors/index";

export const styles = StyleSheet.create({
    container:{
        backgroundColor: "#F0F0F0",
        height: '100vh',
        flex:1,
    },
    containerCard:{
        flexDirection:'row',
        justifyContent:"center",
        width:"100%"
    },  
    cards:{
        marginVertical: 20,
        backgroundColor: "#FFF",
        borderRadius: 8,
        width: "80%",   
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.36,
        shadowRadius: 6.68,

        elevation: 11,
    },
    header:{
        marginVertical: 15,
        alignItems: "center"
    },
    title:{
        fontWeight: "bold",
        fontSize: 15
    },
    subTitle:{
        color: "#6D6D6D"
    },
    lineBottom:{
        borderBottomColor: "#e0e0e0",
        borderBottomWidth: 1,
        marginBottom: 10
      },
    contentCard: {
        flexDirection: "row", 
        paddingHorizontal: 25,
        marginBottom: 5,
        justifyContent: "space-between"
    },
    showMoreContent:{
        width: "100%",
        alignItems: "center", 
        justifyContent: "center"        
    },
    showMoreButton:{
        width: "120px", 
        height: "30px", 
        margin: 20, 
        backgroundColor: Colors.PRIMARY,
        justifyContent: "center", 
        alignItems: "center",
        borderRadius: 10
    },
    showPages:{
        alignItems: "center", 
        justifyContent: "center",
        width: "100%",
        backgroundColor: Colors.PRIMARY, 
        height: "50px"
    },
    orderNotFound:{
        width: "200px",
        height: "300px"
    },
    errorTitle:{
        fontSize: "22px",
        color: "#424242"
    },
    errorDescription:{
        fontSize: "15px",
        color: "#666666"
    },
    containerNoOrders:{
        flex: 1,
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        width: "100%"
    },
    noOrderButton:{
        width: "150px", 
        height: "35px", 
        backgroundColor: "#f15925", 
        alignItems: "center", 
        justifyContent: "center", 
        marginTop: "15px", 
        borderRadius: "5px",
        color:"#FFF"
    }
}); 