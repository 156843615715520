import React, { useState } from "react";
import { View } from "react-native";

export default function SelectProductAtribute() {
	const [atributte, setAtributte] = useState();

	return (
		<>
			<View>
				<select
					style={{
						borderRadius: 5,
						width: "70%",
						marginRight: "auto",
						marginLeft: "auto",
						height: 40,
						backgroundColor: "#fff",
						borderColor: "#ddd",
					}}
					onChange={(e) => {
						setAtributte(e.target.value);
					}}
				>
					<optgroup label="Cor">
						<option value="vermelho">Vermelho</option>
						<option value="azul">Azul</option>
					</optgroup>
					<optgroup label="Quantidade">
						<option value="1">1</option>
						<option value="2">2</option>
					</optgroup>
					<optgroup label="Tamanho">
						<option value="p">P</option>
						<option value="m">M</option>
						<option value="g">G</option>
						<option value="gg">GG</option>
					</optgroup>
				</select>
				<h3 style={{ textAlign: "center" }}>{atributte}</h3>
			</View>
		</>
	);
}
