import React, { useEffect, useState } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  ImageBackground,
  StyleSheet,
  Image,
} from "react-native";
import hamburguerIcon from "../../assets/images/hamburguer-icon.png";
import moment from "moment";
import ModalscheduleEstablishment from "../ModalscheduleEstablishment";
import { useOrderService } from "../../contexts/orderService";
import ModalHour from "../ModalHour/ModalHour";
import Sidebar from "react-sidebar";
import { MdAddBox } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { TiLocation } from "react-icons/ti";
import { Redirect, Route, useHistory } from "react-router-dom";

import { isEnvProduction } from "../../utils/url";
import notPicture from "../../assets/images/notPicture.jpg";
import logoDefault from "../../assets/images/logoDefault.png";
import { useCookies } from "react-cookie";

export default function BannerProduct() {
  const { company, toggleMenu, setToggleMenu, openOrder, setOpenOrder } =
    useOrderService();
  const [statusOpenEstablishment, setStatusOpenEstablishment] = useState(null);
  const [modalscheduleEstablishment, setModalscheduleEstablishment] =
    useState(false);
  const [blockAcessStatus, setBlockAcessStatus] = useState(false);
  const [modalHour, setModalHour] = useState(false);
  const [menuRedirect, setMenuRedirect] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [cookies] = useCookies(["user"]);


  useEffect(() => {
    const status = checkOpenEstablishment(company);
    setBlockAcessStatus(status.isClose);
    setStatusOpenEstablishment(status);
  }, [company]);

  useEffect(() => {
    function getUserData() {
      if (!cookies.user) {
        setUser(null);
      } else {
        setUser(cookies.user);
      }
    }
    getUserData();
  }, [cookies]);

  function welcomeUser(company, cookies) {
      if(checkOpenEstablishment(company).isClose){
        return null;
      } else {
        return cookies.user ? `Bem-vindo, ${cookies.user.name.trim()}!` : "";
      }
  }
  
  function ButtonOptionRestaurant(props) {
    const { title, color, onPress } = props;
    return (
      <View style={styles.contentOptionBtn}>
        <TouchableOpacity onPress={onPress}>
          <View style={styles.optionBtn}>
            <Text
              style={{
                color: color || "#666",
                fontWeight: "500px",
                fontSize: 14,
              }}
            >
              {title}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  }

  function HandleSlidingMenu() {
    return (
      <TouchableOpacity
        onClick={() => setToggleMenu(true)}
        style={{ zIndex: 1 }}
      >
        <View style={styles.menuIcon}>
          <img src={hamburguerIcon} style={{ width: 30, height:30, fontSize: 30, display:"relative" }} />
        </View>
      </TouchableOpacity>
    );
  }

  function checkOpenEstablishment(restaurant) {
    if (restaurant == null) {
      return { isClose: true, value: "-" };
    }

    const date = moment();
    const weekDay = date.day() + 1;
    const day = restaurant.open_hours.find((item) => {
      return item.weekday_id === weekDay;
    });
    if (!day) {
      setOpenOrder(false);
      setMenuRedirect(true);
      return { isClose: true, value: "Fechado" };
    }

    const start = moment(day.hour_start, "HH:mm");
    const stop = moment(day.hour_stop, "HH:mm");

    const start1 = moment(day.hour_start1, "HH:mm");
    const stop1 = moment(day.hour_stop1, "HH:mm");

    const start2 = moment(day.hour_start2, "HH:mm");
    const stop2 = moment(day.hour_stop2, "HH:mm");

    const start3 = moment(day.hour_start3, "HH:mm");
    const stop3 = moment(day.hour_stop3, "HH:mm");

    const now = moment();

    const list = {
      h1: [start, stop],
      h2: [start1, stop1],
      h3: [start2, stop2],
      h4: [start3, stop3],
    };

    function isEstablishmentOpen(now) {
      return (
        Object.keys(list).filter((key) => {
          if (list[key] && now.isBetween(list[key][0], list[key][1])) {
            return true;
          }
          return false;
        }).length > 0
      );
    }

    if (isEstablishmentOpen(now)) {
      const indexSelected = Object.keys(list).find((key) => {
        if (list[key]) {
          return now.isBetween(list[key][0], list[key][1]);
        }
        return false;
      });

      return {
        isClose: false,
        value: `Aberto até ${list[indexSelected][1].format("HH:mm")}`,
      };
    }
    setMenuRedirect(true);
    setOpenOrder(false);
    return {
      isClose: true,
      value: `Fechado até ${list.h1[0].format("HH:mm")}`,
    };
  }

  function redirectToMenu(status) {
    if (!status) {
      return;
    }

    if (isEnvProduction()) {
      return history.push(`/menu/`);
    }
    return history.push(`/${company.url}/menu/`);
  }

  return (
    <View style={styles.containerItem}>
      {redirectToMenu(menuRedirect)}
      <HandleSlidingMenu />
      <ImageBackground
        resizeMode={"cover"}
        style={styles.bgImage}
        source={!company.logo_picture ? notPicture : company.logo_picture}
        blurRadius={3}
      />
      <Image
        style={styles.logoItem}
        resizeMode={"center"}
        source={!company.logo_picture ? logoDefault : company.logo_picture}
      />
      <View style={styles.contentRestaurant}>
        <ModalHour
          company={company}
          onClose={() => setModalHour(false)}
          visible={modalHour}
        />
        <ModalscheduleEstablishment
          company={company}
          onClose={() => setModalscheduleEstablishment(false)}
          visible={modalscheduleEstablishment}
        />
        <ButtonOptionRestaurant
          title="Ver área de entrega"
          onPress={() => {
            setModalscheduleEstablishment(true);
          }}
        />

        <ButtonOptionRestaurant
          onPress={() => {
            setModalHour(true);
          }}
          title={
            statusOpenEstablishment != null ? (
              <>
                {statusOpenEstablishment.value}{" "}
                <MdAddBox style={{ fontSize: 11 }} />
              </>
            ) : (
              ""
            )
          }
          color={
            statusOpenEstablishment
              ? statusOpenEstablishment.isClose
                ? "red"
                : "#00b345"
              : "#000"
          }
        />
      </View>
      <Text
        style={{
          textAlign: "center",
          fontSize: 18,
          fontWeight: "bold",
          color: "rgb(102, 102, 102)",
          paddingBottom: `${welcomeUser(company, cookies) ? 15 : 0}px`,
        }}> 
       {welcomeUser(company, cookies)}
      </Text> 
      <Text
        style={{
          textAlign: "center",
          fontSize: 14,
          color: "rgb(102, 102, 102)",
        }}
      >
        <TiLocation /> {company.address.address}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  containerItem: {
    width: "100vw",
    backgroundColor: "#fff",
  },

  bgImage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "white",
    height: 170,
    width: "100%",
    marginTop: -40,
  },
  logoItem: {
    width: "27%",
    height: 95,
    marginTop: -45,
    backgroundColor: "white",
    margin: "auto",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#eee",
    borderBottomWidth: 2,
    shadowColor: "#000",
    shadowOffset: { width: 1, height: 6 },
    shadowOpacity: 100,
    shadowRadius: 5,
    elevation: 1,
  },
  contentOptionBtn: {
    flex: 1,
    marginHorizontal: 5,
  },
  optionBtn: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
  },
  menuIcon: {
    top: 20,
    marginLeft: 20,
    alignItems: "flex-start",
  },
  contentRestaurant: {
    display: "flex",
    flexDirection: "row",
    paddingVertical: 25,
    backgroundColor: "#fff",
  },
});
