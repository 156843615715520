import { StyleSheet, Dimensions } from "react-native";
import Colors from "../../components/Colors"; 

const windowHeight = Dimensions.get("window").height;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: windowHeight,
  },
  content: {
    flex: 1,
    backgroundColor: "#ddd",
  },
  footer: {
    backgroundColor: "#fff",
    borderTopColor: "#ddd",
    borderTopWidth: 1,
  },
  header: {
    marginTop: 10,
    padding: 10,
    paddingHorizontal: 10,
    backgroundColor: "#fff",
  },
  btnLocation: {
    display: "flex",
    flexDirection: "row",
    marginVertical: 5,
    alignItems: "center",
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
    paddingHorizontal: 5,
  },
  iconLocal: {
    width: 150,
    left: -20,
  },
  contentLocal: {
    flex: 1,
  },
  titleDeliveryLocal: {
    fontSize: 14,
    color: "#555",
    bottom: -25,
    left: 15,
  },
  titleLocal: {
    fontSize: 18,
  },
  contentDescriptionInfoDelivery: {
    padding: 10,
  },
  descriptionTitleLocal: {
    fontSize: 14,
  },
  subtitleLocal: {
    fontSize: 13,
    color: "#666",
  },
  listItens: {
    flex: 1,
    backgroundColor: "#fff",
  },
  containerItem: {
    display: "flex",
    flexDirection: "row",
    marginHorizontal: 15,
    paddingVertical: 10,
    borderColor: "#ddd",
    borderBottomWidth: 1,
    alignItems: "center",
  },
  contentTitleItem: {
    flex: 1,
  },
  titleItem: {
    display: "flex",
    flexDirection: "row",
  },
  titleItemText: {
    fontSize: 16,
    flex: 1,
    left: 10,
    marginRight: 25,
  },
  titleAmountItem: {
    fontSize: 16,
  },
  textComplementItem: {
    fontSize: 11,
    color: "#999",
    marginLeft: 5,
  },
  btnOptionItem: {
    padding: 10,
  },
  containerTotals: {
    paddingVertical: 5,
    paddingHorizontal: 15,
    backgroundColor: "#fff",
  },
  contentItemTotals: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  subtotalsText: {
    color: "#555",
  },
  totalsText: {
    fontSize: 18,
  },
  containerPaymentForm: {
    marginTop: 10,
    backgroundColor: "#fff",
    paddingHorizontal: 15,
  },
  containerVouecher: {
    marginTop: 10,
    marginBottom: 20,
    backgroundColor: "#fff",
    paddingHorizontal: 15,
  },
  containerPaymentFormCard: {
    marginBottom: 5,
    backgroundColor: "#fff",
  },
  button: {
    backgroundColor: "#007AFF",
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
  },
  containerCpf: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: "#fff",
  },
  inputCpf: {
    border: "1px solid black",
    borderRadius: "10px",
    width: "70%",
    padding: "7px",
    fontSize: "18px",
    height: 40,
  },
  containerPaymentFormPix: {
    marginBottom: 5,
    backgroundColor: "#fff",
  },
  titlePaymentForm: {
    fontSize: 18,
    paddingVertical: 20,
    borderBottomColor: "#ccc",
    borderBottomWidth: 1,
  },
  btnSelectPaymentForm: {
    display: "flex",
    paddingVertical: 15,
  },
  titlePaymentFormText: {
    fontSize: 15,
    fontWeight: "bold",
  },
  titleSwitchPaymentForm: {
    fontSize: 15,
    color: Colors.PRIMARY,
  },
  btnFinish: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    padding: 15,
    color: "#fff",
    backgroundColor: Colors.PRIMARY,
    alignItems: "center",
    justifyContent: "center",
  },
  btnFinishText: {
    color: "#fff",
    fontSize: 18,
  },
  containerEstablishment: {
    marginTop: 10,
    padding: 10,
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
  },
  textInput: {
    fontSize: 16,
    paddingTop: 2.5,
    color: "#555",
  },

  addressInput: {
    fontSize: 17,
    paddingTop: 2.5,
    fontWeight: "bold",
    color: "black",
  },
});

export default styles;

