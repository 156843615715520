import React, {useState} from "react";
import {View} from "react-native";
import {useHistory} from "react-router-dom";
import BarCode from "../../components/BarCode";
import ButtomCheckProduct from "../../components/ButtomCheckProduct";
import FormScanner from "../../components/FormScanner";
import NavBar from "../../components/NavBar";
import { useOrderService } from "../../contexts/orderService";
import {getSubDomain, isEnvProduction} from "../../utils/url";


export default function ScannerProduct() {
    const [visibleFormScanner, setVisibleFormScanner] = useState(false);
    const history = useHistory();
    const company = getSubDomain();

    function handleBack() {
        if (isEnvProduction()) {
            history.push(`/`);
        } else {
            history.push(`/${company}`);
        }
    }

    function onVisibleModal () {
        setVisibleFormScanner(!visibleFormScanner);
    }


    return (
        <View style={{
            overflowX: 'hidden',
            overflowY: 'hidden'}}>
            <NavBar history={history} onBackPage={handleBack}/> 
                {navigator.mediaDevices && navigator.mediaDevices.getUserMedia && <BarCode />}
            <ButtomCheckProduct onVisibleModal={onVisibleModal}/>
            <FormScanner
                visible={visibleFormScanner}
                onClose={() => setVisibleFormScanner(false)}
                />
        </View>
    );
}


