import React from 'react';
import {
        StyleSheet,
        View,
        Text,
        TouchableOpacity
} from 'react-native';
import {MdBlock} from 'react-icons/md'
import Colors from '../Colors/'

export default function NotFound() {
  return (
      <>
        <View style={styles.containerNotFound}>
            <Text style={styles.txtNotFound}>Ops!</Text>
        </View>
        <View style={styles.containerBlock}>
            <MdBlock style={{fontSize: 60, color: "#a6a6a6"}}/>
            <Text style={styles.textNotFound}>Página não encontrada</Text>
            <Text style={styles.subTextNotFound}>Verifique o endereço digitado e tente novamente</Text>
        </View>
        <View style={styles.footer}>
            <TouchableOpacity style={styles.btnContinue}>
                <Text style={styles.TextBtnContinue}>Entendi</Text>
            </TouchableOpacity>
        </View>
    </>
  );
}

const styles = StyleSheet.create({
    containerNotFound: {
        backgroundColor: "#DF5A5A"
    },  
    txtNotFound: {
        fontSize: 20,
        textAlign: "center",
        marginVertical: 20,
        color: "#fff",
        fontWeight: "bold"
    },
    containerBlock: {
        alignItems: "center",
        marginVertical: 120
    },
    textNotFound: {
        fontSize: 20,
        fontWeight: "bolder"
    },
    subTextNotFound: {
        color: "#a6a6a6",
        fontWeight: "bold",
        textAlign: "center",
        marginVertical: 5
    },
 
      btnContinue: {
        position: "fixed",
        bottom: 0,
        width: "100%",
        padding: 15,
        color: "#fff",
        backgroundColor: Colors.PRIMARY,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5
      },
      TextBtnContinue: {
          color: "#fff",
          fontSize: 20
      }
})