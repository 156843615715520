import React from "react";
import {

  View
} from "react-native";
import CurrencyInput from 'react-currency-input';
import '../InputTextWithMask/style.css'

export default function InputTextWithMask({value,onChange}) {
    return(
        <View>
            <CurrencyInput  type="tel" value={value} onChange={onChange} className="style-input-mask" prefix="" decimalSeparator="," thousandSeparator="."/>
        </View>
    )
}
