import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  StyleSheet,
} from "react-native";
import Modal from "modal-react-native-web";
import Colors from "../Colors";
import { useColor } from "../../contexts/colors";
import { useOrderService } from "../../contexts/orderService";
import { useHistory } from "react-router-dom";
import { isEnvProduction } from "../../utils/url";

export default function ModalEstablishmentOpen({ visible }) {
  const { company, openOrder, setRedirectMenu } = useOrderService();
  const [ModalEstablishmentOpen, setModalEstablishmentOpen] = useState(false);
  const { buttonColor } = useColor();
  const history = useHistory();

  useEffect(() => {
    setModalEstablishmentOpen(openOrder)
  },[openOrder])

  const  handleAccept = () => {
    setRedirectMenu(true);
    if (isEnvProduction()) {
      return history.push(`/`);
    }
    return history.push(`/${company.url}/`);
  }

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={ModalEstablishmentOpen}
    >
      <View style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            setModalEstablishmentOpen(false);
          }}
        >
          <View style={styles.overlayModal}></View>
        </TouchableWithoutFeedback>
        <View style={styles.modalView}>
          <Text style={styles.textDescription}>
            Deseja fazer algum pedido?
          </Text>
          <TouchableOpacity
            onPress={() => {
              handleAccept();
              setModalEstablishmentOpen(false);
            }}
          >
            <View style={[styles.btnOptionItem, { color: buttonColor }]}>
              Sim
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setModalEstablishmentOpen(false);
            }}
          >
            <View style={[styles.btnOptionItem, { color: buttonColor }]}>
              Não
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  overlayModal: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    backgroundColor: "#fff",
    paddingHorizontal: 15,
  },
  textDescription: {
    fontSize: 20,
    textAlign: "center",
    padding: 15,
    marginBottom: 5,
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
  },
  btnOptionItem: {
    borderBottomWidth: 1,
    borderColor: "#ddd",
    padding: 10,
    alignItems: "center",
    color: Colors.PRIMARY,
    fontSize: 18,
  },
  btnOptionItemText: {
    color: Colors.PRIMARY,
    fontSize: 18,
  },
  textInputUser: {
    borderBottomWidth: 1,
    borderColor: "gray",
    fontSize: 20,
    textAlign: "center",
    padding: 5,
  },
});