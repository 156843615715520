import React, { useEffect, useState } from "react";
import { FlatList, View, Text, Image} from "react-native";
import { styles } from "./styles.js";
import NavBar from "../../components/NavBar";
import { useCookies } from "react-cookie";
import apiDelivery from "../../Api/apiDelivery.js";
import { getSubDomain, isEnvProduction } from "../../utils/url.js";
import { useHistory } from "react-router";
import Helper from "../../utils/Helper.js";
import { TouchableOpacity } from "react-native";
import { transformMonth, verifyStatus } from "../../utils/orders.js";
import { ActivityIndicator } from "react-native";
import NotOrderImage from "../../assets/images/acess-denied.png";
import { Button } from "react-native";
import { useColor } from "../../contexts/colors.js";

export default function MyOrders() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [loading, setLoading] = useState(false);


  const history = useHistory();
  const [cookies] = useCookies(["user"]);
  const company = getSubDomain();
  const { buttonColor, buttonTextColor } = useColor();

  let companyData = localStorage.getItem(`company_${company}`);
  companyData = JSON.parse(companyData);

  useEffect(() => {
    if (cookies.user) {
      getOrders();
    }
  }, []);

  const getOrders = (concat = false) => {
    if (loading) return;
    setLoading(true);
    apiDelivery
      .get(`${company}/order/all/${cookies.user.id}?page=${page}`)
      .then((response) => {
        setLoading(false);
        const { data: newResult, last_page } = response.data.response;
        if (page > last_page) return;
        setData(concat ? [...data, ...newResult] : [...newResult]);
        setPage(page + 1);
        setLastPage(last_page);
      });
  };

  function handleBack() {
    if (isEnvProduction()) {
      history.push(`/`);
    } else {
      history.push(`/${company}`);
    }
  }

  const NoOrders = () => {
    return(
      <View style={styles.containerNoOrders}>
        <Image source={NotOrderImage} style={styles.orderNotFound}/>
        <Text style={styles.errorTitle}>Ops!</Text>
        <Text style={styles.errorDescription}>Parece que você ainda não possui nenhum pedido.</Text>
        
        <TouchableOpacity onPress={() => handleBack()} style={[styles.noOrderButton, {backgroundColor: buttonColor}]}>
          <Text style={{color: buttonTextColor}}>Realize seu pedido</Text>
        </TouchableOpacity>
      </View>
    )
  }

  const Card = ({ item }) => {
    const pluralItems = () => {
      return item.ordem_itens.length > 1 ? " itens" : " item";
    };
    const day = item.date_order.split("/")[0];
    const month = item.date_order.split("/")[1];

    const status = verifyStatus(item.status)

    return (
      <View style={styles.containerCard}>
        <View style={styles.cards}>
          <View style={styles.header}>
            <Text style={styles.title}>{item.order_code}</Text>
            <Text style={{color: status.color}}>{status.title}</Text>
            {item.code_market_self && <Text style={styles.title}>Código da Geladeira: {item.code_market_self}#</Text>}
          </View>

          <View style={styles.lineBottom} />

          <View style={styles.contentCard}>
            <Text>Quantidade:</Text>
            <Text>
              {item.ordem_itens.length}
              {pluralItems()}
            </Text>
          </View>

          <View style={styles.contentCard}>
            <Text>Data: </Text>
            <Text>
              {day} de {transformMonth(month)}
            </Text>
          </View>

          <View style={styles.contentCard}>
            <Text>Total:</Text>
            <Text>R$ {Helper.maskMoney(JSON.parse(item.total_order))}</Text>
          </View>

          <TouchableOpacity
            onPress={() =>
              isEnvProduction()
                ? history.push(`/myOrders/${item.id}`)
                : history.push(`/${company}/myOrders/${item.id}`)
            }
          >
            <View style={styles.showMoreContent}>
              <View style={[styles.showMoreButton, {backgroundColor: buttonColor}]}>
                <Text style={{ color: buttonTextColor }}>Ver Mais</Text>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  

  const ShowBottomContent = () => {

    if(data.length === 0){
      return <NoOrders/>
    }

    if(page >= lastPage || cookies.user === undefined){
      return null
    }

    if(page < lastPage || cookies.user !== undefined){
      return(
        <TouchableOpacity
          onPress={() => getOrders(true)}
          style={styles.showPages}
          >
          <Text style={{ color: "#FFF" }}>Mostrar mais</Text>
        </TouchableOpacity>
      )
    }

  
  };

  const Preload = () => {
    return (
      <View
        style={{
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center",
          padding: 20,
          height: "100vh",
        }}
      >
        <ActivityIndicator />
      </View>
    );
  };

  return (
    <View style={{ flex: 1, flexDirection: "row" }}>
      <FlatList
        style={styles.container}
        ListHeaderComponent={<NavBar onBackPage={handleBack} />}
        ListFooterComponent={loading ? <Preload /> : <ShowBottomContent />}
        keyExtractor={(item) => `${item.id}`}
        data={data}
        renderItem={({ item }) => {
          return <Card item={item} />;
        }}
      />
    </View>
  );
}
