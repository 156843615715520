import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, TouchableWithoutFeedback, StyleSheet, TextInput } from "react-native";
import Modal from "modal-react-native-web";
import Colors from "../Colors";
import MsgConfirmationUser from "../MsgWhats/MsgConfirmationUser";
import { useCookies } from "react-cookie";
import { useColor } from "../../contexts/colors";
import { toast } from "react-toastify";

export default function ModalVerificationUser({ visible, handleAccept, company, userData }) {
  const [modalVerificationUser, setModalVerificationUser] = useState(true);
  const [cookies] = useCookies(["user"]);
  const [user, setUser] = useState(null);
  // const [phone, setPhone] = useState();
  const { buttonColor } = useColor();
  const [cardLastFourDigits, setCardLastFourDigits] = useState("");
  const [creditCards, setCreditCards] = useState([]);

  useEffect(() => {
    const historyCardData = localStorage.getItem("history_card");
    if (historyCardData) {
      setCreditCards(JSON.parse(historyCardData));
    }
  }, []);

  const handleAcceptWithCardValidation = () => {
    if (creditCards.length === 0) {
      handleAccept();
      setModalVerificationUser(false);
      return;
    }

    const isValidCard = creditCards.some(
      (card) => card.card_number.slice(-4) === cardLastFourDigits
    );
    if (isValidCard) {
      handleAccept();
      setModalVerificationUser(false);
    } else {
      toast.dark("Os últimos 4 dígitos do cartão não conferem.");
    }
  };

  useEffect(() => {
    if (cookies.user) {
      setUser(cookies.user);
    }
    setModalVerificationUser(visible);
  }, [visible]);

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVerificationUser}
    >
      <View style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            setModalVerificationUser(false);
          }}
        >
          <View style={styles.overlayModal}></View>
        </TouchableWithoutFeedback>
        <View style={styles.modalView}>
          <Text style={styles.textDescription}>
            O número {userData.phone} já cadastrado para o usuário {userData.name}, deseja atualizar os dados?
          </Text>
          {creditCards.length > 0 && (
            <>
              <Text style={styles.textDescription}>
                Para sua segurança, por favor confirme os últimos 4 dígitos do
                seu cartão de crédito cadastrado:
              </Text>
              <TextInput
                style={styles.textInputUser}
                placeholder="Últimos 4 dígitos do cartão"
                value={cardLastFourDigits}
                onChangeText={setCardLastFourDigits}
                keyboardType="numeric"
                maxLength={4}
              />
            </>
          )}
          <TouchableOpacity
            onPress={handleAcceptWithCardValidation}
            disabled={creditCards.length > 0 && cardLastFourDigits.length !== 4}
            style={{  opacity: creditCards.length > 0 && cardLastFourDigits.length !== 4  ? 0.5 : 1 }}
          >
            <View
              style={[ styles.btnOptionItem, { color: buttonColor }]}>
              Sim
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setModalVerificationUser(false);
              MsgConfirmationUser(company, user);
            }}
          >
            <View style={[styles.btnOptionItem, { color: buttonColor }]}>
              Não
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  overlayModal: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    backgroundColor: "#fff",
    paddingHorizontal: 15,
  },
  textDescription: {
    fontSize: 20,
    textAlign: "center",
    padding: 15,
    marginBottom: 5,
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
  },
  btnOptionItem: {
    borderBottomWidth: 1,
    borderColor: "#ddd",
    padding: 10,
    alignItems: "center",
    color: Colors.PRIMARY,
    fontSize: 18,
  },
  btnOptionItemText: {
    color: Colors.PRIMARY,
    fontSize: 18,
  },
  textInputUser: {
    borderBottomWidth: 1,
    borderColor: "gray",
    fontSize: 20,
    textAlign: "center",
    padding: 5,
  },
});
