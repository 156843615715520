import React from "react";
import { View, Image, Text } from "react-native";
import Modal from "modal-react-native-web";
import UpdateImage from "../../assets/images/update.png";
import { styles } from "./styles.js";
import Colors from "../Colors";
import { useOrderService } from "../../contexts/orderService";
import { TouchableOpacity } from "react-native";

export default function ForceUpdateModal({ doUpdate, currentVersion }) {
  const {    
    setShowBeforeUnload
  } = useOrderService();

  let lockScreen;
  doUpdate == true && (lockScreen = "hidden");

  document.getElementById("body").style.overflow = lockScreen;

  //Set the update on localStorage, clear the cache and reload the page.
  const reloadActions = () => {
    localStorage.setItem(`version_zappedis`, currentVersion);
    setShowBeforeUnload(false);
    window.location.reload();
  };

  return (
    <>
      {doUpdate === true && (
        <Modal animationType="slide" visible={true} transparent={true}>
          <View style={styles.container}>
            <View style={styles.content}>
              <Image
                source={UpdateImage}
                style={{ width: "70%", height: "60%" }}
              />
              <Text
                style={{
                  textAlign: "center",
                  color: Colors.PRIMARY,
                  fontSize: 25,
                }}
              >
                Ops!
              </Text>
              <Text style={{ textAlign: "center" }}>
                Existe uma nova atualização disponível!
              </Text>
              <TouchableOpacity
                style={styles.buttonUpdate}
                onPress={() => reloadActions()}
              >
                Atualizar
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      )}
    </>
  );
}