import React from 'react';
import { View, 
          TouchableOpacity, 
          Text, 
          StyleSheet,
        } from 'react-native';
import { IoMdArrowRoundBack } from 'react-icons/io';
import Colors from '../Colors'

export default function NavBarFashion(props) {
    const {  history } = props;
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: Colors.PRIMARY,
        color: "white",
        height: 50
      }}
  >      
    <Text
      style={{
        color: "white",
        fontSize: 18,
        top: 15,
        position: "absolute",
        textAlign: "center",
        width: "100%",
        zIndex: -1,
       
      }}
    >  
    </Text>    
      <TouchableOpacity>
        <View 
          style={styles.btnBackNavBar}
        >     
           <IoMdArrowRoundBack/> 
        </View>
      </TouchableOpacity>
  </View>
  );
}

const styles = StyleSheet.create({
  containerItem: {
    color: "#fff"
  },
  bgImage: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: "white",
      height: 150,
      width: "100%",
      marginTop: -50,
      shadowColor: "#3D3D3D"
  },
  paperHeader: {
      width: "30%",
      height: 50,
      marginTop: -25,
      borderRadius: 4,
      backgroundColor: "white",
      elevation: 5,
     borderColor: "black",
     borderWidth: 1,
     alignContent: "center"
  },
  btnBackNavBar: {
      color: "#fff",
      fontSize: 30
  }
});