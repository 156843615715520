import React, { useRef, useEffect } from "react";
import { Animated, Easing, Image } from "react-native";

const AnimatedImage = ({ source, style }) => {
  const moveAnim = useRef(new Animated.Value(-500)).current;
  const scaleAnim = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    Animated.parallel([
      Animated.timing(moveAnim, {
        toValue: 0,
        duration: 300,
        easing: Easing.out(Easing.quad),
        useNativeDriver: true,
      }),
      Animated.loop(
        Animated.timing(scaleAnim, {
          toValue: 1.2,
          duration: 18000,
          easing: Easing.linear,
          useNativeDriver: true,
        })
      ),
    ]).start();
  }, []);

  return (
    <Animated.View
      style={[
        style,
        {
          overflow: "hidden",
          transform: [{ translateX: moveAnim }, { scale: scaleAnim }],
        },
      ]}
    >
      <Image
        resizeMode="contain"
        style={{ width: "100%", height: "100%" }}
        source={source}
      />
    </Animated.View>
  );
};

export default AnimatedImage;
