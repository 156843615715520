import React, {useState, useEffect} from 'react';
import './selectedStylesheed.css'
import apiDelivery from '../../Api/apiDelivery';
import { useOrderService } from '../../contexts/orderService';
import { getSubDomain } from '../../utils/url';

export default function SeletectNeighborhoods({value, honestMarket}) {
    const {changeNeighborhood, allNeighborhoods} = useOrderService();
    const [neighborhoods, setNeighborhoods] = useState([]);
    let establishment = getSubDomain();

    if (honestMarket && value[0]) {
        changeNeighborhood(value[0])
    }

  return (
    <>
        <select disabled={honestMarket ? true : false} valeu={JSON.stringify(value)} className="style-select" placeholder="bairros" onChange={({target})=>{
           changeNeighborhood(JSON.parse(target.value));
        }}>
            <option selected disabled value="">Selecione um bairro</option>
            {
                allNeighborhoods.map((item, key) => {
                    const status = value == null ? false :  item.id === value.id;
                    return(
                        <option key={"OptionNeighborhoods"+key} value={JSON.stringify(item)} selected={status}>{item.neighborhood}</option>
                    )
                })
            }
        </select>
    </>
  )
  ;
}

