import React from 'react';
import BannerProductFashion from '../../components/BannerProductFashion/index';
import ListProductsFashion from '../../components/ListProductsFashion/index';

export default function Fashion() {
    return (
        <>
            <BannerProductFashion/>
            <ListProductsFashion />
        </>
    )
}