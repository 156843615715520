import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import Quagga from "@ericblade/quagga2";
import { FiX } from "react-icons/fi";
import VideoComponent from "./VideoComponent";
import ImageComponent from "./ImageComponent";
import { useHistory } from "react-router-dom";
import { getSubDomain } from "../../../utils/url";
import { useProduct } from "../../../contexts/ProductContext";
import Swal from "sweetalert2";

const CodeReaderScreen = ({ onClose }) => {
  const videoRef = useRef(null);
  const [codeValue, setCodeValue] = useState("");
  const [isClosing, setIsClosing] = useState(false);
  const [unavailableProducts, setUnavailableProducts] = useState(new Set());
  const history = useHistory();
  const company = getSubDomain();
  const { setScannerProduct } = useProduct();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    
    const startVideo = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => device.kind === "videoinput");
        const rearCamera = videoDevices.find((device) => device.label.toLowerCase().includes("back"));
        const constraints = {
          video: rearCamera ? { deviceId: { exact: rearCamera.deviceId } } : { facingMode: "environment" },
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play().catch((e) => console.error("Erro ao iniciar vídeo:", e));
        };
      } catch (error) {
        console.error("Error accessing the camera:", error);
      }
    };

    startVideo().then(() => {
      initQuagga();
    });

    const handleDetected = async (result) => {
      const code = result.codeResult.code;
      if (code.length === 13 && !unavailableProducts.has(code)) {
        setCodeValue(code);
        Quagga.stop();

        try {
          const response = await fetch(`${process.env.REACT_APP_API}${company}/product/barcode/${code}`);
          if (response.ok) {
            const data = await response.json();
            if (data.response && data.response.id) {
              setScannerProduct(data.response.id);
              onClose();
              history.push(`/${company}/product/`);
            } else {
              setUnavailableProducts((prev) => new Set(prev).add(code));
              Swal.fire({
                icon: 'error',
                title: 'Produto indisponível',
                text: 'O produto não está cadastrado.',
              }).then(() => {
                Quagga.start();
              });
            }
          } else {
            setUnavailableProducts((prev) => new Set(prev).add(code));
            Swal.fire({
              icon: 'error',
              title: 'Erro ao buscar produto',
              text: 'Não foi possível verificar o produto. Tente novamente.',
            }).then(() => {
              Quagga.start();
            });
          }
        } catch (error) {
          console.error("Erro ao buscar produto:", error);
          setUnavailableProducts((prev) => new Set(prev).add(code));
          Swal.fire({
            icon: 'error',
            title: 'Erro ao buscar produto',
            text: 'Não foi possível verificar o produto. Tente novamente.',
          }).then(() => {
            Quagga.start();
          });
        }
      }
    };

    const initQuagga = () => {
      Quagga.init(
        {
          inputStream: {
            type: "LiveStream",
            target: videoRef.current,
            constraints: {
              width: 640,
              height: 480,
              facingMode: "environment",
            },
          },
          decoder: {
            readers: ["ean_reader"],
            multiple: false,
          },
          locate: true,
        },
        (err) => {
          if (err) {
            console.error("Erro ao inicializar o Quagga:", err);
            return;
          }
          Quagga.start();
        }
      );

      Quagga.onDetected(handleDetected);
    };

    return () => {
      document.body.style.overflow = "auto";
      Quagga.offDetected(handleDetected);
      Quagga.stop();
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [history, onClose, company, setScannerProduct, unavailableProducts]);

  const handlePress = () => {
    if (isClosing) return;
    setIsClosing(true);
    setTimeout(onClose, 200);
  };

  const modalContent = (
    <View style={styles.overlay}>
      <View style={styles.container}>
        <VideoComponent videoRef={videoRef}>
          <TouchableOpacity onPress={handlePress} style={styles.closeButton}>
            <FiX size={24} color="white" />
          </TouchableOpacity>
        </VideoComponent>
        <ImageComponent src="/images/ImageBarCode.png" />
      </View>
    </View>
  );

  return ReactDOM.createPortal(
    modalContent,
    document.getElementById("modal-root")
  );
};

const styles = StyleSheet.create({
  overlay: {
    position: "fixed",
    zIndex: 1000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  container: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 5,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: "transparent",
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: "10px",
    width: "80%",
    padding: 10,
    color: "black",
  },
});

export default CodeReaderScreen;
