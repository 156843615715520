import React, { useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import Modal from "modal-react-native-web";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Colors from '../Colors';
export default function ModalscheduleEstablishment01({ visible, onClose }) {
 

  return (
    <View style={styles.containerContent}>
      <Modal 
        animationType="slide" 
        transparent={true}
        visible={visible}
      >
        <View style={styles.container}>
          <TouchableWithoutFeedback>
            <View style={styles.overlayModal}></View>
          </TouchableWithoutFeedback>
          <View style={styles.modalView}>
            <TouchableOpacity
              onPress={() => {
                onClose()
              }}
            >
              <IoIosCloseCircleOutline
                style={{ fontSize: 25, marginTop: 20, color: Colors.PRIMARY }}
              />
            </TouchableOpacity>
                <Text style={styles.textDescription}>
                  Meireles
                </Text>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100%"
  },
  overlayModal: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    backgroundColor: "#fff",
    paddingHorizontal: 15,
    height: "100%",
    paddingVertical: 20
  },
  textDescription: {
    fontSize: 14,
    textAlign: "center",
    padding: 20,
    marginBottom: 5,
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
  },
  btnOptionItem: {
    borderBottomWidth: 1,
    borderColor: "#ddd",
    padding: 40,
    alignItems: "center",
  },
  btnOptionItemText: {
    color: Colors.PRIMARY,
    fontSize: 18,
  },
  btn: {
    width: "100%",
    height: 50,
    borderRadius: 5,
    backgroundColor: Colors.PRIMARY,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 10,
  },
  textInputAdress: {
    borderBottomWidth: 1,
    borderColor: "gray",
    fontSize: 20,
    textAlign: "center",
    padding: 5,
  },
  IconClose: {
    fontSize: 20,
  },
});
