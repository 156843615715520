import React, { useState, createContext, useEffect, useContext } from "react";

const AuthContext = createContext({
  signed: true,
  permissionLocal: false,
  token: "",
  user: {},
  singleDelivery: true,
  getPermissionLocal: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [deviceToken, setDeviceToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {    
    setUser(null)
    setDeviceToken(null)
    setLoading(true)
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        loading,
        user,
        deviceToken,
        singleDelivery: true,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
