import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  TextInput,
  TouchableOpacity,
} from "react-native";
import Colors from "../Colors";
import Modal from "modal-react-native-web";
import { useColor } from "../../contexts/colors";

export default function ItemObservation(props) {
  const { description, initValue, onChange } = props;
  const [text, setText] = useState(initValue || "");
  const [textTemp, setTextTemp] = useState(initValue || "");
  const [visible, setVisible] = useState(false);
  const [start, setStart] = useState(false);
  const {buttonColor, buttonTextColor} = useColor();

  useEffect(() => {
    async function getInitValue() {
      if (start) return;

      if (initValue != null) {
        setText(initValue);
        setTextTemp(initValue);
      }

      setStart(true);
    }

    getInitValue();
  }, [initValue, start]);

  return (
    <>
      <Modal
        animationType="slide"
        transparent={false}
        visible={visible}
        onRequestClose={() => {
          setVisible(false);
          setTextTemp(text);
        }}
      >
        <View style={styles.containerModal}>
          <View style={styles.navModal}>
            <TouchableOpacity
              style={{ zIndex: 10 }}
              onPress={() => {
                setVisible(false);
                setTextTemp(text);
              }}
            >
              <Text>Voltar</Text>
            </TouchableOpacity>
            <Text style={styles.textNavModal}>{description}</Text>
            <Text style={styles.countNavModal}>{textTemp.length}/150</Text>
          </View>
          <View style={{ flex: 1 }}>
            <TextInput
              value={textTemp}
              style={{
                flex: 1,
                marginVertical: 7,
                paddingHorizontal: 7,
                paddingTop: 7,
              }}
              onChangeText={(text) => {
                if (text.length > 150) return false;
                setTextTemp(text);
              }}
              placeholder="Digite as informações aqui..."
              multiline={true}
              autoFocus={true}
            />
          </View>
          <TouchableWithoutFeedback
            onPress={() => {
              setText(textTemp);
              setVisible(false);
              if (onChange != null) onChange(textTemp);
            }}
          >
            <View style={[styles.btnSaveObservation, {backgroundColor: buttonColor}]}>
              <Text style={{ color: buttonTextColor}}>Salvar</Text>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </Modal>
      <View style={styles.container}>
        <View style={styles.contentDescription}>
          <View style={styles.contentTextDescription}>
            <Text style={styles.textDescription}>{description}</Text>
          </View>
          <Text style={styles.countDescription}>{text.length}/150</Text>
        </View>
        <TouchableWithoutFeedback
          onPress={() => {
            setVisible(true);
          }}
        >
          <Text style={styles.contentText}>{text}</Text>
        </TouchableWithoutFeedback>
        <Text style={styles.observationBottom}>
          Converse diretamente com o estabelecimentio caso queira modificar
          algum item. Neste campo não são aceitas modificações que podem gerar
          cobrança adicional
        </Text>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    borderTopColor: "#f5f5f5",
    borderTopWidth: 1,
  },
  contentDescription: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  contentTextDescription: {
    flexDirection: "row",
  },
  textDescription: {
    fontSize: 15,
    color: "#666",
    marginHorizontal: 4,
    fontWeight: "bold",
  },
  countDescription: {
    color: "#666",
    fontSize: 12,
    fontWeight: "bold",
    marginVertical: 5,
  },
  contentText: {
    borderWidth: 1,
    borderColor: "#ddd",
    fontSize: 11,
    color: "#666",
    fontWeight: "bold",
    padding: 5,
    paddingVertical: 18,
    borderRadius: 5,
    marginHorizontal: 4,
  },
  containerModal: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 15,
  },
  navModal: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textNavModal: {
    fontSize: 12,
    color: "#666",
    fontWeight: "bold",
    flex: 1,
    textAlign: "center",
    position: "absolute",
    width: "100%",
  },
  countNavModal: {
    fontSize: 10,
    color: "#666",
    textAlign: "center",
  },
  btnSaveObservation: {
    backgroundColor: Colors.PRIMARY,
    padding: 15,
    borderRadius: 5,
    alignItems: "center",
  },
  observationBottom: {
    color: "#666",
    fontSize: 12,
    fontWeight: "bold",
    marginVertical: 5,
    paddingVertical: 10,
  },
});
