import Modal from "modal-react-native-web";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { ToastContainer, toast } from "react-toastify";
import apiDelivery from "../../Api/apiDelivery";
import { useColor } from "../../contexts/colors";
import { useOrderService } from "../../contexts/orderService";
import Helper from "../../utils/Helper";
import { getSubDomain, isEnvProduction } from "../../utils/url";
import { validarCPF } from "../../utils/validarCPF";
import InputPhoneMask from "../InputPhoneMask/InputPhoneMask";
import ModalAccountExist from "../ModalAccountExist/ModalAccountExist";
import ModalNullNeightborhood from "../ModalNullNeightborhood/ModalNullNeightborhood";
import ModalVerificationUser from "../ModalVerificationUser/ModalVerificationUser";
import NavBar from "../NavBar";
import { styles } from "./HonestMarketRegistration.style";

export default function HonestMarketRegistration(props) {
  const {
    neighborhood,
    paymentForm,
    getToken,
    isEdgeOrChrome,
    isFirefox,
    company: companySelected,
    changeNeighborhood,
  } = useOrderService();

  const { buttonColor, buttonTextColor } = useColor();

  const [accountStorage, setAccountStorage] = useState(false);
  const [cookies, setCookies] = useCookies(["user"]);
  const [optionalEmail, setOptionalEmail] = useState(false);
  const [name, setName] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [document, setDocument] = useState("");
  const [user, setUser] = useState([]);
  const [token, setToken] = useState(null);
  const [visibleModalNull, setVisibleModalNull] = useState(false);
  const [visibleModalUser, SetVisibleModalUser] = useState(false);
  const [userId, setUserId] = useState(0);

  

  useEffect(() => {
    if (paymentForm?.activate_acquirer) {
      setOptionalEmail(true);
    } else {
      setOptionalEmail(false);
    }
  }, [paymentForm]);

  useEffect(() => {
    if (!cookies.user) {
      setAccountStorage(true);
    } else {
      setAccountStorage(false);
    }
  }, [cookies.user]);

  let company = getSubDomain();
  const honestMarket = companySelected?.honest_market;

  useEffect(() => {
    if (cookies.user) {
      setName(cookies.user.name);
      setPhone(cookies.user.phone);
      setEmail(cookies.user.email);
      setDocument(cookies.user.document);
      setUser(cookies.user);
    }
  }, [cookies.user]);

  function findComponent(components, type) {
    return components.find((c) => c.types.includes(type))?.short_name || "";
  }

  async function validateUser() {
    return await apiDelivery.get(
      `${company}/customer/${Helper.onlyNumbers(phone)}`
    );
  }

  async function validateEmail() {
    return await apiDelivery.get(`${company}/customer/`);
  }

  useEffect(() => {
    if (isFirefox || isEdgeOrChrome) {
      getToken().then((e) => setToken(e));
    }
  }, []);

  function validateForm() {
    if (name === "" || name == null) {
      toast.dark("Nome Obrigatório");
      return false;
    }
    if (phone === "") {
      toast.dark("Telefone Obrigatório");
      return false;
    }
    if (!Helper.validatePhone(phone)) {
      toast.dark("Telefone inválido");
      return false;
    }
    if (!email) {
      toast.dark("Email Obrigatório");
      return false;
    }
    if (!validarCPF(document)) {
      toast.dark("CPF inválido");
      return false;
    }
    if (document.length === 0 || document.length < 11) {
      toast.dark("Informe o CPF");
      return false;
    }
    if (email && email.length > 0 && !Helper.isEmailValid(email)) {
      toast.dark("E-mail inválido");
      return false;
    }
    return true;
  }

  function goBack() {
    let redirect = "/";

    if (!isEnvProduction()) {
      redirect = `/${company}`;
    }

    if (onClose != undefined) {
      return onClose();
    }

    if (onClose === undefined) {
      return history.push(redirect);
    }
  }

  async function putDataUser() {
    if (
      cookies.user.name === name &&
      cookies.user.phone === phone &&
      cookies.user.email === email &&
      cookies.user.document === document
    ) {
      return;
    }
    try {
      apiDelivery
        .put(`${company}/customer/${user.id}`, {
          _method: "PUT",
          id: user.id,
          full_name: name,
          latitude: latitude,
          longitude: longitude,
          device_token_id: token,
          phone_contact: Helper.onlyNumbers(phone),
          email: email || null,
          document: document,
          company_id: companySelected.id,
        })
        .then((res) => {
          if (res.status === 200) {
            const expires = new Date();
            expires.setDate(expires.getDate() + 365);
            setCookies(
              "user",
              JSON.stringify({
                id: userId,
                latitude,
                longitude,
                name,
                email,
                phone,
                document,
              }),
              {
                expires,
                maxAge: 63072000,
              }
            );
          }
        });
    } catch (error) {
      if(error.response && error.response[0] === "The document has already been taken.") {
        toast.dark("CPF já cadastrado");
        return false;
    }
    }
    return false;
  }

  async function saveDataUser() {
    try {
      const response = await apiDelivery.post(`${company}/customer`, {
        full_name: name,
        phone_contact: Helper.onlyNumbers(phone),
        latitude: latitude,
        longitude: longitude,
        document: document,
        email: email || null,
        device_token_id: token,
        company_id: companySelected.id,
      });
      return response;
    } catch (error) {
      if (error.response && error.response.data.response[0] === "The document has already been taken.") {
        toast.dark("CPF já cadastrado");
        return false;
      }
      console.error(error);
    }
  }

  const { visible, history, onClose } = props;

  const handleSelectClick = () => {
    if (honestMarket) {
      toast.dark("Não é necessário alterar este campo!");
    }
  };

  const handleSelectCustomer = (customer) => {
    setName(customer.full_name);
    setPhone(customer.phone_contact);
    setEmail(customer.email);
    setDocument(customer.document);
    setLatitude(customer.latitude);
    setLongitude(customer.longitude);
  };

  return (
    <Modal
      visible={visible}
      animationType="slide"
      onRequestClose={() => {
        onClose();
      }}
    >
      {accountStorage && (
        <ModalAccountExist onSelectCustomer={handleSelectCustomer} />
      )}
      <View style={styles.container}>
        <NavBar
          history={history}
          onBack={() => {
            onClose();
          }}
        />
        <ModalNullNeightborhood
          onClose={() => {
            setVisibleModalNull(false);
          }}
          company={companySelected}
          visible={visibleModalNull}
        />
        <ScrollView>
          <View style={styles.title}>Cadastro - Zappedis Honest Market</View>
          <View style={styles.containerInput}>
            <Text style={styles.labelInput}>Nome</Text>
            <TextInput
              style={styles.input}
              value={name}
              onChangeText={(text) => setName(text)}
              placeholder="Nome Completo"
            />
          </View>

          <View style={styles.containerInput}>
            <Text style={styles.labelInput}>Telefone</Text>
            <InputPhoneMask
              style={styles.input}
              value={phone}
              onChangeText={(text) => setPhone(text)}
              placeholder="(99) 99999-9999"
            />
          </View>

          <View style={styles.containerInput}>
            <View style={styles.labelGroup}>
              <Text style={styles.labelInput}>Documento</Text>
            </View>
            <TextInput
              keyboardType="numeric"
              style={styles.input}
              value={document}
              onChangeText={(text) => setDocument(text)}
              placeholder="CPF/CNPJ"
            />
          </View>

          <View style={styles.containerInput}>
            <View style={styles.labelGroup}>
              <Text style={styles.labelInput}>E-mail</Text>
              {optionalEmail && (
                <Text style={styles.labelOption}> (Opcional)</Text>
              )}
            </View>
            <TextInput
              keyboardType="email-address"
              style={styles.input}
              value={email}
              onChangeText={(text) => setEmail(text)}
              placeholder="exemplo@email.com"
            />
          </View>
          <TouchableOpacity
            onPress={() => {
              if (!validateForm()) return;
              SetVisibleModalUser(false);

              const expires = new Date();
              expires.setDate(expires.getDate() + 365);

              validateUser()
                .then(({ data: { response } }) => {
                  if (response !== null && !cookies.user) {
                    setCookies(
                      "user",
                      JSON.stringify({
                        id: response.id,
                        name,
                        latitude,
                        longitude,
                        email,
                        phone,
                      }),
                      {
                        expires,
                        maxAge: 63072000,
                      }
                    );
                  }

                  if (response !== null) {
                    return response;
                  }
                  SetVisibleModalUser(false);
                  saveDataUser()
                    .then(({ data: { response } }) => {
                      setCookies(
                        "user",
                        JSON.stringify({
                          id: response.id,
                          name,
                          latitude,
                          longitude,
                          email,
                          phone,
                        }),
                        {
                          expires,
                          maxAge: 63072000,
                        }
                      );
                      onClose();
                    })
                    .catch((result) => {
                      console.error(result);
                    });
                })
                .then((response) => {
                  if (response == null) throw null;
                  setUserId(response.id);
                  SetVisibleModalUser(true);
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
          >
            <View
              style={[styles.btnContainer, { backgroundColor: buttonColor }]}
            >
              <Text style={[styles.btnText, { color: buttonTextColor }]}>
                Salvar
              </Text>
            </View>
          </TouchableOpacity>
          <ModalVerificationUser
            handleAccept={() => {
              goBack();
              putDataUser();
            }}
            company={companySelected}
            userData={user}
            visible={visibleModalUser}
          />
        </ScrollView>
        <ToastContainer hideProgressBar={true} pauseOnHover limit={1} />
      </View>
    </Modal>
  );
}
