import React, { useState } from "react";
import { View, Image, ScrollView, Text, StyleSheet } from "react-native";
import { Dimensions } from "react-native-web";

const { width } = Dimensions.get("window");
const height = (width * 100) / 150;

const images = [
  "https://images.pexels.com/photos/1684149/pexels-photo-1684149.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1586150/pexels-photo-1586150.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/3214409/pexels-photo-3214409.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/2127969/pexels-photo-2127969.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/803940/pexels-photo-803940.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/4849116/pexels-photo-4849116.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];
export default function SwipeImage() {
  const [active, setActive] = useState(0);

  const change = ({ nativeEvent }) => {
    const slide = Math.ceil(
      nativeEvent.contentOffset.x / nativeEvent.layoutMeasurement.width
    );
    if (slide !== active) {
      setActive(slide);
    }
  };

  return (
    <View>
      <ScrollView
        horizontal
        pagingEnabled
        scrollEventThrottle={0}
        onScroll={change}
        showsVerticalScrollIndicator={false}
        style={{ width, height }}
      >
        {images.map((image, index) => {
          return (
            <Image
              source={{ uri: image }}
              key={index}
              style={{
                width,
                height,
                resizeMode: "contain",
              }}
            />
          );
        })}
      </ScrollView>
      <View
        style={{
          flexDirection: "row",
          position: "absolute",
          bottom: 0,
          alignSelf: "center",
        }}
      >
        {images.map((i, k) => {
          return (
            <Text
              key={k}
              style={k === active ? styles.pagingTextActive : styles.pagingText}
            >
              ⬤
            </Text>
          );
        })}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  pagingText: {
    color: "#888",
    margin: 3,
    fontSize: width / 30,
  },
  pagingTextActive: {
    color: "#fff",
    margin: 3,
    fontSize: width / 30,
  },
});
