export function formatAddress(addressFull) {
    const part = addressFull.split(", ");

    if (part.length >= 2) {
        const address = part[0];
        const cityAddress = part[1];
        return address + ", " + cityAddress;
    }

    return addressFull;
}