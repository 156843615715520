import React, { useState, useEffect } from "react";
import { View, ScrollView, Text, Image, StyleSheet } from "react-native";
import Helper from "../../utils/Helper";
import apiDelivery from "../../Api/apiDelivery";
import TouchableButton from "../TouchableButton";
import { useOrderService } from "../../contexts/orderService";
import notPicture from "../../assets/images/notPicture.jpg";
import { getSubDomain, isEnvProduction } from "../../utils/url";
import TabSection from "../TabSection";

let loading = false;

export default function ListProducts({
  childrenComponents,
  restaurant,
  footerComponent,
  history,
}) {
  const {
    categories,
    updateCategories,
    company: companyData,
  } = useOrderService();

  let company = getSubDomain();

  useEffect(() => {
    async function getCategories() {
    loading = true;
      try {
        const {
          data: { response },
        } = await apiDelivery.get(`${company}/product`);

        loading = true;

        if (!response) return;

        localStorage.setItem(`products_${company}`, JSON.stringify(response));

        updateCategories(response);
      } catch (err) {
        const storeProducts = localStorage.getItem(`products_${company}`);

        if (storeProducts) {
          updateCategories(JSON.parse(storeProducts));
        }
      }
    }

    if (categories.length === 0 && !loading) getCategories();
  }, []);

  return (
    <TabSection
      childrenComponents={childrenComponents}
      footerComponent={footerComponent}
      list={categories
        .filter((category) => {
          return category.products.length !== 0;
        })
        .map((category, index) => {
          return {
            name: category.name,
            component: (
              <View key={index}>
                <View style={styles.tabBar}>
                  <Text style={styles.textCategory}>{category.name}</Text>
                </View>
                <View>
                  {category.products.map((product, index) => {
                    return (
                      <View style={styles.itemContainer} key={index}>
                        <TouchableButton
                          onPress={() => {
                            if (isEnvProduction()) {
                              history.push(`/menu/product`, {
                                item: product,
                                restaurant,
                              });
                            } else {
                              history.push(`/${restaurant}/menu/product/`, {
                                item: product,
                                restaurant,
                              });
                            }
                          }}
                        >
                          <View style={styles.containerProducts}>
                            <View style={{ flex: 1 }}>
                              <Text style={styles.itemTitle}>
                                {product.name}
                              </Text>
                              <Text
                                style={styles.itemDescription}
                                numberOfLines={2}
                              >
                                {product.description}
                              </Text>
                              <Text style={styles.itemPrice}>
                              {product.sale_price == 0 ? (
                                  <Text style={styles.itemNoPrice}>
                                    Clique aqui para mais detalhes
                                  </Text>
                                ) : (
                                    <Text style={styles.itemPrice}>
                                      R$
                                      {Helper.maskMoney(
                                        parseFloat(product.sale_price)
                                      )}
                                    </Text>
                                  )}
                              </Text>
                            </View>
                            <Image
                              resizeMode="contain"
                              style={styles.logoItem}
                              source={{
                                uri: !product.picture
                                  ? notPicture
                                  : product.picture,
                              }}
                            />
                          </View>
                        </TouchableButton>
                      </View>
                    );
                  })}
                </View>
              </View>
            ),
          };
        })}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tabBar: {
    backgroundColor: "#eee",
    borderBottomColor: "#f4f4f4",
    borderBottomWidth: 1,
    padding: 20,
  },
  itemNoPrice: {
    color: "rgb(133, 133, 133)",
    fontSize: '1rem',
  },
  itemContainer: {
    paddingVertical: 20,
    paddingHorizontal: 15,
    backgroundColor: "#fff",
  },
  itemTitle: {
    flex: 1,
    fontSize: 18,
    color: "#131313",
  },
  itemPrice: {
    fontSize: 18,
    color: "#131313",
    paddingTop: 2,
  },
  contentDescriptionImage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  itemDescription: {
    // marginTop: 10,
    color: "#b6b6b6",
    fontSize: 12,
    flex: 1,
  },
  itemRow: {
    flexDirection: "row",
  },
  logoItem: {
    width: 75,
    height: 75,
    flexDirection: "row",
    borderRadius: 5,
  },
  textCategory: {
    fontSize: 16,
    fontWeight: "500px",
  },
  containerProducts: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
