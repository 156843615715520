import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import Helper from "../../utils/Helper";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { useOrderService } from "../../contexts/orderService";

export default function GroupComplement(props) {
  const { group, onChangeItems, selectedComplements, highValue} = props;
  const [isStart, setStart] = useState(false);
  const [items, setItems] = useState([]);
  
  const {
    setIsHigherValue
  } = useOrderService();

  useEffect(() => {
    async function getSelectedComplements() {
      if (isStart) return;
      if (selectedComplements) {
        const groupComplements = selectedComplements.find((item) => {
          return item.groupId == group.id;
        });

        if (groupComplements) setItems([...groupComplements.items]);

        setStart(true);
      }
    }

    getSelectedComplements();
  }, [items]);

  return (
    <>
      <View style={styles.contentGroup} key={group.id}>
        <Text style={styles.nameGroup}>
          {group.name} {group.minimum_quantity > 0 ? `(Min: ${group.minimum_quantity} )` :  null}
        </Text>
        <View style={styles.contentRequiredItem}>
          <Text style={styles.textAmountItem}>
            {items.length} de {group.maximum_quantity }
          </Text>
          {group.required == true ? (
            <View style={styles.tagRequired}>
              <Text style={styles.tagRequiredText}>OBRIGATÓRIO </Text>
            </View>
          ) : null}
        </View>
      </View>
      <View style={styles.contentComplementOption}>
        {group.complements.map((product, indexProduct) => {
          const count = checkCountItemEdit(product, selectedComplements);

          return (
            <View key={product.id} style={styles.complementItem}>
              <View style={{ flex: 1 }}>
                <Text style={styles.complementItemText}>{product.name}</Text>
                <Text style={styles.description}>{product.description}</Text>

                <Text style={styles.complementItemPrice}>
                  + R$ {Helper.maskMoney(parseFloat(product.price))}
                </Text>
              </View>
              <OptionQuantity
                value={count}
                onValidate={() => {
                  return items.length < group.maximum_quantity;
                }}
                onAdd={() => {

                  let newItems = []
                  if(highValue){
                    setIsHigherValue(highValue)
                    newItems = [
                      ...items,
                      { ...product, price: product.price, isHigherValue: highValue},
                    ];
                  }
                  if(!highValue){
                    newItems = [
                      ...items,
                      { ...product, price: product.price, isHigherValue: highValue},
                    ];
                  }
                 
                  onChangeItems(newItems, group.id, group.name);
                  setItems(newItems);
                }}
                onRemove={() => {
                  let isRemove = false;
                  const newItems = [
                    ...items.filter((item) => {
                      let isFind = false;
                      if (item.id == product.id && !isRemove) {
                        isFind = true;
                        isRemove = true;
                      }
                      return !isFind;
                    }),
                  ];

                  onChangeItems(newItems, group.id, group.name);
                  setItems(newItems);
                }}
                isLimit={items.length == group.maximum_quantity}
              />
            </View>
          );
        })}
      </View>
    </>
  );
}

function checkCountItemEdit(product, selectedComplements) {
  if (!selectedComplements) return null;

  const groupSelected = selectedComplements.find((groupSelected) => {
    return groupSelected.groupId === product.product_group_complement_id;
  });

  if (!groupSelected) return null;

  const count = groupSelected.items.filter((item) => {
    return item.id === product.id;
  }).length;

  return count;
}

function OptionQuantity(props) {
  const [count, setCount] = useState(0);
  const [isStart, setIsStart] = useState(false);

  const { onValidate, onAdd, onRemove, isLimit, value } = props;

  useEffect(() => {
    async function checkValueDefault() {
      if (isStart) return;
      if (value) {
        setCount(value);
        setIsStart(true);
      }
    }
    checkValueDefault();
  }, []);

  return (
    <View style={styles.containerOptionQuantity}>
      {count > 0 ? (
        <TouchableOpacity
          onPress={() => {
            if (onRemove) onRemove();
            setCount(count - 1);
          }}
        >
          <Text style={{ fontSize: 30 }}>
            <IoIosRemove />
          </Text>
        </TouchableOpacity>
      ) : null}
      {count > 0 ? (
        <Text style={styles.textOptionQuantity}>{count}</Text>
      ) : null}
      <TouchableOpacity
        onPress={() => {
          if (onValidate) {
            if (onValidate(count + 1)) {
              if (onAdd) onAdd(count + 1);
              setCount(count + 1);
            }
          } else {
            if (onAdd) onAdd(count + 1);
            setCount(count + 1);
          }
        }}
      >
        <Text style={[{ fontSize: 30 }, { opacity: isLimit ? 0.25 : 1 }]}>
          <IoIosAdd />
        </Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  contentGroup: {
    padding: 20,
    backgroundColor: "#f5f5f5",
    
  },
  nameGroup: {
    fontWeight: "bold",
    fontSize: 16,
    
    
  },
  contentRequiredItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textAmountItem: {
    fontSize: 12,
    color: "#666",
  },
  tagRequired: {
    backgroundColor: "#424242",
    borderRadius: 2,
    paddingHorizontal: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  tagRequiredText: {
    fontSize: 10,
    color: "#fff",
  },
  contentComplementOption: {
    paddingHorizontal: 20,
  },
  complementItem: {
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#f5f5f5",
    flexDirection: "row",
  },
  complementItemText: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#666",
  },
  description: {
    fontSize: 11,
    color: "#999",
    marginLeft: 5,

    color: "#666",
  },
  complementItemPrice: {
    fontSize: 11,
    color: "#666",
  },
  containerOptionQuantity: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  textOptionQuantity: {
    fontSize: 20,
    marginHorizontal: 10,
    width: 35,
    textAlign: "center",
  },
});
