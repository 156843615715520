import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import { View, Text } from "react-native";
import { useHistory } from "react-router";
import { getSubDomain, isEnvProduction } from "../../utils/url";
import { styles } from "./styles.js";
import CardModal from "../../components/CardModal";
import NewCard from "../../components/NewCard";
import { AiOutlineCreditCard, AiOutlineCheck } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import { TouchableOpacity } from "react-native";

export default function Payments() {
  const company = getSubDomain();
  const history = useHistory();
  const [isVisibleRemoveCard, setIsVisibleRemoveCard] = useState(false);
  const [isVisibleNewCard, setIsVisibleNewCards] = useState(false);
  const [idCard, setIdCard] = useState(0);
  const [cardUser, setCardUser] = useState([]);
  const [searchCard, setSearchCard] = useState(false);

  useEffect(() => {
    setCardUser(JSON.parse(localStorage.getItem("history_card")));
    setSearchCard(false);
  }, [searchCard]);

  function handleBack() {
    if (isEnvProduction()) {
      history.push(`/`);
    } else {
      history.push(`/${company}`);
    }
  }

  const removeCard = (id) => {
    setIdCard(id);
    setIsVisibleRemoveCard(true);
  };

  const updateInforCard = (id) => {
    const listCard = cardUser.map((item, index) => {
      if (index === id) return { ...item, active: true };
      return { ...item, active: false };
    });
    setSearchCard(true);
    localStorage.setItem("history_card", JSON.stringify(listCard));
  };

  return (
    <View>
      <CardModal
        toggleVisible={() => setIsVisibleRemoveCard(!isVisibleRemoveCard)}
        visible={isVisibleRemoveCard}
        idCard={idCard}
        setSearchCard={setSearchCard}
      />
      <NewCard
        toggleVisible={() => setIsVisibleNewCards(!isVisibleNewCard)}
        visible={isVisibleNewCard}
        setSearchCard={setSearchCard}
      />
      <NavBar onBackPage={handleBack} />
      <View style={styles.container}>
        <Text style={{ color: "#494949", fontSize: 15 }}>
          Formas de pagamento cadastradas
        </Text>
        <View style={styles.containerCards}>
          {cardUser &&
            cardUser.map((cards, index) => {
              return (
                <TouchableOpacity onPress={() => updateInforCard(index)}>
                  <View style={styles.containerListCards}>
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onPress={() => removeCard(index)}
                      >
                        <View>
                          <TiDelete
                            style={{ marginRight: 10 }}
                            color={"#D50505"}
                            size={22}
                          />
                        </View>
                      </TouchableOpacity>
                      <View>
                        <Text style={styles.cardItem}>
                          <AiOutlineCreditCard
                            style={{ marginRight: 10 }}
                            size={30}
                          />
                          **** **** **** {cards.card_number.substr(-4)}
                        </Text>
                      </View>
                    </View>
                    <View style={[!cards.active && { opacity: 0 }]}>
                      <AiOutlineCheck color={"#1BD505"} size={22} />
                    </View>
                  </View>
                </TouchableOpacity>
              );
            })}
        </View>
        <TouchableOpacity>
          <Text
            style={styles.newPaymentForm}
            onPress={() => setIsVisibleNewCards(true)}
          >
            Adicionar forma de pagamento
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
