

export default function MsgConfirmationUser(company, user) {
  
    let allText = "";
    function addLine(text) {
      allText += encodeURIComponent(text);
    }
    function addLineN(text) {
      allText += `*${text}*`;
      addLine("\n");
    }
    // function addEmoji(emoji) {
    //   allText += `${emoji}`;
    //   addLine("");
    // }
  
    // function bkl() {
    //   allText += encodeURIComponent(" \n");
    // }
  
   function sendMsgConfirmationUser() {
    addLineN(`O usuário  ${user.name}, já existe para o número  ${user.phone}! \n`);

    window.open(
        "https://api.whatsapp.com/send?phone=55"+
        company.phone_whatsapp +
          "&text=" +
          allText,
        "_blank"
      );
    }
    sendMsgConfirmationUser();
}

