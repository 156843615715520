import React, { createContext, useContext, useState } from 'react';

const ProductContext = createContext();

export const useProduct = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
    const [scannerProduct, setScannerProduct] = useState();

    return (
        <ProductContext.Provider value={{ scannerProduct, setScannerProduct }}>
            {children}
        </ProductContext.Provider>
    );
};
