import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text } from "react-native";
import Colors from "../Colors";
import { TouchableWithoutFeedback, TouchableOpacity } from "react-native";
import Modal from "modal-react-native-web";
import { useColor } from "../../contexts/colors";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { getSubDomain, isEnvProduction } from "../../utils/url";
import apiDelivery from "../../Api/apiDelivery";
import { TextInput } from "react-native";
export default function FormScanner(props) {
    const { visible, onClose, order } = props;
    const [barcode, setBarcode] = useState(null)
    let history = useHistory();
    const {buttonColor} = useColor();
    const company = getSubDomain();


    const getProductByCode = () => {
        apiDelivery.get(`alves07/product/barcode/${barcode}`)
          .then((response) => {
            if(!response.data.response)
              return toast.dark("Código de barras não encontrado");
            
            if (isEnvProduction()) {
              history.push(`/product/`, {
                item: response.data.response,
                company,
              })
            } else {
              history.push(`/${company}/product/`, {
                item: response.data.response,
                company,
              })
            }
          })
          .catch((error) => {toast.dark("Código de barras não encontrado")})
    }

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
        >
            <View style={styles.container}>
                <TouchableWithoutFeedback
                    onPress={() => {
                    onClose();
                    }}
                >
                <View style={styles.overlayModal}></View>
                </TouchableWithoutFeedback>

                <View style={styles.modalView}>
                    <Text style={styles.textDescription}>
                      Codigo de barras
                    </Text>
                    <TextInput
                      style={styles.input}
                      value={barcode}
                      onChangeText={(text) => setBarcode(text)}
                    />
                    <TouchableOpacity
                        onPress={() => getProductByCode()}
                    >
                        <View style={styles.btnOptionItem}>
                            <Text style={[styles.btnOptionItemText, {color: buttonColor}]}>
                                Pesquisar produto
                            </Text>
                        </View>
                    </TouchableOpacity>
                    <ToastContainer hideProgressBar={true} pauseOnHover />
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    overlayModal: {
        flex: 1,
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    modalView: {
        backgroundColor: "#fff",
        paddingHorizontal: 15,
    },
    textDescription: {
        fontSize: 20,
        textAlign: "center",
        padding: 15,
        marginBottom: 5,
        borderBottomColor: "#ddd",
        borderBottomWidth: 1,
    },
    btnOptionItem: {
        borderBottomWidth: 1,
        borderColor: "#ddd",
        padding: 10,
        alignItems: "center",
    },
    btnOptionItemText: {
        color: Colors.PRIMARY,
        fontSize: 18,
    },
    input: {
      padding: 10,
      borderColor: "gray",
      borderWidth: 1,
      borderRadius: 5,
    },
});
