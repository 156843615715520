import React, { useState } from "react";
import { View, TouchableOpacity, Text, StyleSheet } from "react-native";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useColor } from "../../contexts/colors";
import FormScanner from "../FormScanner";
export default function ButtomCheckProduct(props) {
  const { onVisibleModal } = props;
  const [ModalBarCode, setModalBarCode] = useState(false);
  const {buttonColor, buttonTextColor} = useColor();

  return (
    <View>
      <TouchableOpacity onPress={() => {onVisibleModal()}} style={[styles.btnAddItem, {backgroundColor: buttonColor, color: buttonTextColor}]}>
        <View >
         <Text style={[styles.btnTextItemAdd, {color: buttonTextColor}]}>Digitar Código</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  containerItem: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
  },
  btnAddItem: {
    borderRadius: 5,
    marginHorizontal: '3%',
    alignItems: "center",
    padding: 14,
    zIndex: 1,
    marginTop: -95,
    bottom: 0
  },
  btnTextItemAdd: {
    color: "#fff",
    fontSize: 15,
    textAlign: 'center',
    alignContent: "center",
    width: "auto",
  },
});
