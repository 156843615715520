import { StyleSheet } from "react-native";
import Colors from "../Colors";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  overlayModal: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  labelGroup: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  modalView: {
    backgroundColor: "#fff",
    paddingHorizontal: 15,
  },
  textDescription: {
    fontSize: 20,
    textAlign: "center",
    padding: 15,
    marginBottom: 5,
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
  },
  btnOptionItem: {
    borderBottomWidth: 1,
    borderColor: "#ddd",
    padding: 10,
    alignItems: "center",
  },
  btnOptionItemText: {
    color: Colors.PRIMARY,
    fontSize: 18,
  },
  containerInput: {
    padding: 10,
  },
  labelInput: {
    fontSize: 14,
    marginRight: 1,
  },
  labelOption: {
    fontSize: 10,
    color: "#0c66e4",
  },
  SelectNeighborhoodsLabel: {
    marginBottom: 10,
    marginLeft: 10,
    fontSize: 14,
  },
  input: {
    padding: 10,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
  },
  suggestionsContainer: {
    backgroundColor: "#fff",
    borderColor: "#ddd",
    borderWidth: 1,
    borderRadius: 5,
    marginTop: 4,
    maxHeight: 100,
    overflow: "hidden",
    overflowY: "scroll",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  suggestionItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
  },
  suggestionText: {
    fontSize: 16,
    color: "black",
  },
  noSuggestionsText: {
    padding: 10,
    fontSize: 16,
    color: "grey",
  },
  btnContainer: {
    backgroundColor: Colors.PRIMARY,
    alignItems: "center",
    padding: 20,
    marginTop: 5,
    marginHorizontal: 10,
    borderRadius: 5,
  },
  btnText: {
    color: "#fff",
    fontSize: 16,
  },
  linkNeighBorHoods: {
    marginLeft: 10,
    marginTop: 5,
    fontSize: 14,
    color: Colors.PRIMARY,
  },
  subtitle: {
    fontSize: 12,
    color: "#0c66e4",
    marginBottom: 4,
    marginTop: 4,
  },
  dropdownSelector: {
    width: "100%",
    height: 40,
    borderRadius: 5,
    borderWidth: 0.5,
    alignSelf: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 5,
    paddingRight: 5,
  },
  icon: {
    width: 12,
    height: 12,
  },
  dropdownArea: {
    elevation: 5,
    height: 300,
    marginTop: 10,
    paddingHorizontal: 10,
    alignSelf: "center",
    width: "100%",
    borderWidth: 0.5,
    borderColor: "#8e8e8e",
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  searchInput: {
    width: "100%",
    minHeight: 40,
    alignSelf: "center",
    borderWidth: 0.2,
    borderColor: "#8e8e8e",
    borderRadius: 5,
    marginTop: 15,
    paddingLeft: 10,
  },
  rolesItem: {
    width: "100%",
    alignSelf: "center",
    height: 50,
    justifyContent: "center",
    borderBottomWidth: 0.5,
    borderColor: "#8e8e8e",
  },
  inputWithLoading: {
    position: "relative",
    height: 40,
    paddingTop: 10,
  },
  loadingIndicator: {
    position: 'absolute',
    right: 10,
    top: '55%',
    transform: [{ translateY: -0.5 }],
  },
});
