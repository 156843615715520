import React from "react";
import { View, Image} from "react-native";
import { Linking } from "react-native";
import { styles } from "./styles"
import WhatsappIcon from "../../assets/images/whatsapp.png"
import PhoneIcon from "../../assets/images/telephone.png"

export default function FloatingButton({phoneNumber}) {
  return (
    <View style={styles.containerIcons}>
      <View style={styles.icon} onClick={() => {
        Linking.openURL(`tel:${phoneNumber}`)
      }}>
        <Image source={PhoneIcon} style={{width:'30px', height: '30px'}}/>
      </View>
      <View style={styles.icon} onClick={() => {
          Linking.openURL(`https://api.whatsapp.com/send?phone=55${phoneNumber}&text=Olá, gostaria de algumas informações!`)
        }}>
          <Image source={WhatsappIcon} style={{width:'30px', height: '30px'}}/>
      </View>
    </View>
  )
}