import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import Modal from "modal-react-native-web";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Colors from "../Colors";
import "react-toastify/dist/ReactToastify.min.css";
import { ScrollView } from "react-native";

export default function ModalHour({ company, visible, onClose }) {
  const [visibleModalHour] = useState(false);
  const [hour, setHour] = useState([]);

  useEffect(() => {
    if (company) setHour(company.open_hours);
    
  }, [company]);
  return ( 
    <Modal animationType="slide" transparent={false} visible={visible}>
      <ScrollView>
        <View style={styles.container}>
          <TouchableWithoutFeedback onPress={() => onClose()}>
            <View style={styles.overlayModal}></View>
          </TouchableWithoutFeedback>
          <View style={styles.modalView}>
            <TouchableOpacity
              onPress={() => onClose()}
              visible={visibleModalHour}
            >
              <IoIosCloseCircleOutline
                style={{ fontSize: 25, marginTop: 15, color: Colors.PRIMARY }}
              />
            </TouchableOpacity>
            {hour.map((item, key) => {
              return (
                <View key={"modalHour" + key} style={styles.containerData}>
                  <View style={styles.scheduleContent}>
                    <Text style={styles.textDay}>{item.description}</Text>
                      <div>{item.hour_start} às {item.hour_stop}</div>
                      {item.hour_start1 && <div>{item.hour_start1} às {item.hour_stop1}</div>}
                      {item.hour_start2 && <div>{item.hour_start2} às {item.hour_stop2}</div>}
                      {item.hour_start3 && <div>{item.hour_start3} às {item.hour_stop3}</div>}
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </ScrollView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    flex: 1,
  },
  overlayModal: {
    // height: "100%",
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    height: "100%",
    backgroundColor: "#fff",
    paddingHorizontal: 15,
    paddingVertical: 20,
  },

  btnOptionItem: {
    borderBottomWidth: 1,
    borderColor: "#ddd",
    padding: 40,
    alignItems: "center",
  },
  btnOptionItemText: {
    color: Colors.PRIMARY,
    fontSize: 18,
  },
  IconClose: {
    fontSize: 20,
  },
  containerData: {
    flexDirection: "column",
    justifyContent: "space-between",
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
  },
  textDay: {
    fontSize: 16,
    textAlign: "center",
    padding: 20,
    marginBottom: 5,
    color: Colors.PRIMARY,
    fontWeight: "500",
  },
  scheduleContent: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    textAlign: "center",
    padding: 20,
    marginBottom: 5,
    color: Colors.PRIMARY,
  },
});
