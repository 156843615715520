import React from "react";

const ImageComponent = ({ src }) => {
  const imageStyles = {
    width: "80%",
    height: "auto",
    opacity: 0.8,
    objectFit: "cover",
  };

  return <img src={src} style={imageStyles} alt="Barcode Image" />;
};

export default ImageComponent;
