import React, { useEffect} from 'react';
import { useCookies } from "react-cookie";
import './style.css'
import { useOrderService } from '../../contexts/orderService';
import { ToastContainer} from "react-toastify"
import Helper from '../../utils/Helper';


export default function SelectDeliveryMethod() {
    const {changeOrderMode,SelectedChangeMoney, bag, address, setAddress} = useOrderService();
    const [cookies] = useCookies(["user"]);
  
    function calculateTotalItems() {
      const list = bag.map(({ item, amount, complements }) => {
        return Helper.calculateValueItem(item, amount, complements);
      });
      if (list.length > 0) {
        const value = list.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        });
        return value;
      }
      return 0;
    }

    useEffect(() => {
        if (cookies.user) {
          setAddress(cookies.user.address);
        }
      }, [cookies.user, changeOrderMode]);
      
  return (
    <select defaultValue={''} className="styledSelect" onChange={(event)=>{
        SelectedChangeMoney(0);
      changeOrderMode(event.target.value);
    }}>
        <option  className="text-select" disabled selected value={""}>Metodo de entrega ou retirada</option>
        <option  className="text-select" value="0">Retirar no estabelecimento</option>
        <option  className="text-select" value="1">Endereço: {address}</option>
        <ToastContainer hideProgressBar={true} pauseOnHover/>
    </select>
  );
}

