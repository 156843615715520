import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import { View, Text } from "react-native";
import { useHistory } from "react-router";
import { getSubDomain, isEnvProduction } from "../../utils/url";
import { styles } from "./styles.js";
import { AiOutlineCreditCard, AiOutlineCheck } from "react-icons/ai";
import { TouchableOpacity } from "react-native";
import NewCard from "../NewCard";

export default function CardPayment({ creditCard }) {
  const [cardUser, setCardUser] = useState([]);
  const [isVisibleNewCard, setIsVisibleNewCards] = useState(false);
  const [searchCard, setSearchCard] = useState(false);

  useEffect(() => {
    setCardUser(JSON.parse(localStorage.getItem("history_card")));
    setSearchCard(false);
  }, [searchCard, creditCard]);

  const updateInforCard = (id) => {
    const listCard = cardUser.map((item, index) => {
      if (index === id) return { ...item, active: true };
      return { ...item, active: false };
    });
    setSearchCard(true);
    localStorage.setItem("history_card", JSON.stringify(listCard));
  };

  return (
    <View>
      <View style={styles.container}>
        <NewCard
          toggleVisible={() => setIsVisibleNewCards(!isVisibleNewCard)}
          visible={isVisibleNewCard}
        />
        <Text style={{ color: "#494949", fontSize: 15 }}>
          {cardUser == null
            ? "Nenhuma forma de pagamento encontrada"
            : "Formas de pagamento cadastradas"}
        </Text>
        <View style={styles.containerCards}>
          {cardUser &&
            cardUser.map((cards, index) => {
              return (
                <TouchableOpacity onPress={() => updateInforCard(index)}>
                  <View style={styles.containerListCards}>
                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.cardItem}>
                          <AiOutlineCreditCard
                            style={{ marginRight: 10 }}
                            size={30}
                          />
                          **** **** **** {cards.card_number.substr(-4)}
                        </Text>
                      </View>
                    </View>
                    <View style={[!cards.active && { opacity: 0 }]}>
                      <AiOutlineCheck color={"#1BD505"} size={22} />
                    </View>
                  </View>
                </TouchableOpacity>
              );
            })}
          <TouchableOpacity>
            <Text
              style={styles.newPaymentForm}
              onPress={() => setIsVisibleNewCards(true)}
            >
              Adicionar forma de pagamento
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}
