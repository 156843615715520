import {StyleSheet} from "react-native"
import Colors from "../Colors";


export const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    overlayModal: {
      flex: 1,
      justifyContent: "space-around",
      backgroundColor: "rgba(0,0,0,0.5)",
    },
    modalView: {
      backgroundColor: "#fff",
      paddingHorizontal: 15,
    },
    textDescription: {
      fontSize: 20,
      textAlign: "center",
      padding: 15,
      marginBottom: 5,
      borderBottomColor: "#ddd",
      borderBottomWidth: 1,
    },
    btnOptionItem: {
      borderBottomWidth: 1,
      borderColor: "#ddd",
      padding: 10,
      alignItems: "center",
    },
    btnOptionItemText: {
      color: Colors.PRIMARY,
      fontSize: 18,
    },
  });
  