import {StyleSheet} from "react-native"
import Colors from "../Colors";

export const styles = StyleSheet.create({
    container: {
      zIndex: 10,
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `rgba(0,0,0,0.5)`,
    },
    buttonUpdate:{
      width: "80%",
      height: 35,
      borderRadius: 5,
      marginLeft: 9,
      backgroundColor: Colors.PRIMARY,
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 20,
      color: "#FFFF"
    },
    content:{
      width: "75%",
      height: "65%",
      backgroundColor: "white",
      borderRadius: 5,
      justifyContent: "center",
      alignItems: "center",
    }
  
  });
  