export const roles = [
  { id: 1, name: "Acupunturista" },
  { id: 2, name: "Administrador de dados" },
  { id: 3, name: "Administrador de empresas" },
  { id: 4, name: "Advogado" },
  { id: 5, name: "Agente de viagens" },
  { id: 6, name: "Analista de controle de qualidade" },
  { id: 7, name: "Analista de marketing" },
  { id: 8, name: "Analista de redes" },
  { id: 9, name: "Analista de recursos humanos" },
  { id: 10, name: "Analista de sistemas" },
  { id: 11, name: "Analista de suporte" },
  { id: 12, name: "Analista econômico" },
  { id: 13, name: "Analista financeiro" },
  { id: 14, name: "Arquiteto" },
  { id: 15, name: "Arquiteto de sistemas" },
  { id: 16, name: "Assessor de imprensa" },
  { id: 17, name: "Assessor jurídico" },
  { id: 18, name: "Atendente" },
  { id: 19, name: "Auditor financeiro" },
  { id: 20, name: "Auxiliar de contabilidade" },
  { id: 21, name: "Auxiliar de cozinha" },
  { id: 22, name: "Auxiliar de escritório" },
  { id: 23, name: "Auxiliar de estoque" },
  { id: 24, name: "Auxiliar de farmácia" },
  { id: 25, name: "Auxiliar de limpeza" },
  { id: 26, name: "Auxiliar de logística" },
  { id: 27, name: "Auxiliar de serviços gerais" },
  { id: 28, name: "Bartender" },
  { id: 29, name: "Biomédico" },
  { id: 30, name: "Caixa de loja" },
  { id: 31, name: "Camareira" },
  { id: 32, name: "Carpinteiro" },
  { id: 33, name: "Chefe de cozinha" },
  { id: 34, name: "Cientista de dados" },
  { id: 35, name: "Cirurgião" },
  { id: 36, name: "Coach" },
  { id: 37, name: "Consultor de negócios" },
  { id: 38, name: "Consultor legal" },
  { id: 39, name: "Contador" },
  { id: 40, name: "Coordenador de eventos" },
  { id: 41, name: "Coordenador pedagógico" },
  { id: 42, name: "Cuidador de idosos" },
  { id: 43, name: "Delegado de polícia" },
  { id: 44, name: "Dentista" },
  { id: 45, name: "Desenvolvedor" },
  { id: 46, name: "Desenvolvedor de software" },
  { id: 47, name: "Desenvolvedor web" },
  { id: 48, name: "Designer de interiores" },
  { id: 49, name: "Designer gráfico" },
  { id: 50, name: "Diretor de arte" },
  { id: 51, name: "Economista" },
  { id: 52, name: "Educador social" },
  { id: 53, name: "Eletricista" },
  { id: 54, name: "Enfermeiro" },
  { id: 55, name: "Engenheiro" },
  { id: 56, name: "Engenheiro aeronáutico" },
  { id: 57, name: "Engenheiro agrônomo" },
  { id: 58, name: "Engenheiro ambiental" },
  { id: 59, name: "Engenheiro civil" },
  { id: 60, name: "Engenheiro de alimentos" },
  { id: 61, name: "Engenheiro de automação" },
  { id: 62, name: "Engenheiro de controle e automação" },
  { id: 63, name: "Engenheiro de minas" },
  { id: 64, name: "Engenheiro de petróleo" },
  { id: 65, name: "Engenheiro de produção" },
  { id: 66, name: "Engenheiro de redes" },
  { id: 67, name: "Engenheiro de segurança do trabalho" },
  { id: 68, name: "Engenheiro de software" },
  { id: 69, name: "Engenheiro elétrico" },
  { id: 70, name: "Engenheiro eletrônico" },
  { id: 71, name: "Engenheiro florestal" },
  { id: 72, name: "Engenheiro mecânico" },
  { id: 73, name: "Engenheiro químico" },
  { id: 74, name: "Escritor" },
  { id: 75, name: "Escriturário judicial" },
  { id: 76, name: "Especialista em segurança da informação" },
  { id: 77, name: "Especialista em SEO" },
  { id: 78, name: "Especialista em UX/UI" },
  { id: 79, name: "Esteticista" },
  { id: 80, name: "Executivo de contas" },
  { id: 81, name: "Executivo de vendas" },
  { id: 82, name: "Farmacêutico" },
  { id: 83, name: "Fisioterapeuta" },
  { id: 84, name: "Fonoaudiólogo" },
  { id: 85, name: "Fotógrafo" },
  { id: 86, name: "Garçom" },
  { id: 87, name: "Gerente comercial" },
  { id: 88, name: "Gerente de hotel" },
  { id: 89, name: "Gerente de marketing" },
  { id: 90, name: "Gerente de recursos humanos" },
  { id: 91, name: "Gerente de restaurante" },
  { id: 92, name: "Gerente de TI" },
  { id: 93, name: "Gestor de projetos" },
  { id: 94, name: "Guia de turismo" },
  { id: 95, name: "Ilustrador" },
  { id: 96, name: "Instrutor de academia" },
  { id: 97, name: "Instrutor de cursos livres" },
  { id: 98, name: "Instrutor de idiomas" },
  { id: 99, name: "Jardineiro" },
  { id: 100, name: "Jornalista" },
  { id: 101, name: "Juiz" },
  { id: 102, name: "Lavador de carros" },
  { id: 103, name: "Manobrista" },
  { id: 104, name: "Marceneiro" },
  { id: 105, name: "Mecânico de manutenção" },
  { id: 106, name: "Médico" },
  { id: 107, name: "Médico veterinário" },
  { id: 108, name: "Montador" },
  { id: 109, name: "Motorista" },
  { id: 110, name: "Nutricionista" },
  { id: 111, name: "Operador de caixa" },
  { id: 112, name: "Operador de máquinas" },
  { id: 113, name: "Orientador educacional" },
  { id: 114, name: "Outros" },
  { id: 115, name: "Pedagogo" },
  { id: 116, name: "Pediatra" },
  { id: 117, name: "Personal trainer" },
  { id: 118, name: "Pintor" },
  { id: 119, name: "Pizzaiolo" },
  { id: 120, name: "Porteiro" },
  { id: 121, name: "Produtor de conteúdo" },
  { id: 122, name: "Professor" },
  { id: 123, name: "Professor de artes" },
  { id: 124, name: "Professor de ciências" },
  { id: 125, name: "Professor de educação física" },
  { id: 126, name: "Professor de geografia" },
  { id: 127, name: "Professor de história" },
  { id: 128, name: "Professor de informática" },
  { id: 129, name: "Professor de matemática" },
  { id: 130, name: "Professor de música" },
  { id: 131, name: "Professor de português" },
  { id: 132, name: "Profissional de educação física" },
  { id: 133, name: "Promotor de eventos" },
  { id: 134, name: "Promotor de justiça" },
  { id: 135, name: "Psicanalista" },
  { id: 136, name: "Psicólogo" },
  { id: 137, name: "Publicitário" },
  { id: 138, name: "Recepcionista" },
  { id: 139, name: "Representante comercial" },
  { id: 140, name: "Salva-vidas" },
  { id: 141, name: "Segurança" },
  { id: 142, name: "Segurança privado" },
  { id: 143, name: "Soldador" },
  { id: 144, name: "Suporte técnico" },
  { id: 145, name: "Tapeceiro" },
  { id: 146, name: "Técnico de enfermagem" },
  { id: 147, name: "Técnico desportivo" },
  { id: 148, name: "Técnico em automação industrial" },
  { id: 149, name: "Técnico em eletrônica" },
  { id: 150, name: "Técnico em informática" },
  { id: 151, name: "Técnico em mecânica" },
  { id: 152, name: "Técnico em nutrição" },
  { id: 153, name: "Técnico florestal" },
  { id: 154, name: "Terapeuta" },
  { id: 155, name: "Terapeuta ocupacional" },
  { id: 156, name: "Tratador de animais" },
  { id: 157, name: "Tutor e-learning" },
  { id: 158, name: "Vigilante" },
  { id: 159, name: "Web designer" },
  { id: 160, name: "Zelador" },
];
