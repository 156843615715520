import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text } from "react-native";
import Colors from "../Colors";
import { TouchableWithoutFeedback, TouchableOpacity } from "react-native";
import Modal from "modal-react-native-web";
import { useOrderService } from "../../contexts/orderService";
import apiDelivery from "../../Api/apiDelivery";
import { getSubDomain } from "../../utils/url";
import Helper from "../../utils/Helper";
import { useColor } from "../../contexts/colors";

export default function Voucher(props) {
  const {
    voucherValue,
    setVoucherSelected,
    voucherSelected,
    voucherData,
    totalVoucher,
  } = useOrderService();
  const { visible, onClose } = props;
  const { buttonColor, buttonTextColor} = useColor();
  let company = getSubDomain();

  return (
    <Modal animationType="slide" transparent={true} visible={visible}>
      <View style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            onClose();
          }}
        >
          <View style={styles.overlayModal}></View>
        </TouchableWithoutFeedback>
        <View style={styles.modalView}>
          <Text style={styles.textDescription}>Voucher</Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ fontSize: "22px" }}>Valor</Text>
            <Text style={{ fontSize: "22px" }}>
              R$ {totalVoucher > 0 ? Helper.maskMoney(totalVoucher) : 0}
            </Text>
          </View>

          

          {totalVoucher > 0  && !voucherSelected &&
          (
              <TouchableOpacity
                onPress={(event) => {
                  setVoucherSelected(true);
                  onClose();
                }}
              >
                <View style={styles.btnOptionItem}>
                  <Text style={[styles.btnOptionItemText, {color: buttonColor}]}>
                    Selecionar voucher
                  </Text>
                </View>
              </TouchableOpacity>
          )
          }

          {voucherSelected === true && (
            <TouchableOpacity
              onPress={(event) => {
                setVoucherSelected(false);
                onClose();
              }}
            >
              <View style={styles.btnOptionItem}>
                <Text style={[styles.btnOptionItemText, {color: buttonColor}]}>Remover Voucher</Text>
              </View>
            </TouchableOpacity>
          )}

          <TouchableOpacity
            onPress={(event) => {
              onClose();
            }}
          >
            <View style={[styles.btnOptionItem]}>
              <Text style={[styles.btnOptionItemText, {color: buttonColor}]}>Cancelar</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  overlayModal: {
    flex: 1,
    justifyContent: "space-around",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    backgroundColor: "#fff",
    paddingHorizontal: 15,
  },
  textDescription: {
    fontSize: 20,
    textAlign: "center",
    padding: 15,
    marginBottom: 5,
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
  },
  btnOptionItem: {
    borderBottomWidth: 1,
    borderColor: "#ddd",
    padding: 10,
    alignItems: "center",
  },
  btnOptionItemText: {
    color: Colors.PRIMARY,
    fontSize: 18,
  },
});
