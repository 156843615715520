import React from 'react';
import { ActivityIndicator, View } from 'react-native-web';

export default function Preloader (value) {
    if (value.status) {
        return (
            <View
              style={{
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                padding: 20,
                height: "100vh"
              }}
            >
              <ActivityIndicator />
            </View>
          );
    } else {
        return '';
    }
}