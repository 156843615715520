import React, { useEffect, useState } from "react";
import { StyleSheet, SafeAreaView, View } from "react-native";
import BagMenu from "../../components/BagMenu";
import { useOrderService } from "../../contexts/orderService";
import BannerProductMenu from "../../components/BannerProductMenu";
import ModalPromotionBanner from "../../components/ModalPromotionBanner/ModalPromotionBanner";
import ListProductsMenu from "../../components/ListProductsMenu";
import NotFound from "../../components/NotFound/NotFound";
import { getSubDomain } from "../../utils/url";
import ModalEstablishmentOpen from "../../components/ModalEstablishmentOpen";

let loading = false;
export default function Restaurant({ history }) {
  const { bag } = useOrderService();
  const {
    company: companySelected,
    modalPromotion,
    setDetailCompany,
    getDataCompany,
    openOrder
  } = useOrderService();
  let company = getSubDomain();
  const [error, setError] = useState(false);

  useEffect(() => {
    async function getCategories() {
      loading = true;
      const data = await getDataCompany(company);
      if (data === null) return setError(true);
      return setDetailCompany(data);
    }
    if (companySelected == null && !error && !loading) {
      getCategories();
      return;
    }
  }, [company]);

  function CheckScreen() {
    if (error) return <NotFound />;
    return (
      <>
        <ModalPromotionBanner />
        <BannerProductMenu />
        {!modalPromotion ? <ModalEstablishmentOpen /> : null}
      <ListProductsMenu
        restaurant={company}
        isBagActive={bag.length > 0}
        history={history}

        footerComponent={bag.length > 0 ? <BagMenu history={history} /> : null}
      />
      </>
    );
  }
  if (error) return <NotFound />;
  return (
    <SafeAreaView style={styles.container}>
      {companySelected == null ? null : <CheckScreen />}
    </SafeAreaView>
  );
}
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: "#ddd" },
  contentContainer: {
    flexGrow: 1,
  },
  navContainer: {
    height: 70,
    marginHorizontal: 10,
  },
  btnBackNavBar: {
    marginTop: 5,
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
  },
  titleStyle: {
    color: "white",
    fontWeight: "bold",
    fontSize: 18,
  },
  titleContent: {
    fontSize: 18,
  },
  subTitleContent: {
    color: "#666",
  },
  ratingTitlleContent: {
    textAlign: "right",
    color: "#ffab00",
  },
  contentTimeDelivery: {
    display: "flex",
    flexDirection: "row",
    borderWidth: 1,
    padding: 10,
    borderColor: "#ddd",
    marginVertical: 10,
    borderRadius: 5,
    alignItems: "center",
  },
  contentItemTimeDelivery: {
    marginHorizontal: 10,
  },
  listItems: {
    flex: 1,
    backgroundColor: "#fff",
  },
  contentItem: {
    marginHorizontal: 10,
    paddingVertical: 10,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
    alignItems: "center",
    marginBottom: 10,
  },
  detailsItem: {
    flex: 1,
    paddingHorizontal: 10,
  },

  titleItem: {
    fontSize: 14,
    marginBottom: 5,
  },
  descriptionItem: {
    color: "#666",
  },
});
