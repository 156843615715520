import React from "react";
import { View, StyleSheet, Text } from "react-native";
import Colors from "../Colors";
import { TouchableWithoutFeedback, TouchableOpacity } from "react-native";
import Modal from "modal-react-native-web";
import { useColor } from "../../contexts/colors";

export default function OptionItemModal(props) {
  const {buttonColor} = useColor();
  const { product, onClose, onEditItem, onRemove } = props;

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={!!product}
      onRequestClose={() => {
        onClose();
      }}
    >
      <View style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            onClose();
          }}
        >
          <View style={styles.overlayModal}></View>
        </TouchableWithoutFeedback>
        <View style={styles.modalView}>
          <Text style={styles.textDescription}>
            {verifyItemSelected(product)}
          </Text>
          <TouchableOpacity
            onPress={() => {
              onClose();
              onEditItem();
            }}
          >
            <View style={styles.btnOptionItem}>
              <Text style={[styles.btnOptionItemText, {color: buttonColor}]}>Editar item</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              onClose();
              onRemove();
            }}
          >
            <View style={styles.btnOptionItem}>
              <Text style={[styles.btnOptionItemText, {color: buttonColor}]}>Excluir item</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}

function verifyItemSelected(product) {
  if (!product) return ``;
  return `${product.amount}x ${product.item.name}`;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  overlayModal: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    backgroundColor: "#fff",
    paddingHorizontal: 15,
  },
  textDescription: {
    fontSize: 20,
    textAlign: "center",
    padding: 15,
    marginBottom: 5,
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
  },
  btnOptionItem: {
    borderBottomWidth: 1,
    borderColor: "#ddd",
    padding: 10,
    alignItems: "center",
  },
  btnOptionItemText: {
    color: Colors.PRIMARY,
    fontSize: 18,
  },
});
