export function getSubDomain()
{
  let host = window.location.host;
  let parts = host.split(".");

  if(!isIp() && parts.length > 3){
      return parts[0];
  }

  return window.location.href.split("/")[3];
}

export function isEnvProduction(){
    let host = window.location.host;

    let parts = host.split(".");
    
    if(isIp() ||parts.length <= 3){
      return false;
    }

    return true;
} 

function isIp(){
  return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(window.location.hostname))
}