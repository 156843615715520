import { StyleSheet } from "react-native";
import Colors from "../../components/Colors";

export const styles = StyleSheet.create({
  container: {
    padding: 15,
    height: "100%",
    flex: 1,
  },
  containerCards: {
    marginTop: 10,
    borderBottomColor: "#d6d6d6",
    borderBottomWidth: 1,
  },
  cardItem: {
    fontSize: 18,
    marginVertical: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  overlayModal: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    backgroundColor: "#fff",
    paddingHorizontal: 15,
    height: "100vh",
    paddingVertical: 20,
  },
  textDescription: {
    fontSize: 14,
    textAlign: "center",
    padding: 20,
    marginBottom: 5,
    borderBottomColor: "#ddd",
    borderBottomWidth: 1,
  },
  btnOptionItem: {
    borderBottomWidth: 1,
    borderColor: "#ddd",
    padding: 40,
    alignItems: "center",
  },
  btnOptionItemText: {
    color: Colors.PRIMARY,
    fontSize: 18,
  },
  btn: {
    width: "100%",
    height: 50,
    borderRadius: 5,
    backgroundColor: Colors.PRIMARY,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 10,
  },
  textInputAdress: {
    borderBottomWidth: 1,
    borderColor: "gray",
    fontSize: 20,
    textAlign: "center",
    padding: 5,
  },
  IconClose: {
    fontSize: 20,
  },
  newPaymentForm: {
    marginTop: 12,
    fontSize: 15,
    color: Colors.PRIMARY,
  },
  containerListCards: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
});
