import Modal from "modal-react-native-web";
import React, { useEffect, useState } from "react";
import {
  TouchableWithoutFeedback,
  View,
  Text,
  TextInput,
  StyleSheet,
  TouchableOpacity,
} from "react-native-web";
import InputMask from "react-input-mask";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getSubDomain } from "../../utils/url";
import InputPhoneMask from "../InputPhoneMask/InputPhoneMask";
import { ActivityIndicator } from "react-native";
const ModalAccountExist = ({ onSelectCustomer }) => {
  const [visibleModalAccount, setVisibleModalAccount] = useState(false);
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let company = getSubDomain();
  let baseUrlApi = process.env.REACT_APP_API;

  useEffect(() => {
    setVisibleModalAccount(true);
  }, []);

  const handleModalClose = () => {
    setVisibleModalAccount(false);
  };

  const handleYes = () => {
    setStep(2);
  };

  const handleNo = () => {
    setVisibleModalAccount(false);
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseUrlApi}${company}/customer`);
      const data = await response.json();
      if (data.status) {
        const results = data.response.filter((info) =>
          info.phone_contact.includes(phoneNumber)
        );
        if (results.length === 0) {
          toast.error(
            "Conta não encontrada. Por favor, tente novamente com um número de telefone diferente."
          );
        } else {
          setSearchResults(results);
        }
      } else {
        toast.error(
          "Erro ao buscar dados. Por favor, tente novamente mais tarde."
        );
      }
    } catch (error) {
      toast.error(
        "Erro ao buscar dados. Por favor, tente novamente mais tarde."
      );
      console.error("Erro ao buscar dados:", error);
    }
    setIsLoading(false);
  };

  const handleSelect = (customer) => {
    onSelectCustomer(customer);
    setVisibleModalAccount(false);
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visibleModalAccount}
    >
      <TouchableWithoutFeedback>
        <View style={styles.modalBackground}>
          <View style={styles.modalContent}>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={handleModalClose}
            >
              <Text style={styles.closeButtonText}>X</Text>
            </TouchableOpacity>
            {step === 1 && (
              <>
                <Text style={styles.text}>Você já possui conta?</Text>
                <View style={styles.buttonContainer}>
                  <TouchableOpacity style={styles.button} onPress={handleYes}>
                    <Text style={styles.buttonText}>Sim</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={styles.button} onPress={handleNo}>
                    <Text style={styles.buttonText}>Não</Text>
                  </TouchableOpacity>
                </View>
              </>
            )}
            {step === 2 && (
              <View style={styles.containerModal}>
                <Text style={styles.tittleModal}>Verifique seu cadastro.</Text>
                <Text style={styles.descriptionModal}>
                  Por favor, insira seu número de telefone.
                </Text>
                <InputMask
                    placeholder="(99) 99999-9999"
                  type="tel"
                  value={phoneNumber}
                  mask="(99) 99999-9999"
                  maskchar={null}
                  onChange={(event) => {
                    const rawValue = event.target.value.replace(/\D/g, "");
                    setPhoneNumber(rawValue);
                  }}
                  style={{
                    width: "100%",
                    padding: 10,
                    borderWidth: 1,
                    borderColor: "#ccc",
                    borderRadius: 5,
                    marginTop: 20,
                  }}
                />
                <TouchableOpacity
                  style={styles.buttonModal}
                  onPress={handleSearch}
                >
                  {isLoading ? (
                    <ActivityIndicator size={18} color="white" />
                  ) : (
                    <Text style={styles.buttonText}>Buscar</Text>
                  )}
                </TouchableOpacity>
                {searchResults.length > 0 && (
                  <View style={styles.resultContainer}>
                    {searchResults.map((customer, index) => (
                      <TouchableOpacity
                        key={index}
                        style={styles.resultItem}
                        onPress={() => handleSelect(customer)}
                        activeOpacity={0.6}
                      >
                        <Text style={styles.resultText}>
                          Cadastro Encontrado: {customer.full_name}
                        </Text>
                      </TouchableOpacity>
                    ))}
                  </View>
                )}
              </View>
            )}
          </View>
        </View>
      </TouchableWithoutFeedback>
      <ToastContainer hideProgressBar={true} pauseOnHover limit={1} />
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalBackground: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  resultItem: {
    padding: 15,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    marginTop: 10,
    width: "100%",
    alignItems: "center",
    backgroundColor: "#f9f9f9",
  },
  resultText: {
    fontSize: 20,
  },
  modalContent: {
    width: "100%",
    padding: 20,
    backgroundColor: "white",
    marginTop: 50,
    alignItems: "center",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: "black",
    borderRadius: 15,
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  closeButtonText: {
    color: "white",
    fontWeight: "bold",
  },
  text: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
  },
  tittleModal: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 10,
  },
  descriptionModal: {
    fontSize: 20,
    fontWeight: "semibold",
    textAlign: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    gap: 10,
    marginTop: 20,
    width: "100%",
  },
  button: {
    flex: 1,
    backgroundColor: "black",
    padding: 15,
    marginTop: 5,
    borderRadius: 5,
    alignItems: "center",
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    fontSize: 16,
  },
  input: {
    width: "100%",
    padding: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    marginTop: 20,
  },
  resultContainer: {
    marginTop: 20,
    width: "100%",
    alignItems: "center",
  },
  containerModal: {
    width: "100%",
    padding: 10,
  },
  buttonModal: {
    flex: 1,
    backgroundColor: "black",
    padding: 15,
    marginTop: 15,
    borderRadius: 5,
    alignItems: "center",
  },
  resultItem: {
    padding: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    marginTop: 10,
    width: "100%",
    alignItems: "center",
    backgroundColor: "#f9f9f9",
  },
  resultText: {
    fontSize: 16,
  },
});

export default ModalAccountExist;
