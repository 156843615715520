import React from "react";
import { Linking } from "react-native";
import Helper from "../../utils/Helper";

export default function msgWhats(request, company, response) {
  let allText = "";
  function addLine(text) {
    allText += encodeURIComponent(text);
  }
  
  function addLineN(text) {
    allText += `*${text}*`;
    addLine("\n");
  }

  const neighborhood = JSON.parse(request.user.neighborhood);
  addLineN(`${request.companyData.fantasy_name}\n`);
  addLineN(`--------------------------------------\n`);
  addLine(`\n`);
  addLineN(`🚩 Acompanhe seu Pedido: https://${request.companyData.url}.zappedis.com.br/myOrders/${request.response.id}`);
  addLine(`\n`);
  addLineN(`--------------------------------------\n`);

  addLineN(`👤 Cliente: ${request.user.name}`);
  if (request.orderMode === "1") {
    addLineN(
      `🚚 Modo de entrega: Entregar em ${request.user.address}  Nº${request.user.numberAddress}`
    );
    {
      request.user.referencePoint &&
        addLineN(`🗺️ Ponto de Referência: ${request.user.referencePoint}\n`);
    }
    {
      request.user.complement &&
        addLineN(`➕ Complemento: ${request.user.complement}\n`);
    }
  } else {
    addLineN(`🚚 Modo de entrega: Retirar no estabelecimento`);
  }
  addLineN(`🏘️ Bairro: ${neighborhood.neighborhood}\n`);
  addLineN(`☎ Telefone: ${request.user.phone}\n`);
  {
    request.paymentForm &&
      addLineN(`💸Pagamento: ${request.paymentForm.description}\n`);
  }
  {
    !request.paymentForm && addLineN(`💸Pagamento: Voucher\n`);
  }

  if (request.changeMoney !== null && request.changeMoney !== 0)
    addLine(
      `💱Troco para: ${Helper.maskMoney(parseFloat(request.changeMoney))}  \n`
    );
  if (request.changeMoney === null && request.changeMoney === 0) addLineN(``);
  addLineN(`--------------------------------------\n`);
  // addLineN("---------------------------- \n ");
  addLine(`*Pedido: ${request.response.order_code}*`);
  addLine(`  \n `);
  addLineN(`--------------------------------------\n`);
  let amount = 0;
  let valueTotalComplements = 0;

  for (let key in request.bag) {
    let pedido = request.bag[key];
    let price = Helper.calculateValueItem(
      pedido.item,
      pedido.amount,
      pedido.complements
    );

    addLine(
      `🔹 ${pedido.amount}x ${pedido.item.name} - R$ ${Helper.maskMoney(
        parseFloat(pedido.item.sale_price)
      )} \n`
    );
    amount += price;

    const groupComplements = groupComplementItem(pedido.complements);
    groupComplements.map(({ group, complements }) => {
      addLine(`      ▫️ ${group.name}\n`);
      complements.map(({ amount, complement }) => {
        valueTotalComplements += JSON.parse(complement.price) * amount;
        addLine(
          `            ▪️ ${amount}x  ${
            complement.name
          } - (R$ ${Helper.maskMoney(JSON.parse(complement.price))}) \n`
        );
        return "";
      });
    });
    if (pedido.observation === "") {
      addLine(``);
    } else {
      addLine(`Observação: ${pedido.observation} \n`);
    }
    // addLine(`R$ ${Helper.maskMoney(price)}\n`);
    // amount += parseFloat(Helper.maskMoney(price));
  }
  //tttttttttttt
  let subTotal = amount;
  if (request.orderMode === "1" && request.bag && !request.isFreeShipping) {
    amount += parseFloat(request.neighborhood.price);
  }
  // let subTotal = amount
  //  amount += parseFloat(request.neighborhood.price)
  addLineN("--------------------------------------\n");
  addLine(
    `💲 Subtotal: R$ ${Helper.maskMoney(
      parseFloat(request.calculateTotalItems())
    )}\n`
  );
  if (request.orderMode === "1" && request.bag && !request.isFreeShipping) {
    addLineN(
      `💸Taxa de entrega: R$ ${Helper.maskMoney(
        parseFloat(request.neighborhood.price)
      )}\n`
    );
  } else {
    addLineN("💸 Taxa de entrega: Sem taxa");
  }
  if (request.voucherSelected) {
    addLine(
      `🎟️ Voucher: - R$ ${Helper.maskMoney(request.printVoucherValue())}\n`
    );
  }

  if (
    request.printVoucherValue() &&
    parseFloat(subTotal) < request.printVoucherValue() &&
    request.isFreeShipping
  ) {
    addLine(
      `🎟️ Voucher Disponível:  R$ ${Helper.maskMoney(
        request.totalVoucher - parseFloat(subTotal)
      )}\n`
    );
  }

  if (
    request.printVoucherValue() &&
    parseFloat(subTotal) < request.printVoucherValue() &&
    !request.isFreeShipping
  ) {
    addLine(
      `🎟️ Voucher Disponível:  R$ ${Helper.maskMoney(
        request.totalVoucher -
          parseFloat(subTotal) -
          parseFloat(request.neighborhood.price)
      )}\n`
    );
  }

  addLine(`💰 Total: R$ ${Helper.maskMoney(request.printTotalValue())} \n`);

  addLineN(`--------------------------------------\n`);

  {
    request.response.pix_key && request.paymentForm.description.substr(0, 3) === 'PIX' &&
      addLine(`📲 *Pix copiar e cola:* ${request.response.pix_key}`);
  }

  addLine("\n \n \n");
  addLine(`\n`);
  addLine(`\n`);
  addLine(`Envie seu pedido ........... 👉🏼`);

  const sendMessage =
    "https://api.whatsapp.com/send?phone=55" +
    request.companyData.phone_whatsapp +
    "&text=" +
    allText;
  Linking.openURL(sendMessage);
}

function groupComplementItem(complements) {
  const listComplements = [];
  const groups = [];
  complements.map((group) => {
    if (group.items) {
      group.items.map((item) => {
        let objectSelected = listComplements.find((object) => {
          return object.complement.id == item.id;
        });

        if (objectSelected) {
          objectSelected.amount++;
        } else {
          listComplements.push({
            complement: item,
            amount: 1,
            group: { groupId: group.groupId, name: group.name },
          });
        }
      });
    }

    if (!group.items) {
      let objectSelected = listComplements.find((object) => {
        return object.complement.id == group.product_complement.id;
      });

      if (objectSelected) {
        objectSelected.amount++;
      } else {
        listComplements.push({
          complement: group.product_complement,
          amount: 1,
          group: {
            groupId: group.id,
            name: group.product_complement.description,
          },
        });
      }
    }
  });

  listComplements.map(({ complement, amount, group }) => {
    let objectSelected = groups.find((object) => {
      return object.group.groupId === group.groupId;
    });

    if (objectSelected) {
      objectSelected.complements.push({ complement, amount });
    } else {
      groups.push({ complements: [{ complement, amount }], group });
    }
  });

  return groups;
}
