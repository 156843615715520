import { StyleSheet } from "react-native";
import Colors from "../../components/Colors";

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  containerScroll: {
    flex: 1,
    backgroundColor: "#fff",
  },
  logo: {
    height: 200,
    width: "100%",
    marginVertical: 5,
    borderRadius: 5,
    alignSelf: "center",
  },
  content: {
    marginHorizontal: 15,
  },
  descriptionItem: {
    marginVertical: 11,
    color: "#666",
    marginHorizontal: 8,
  },
  priceItem: {
    fontSize: 13,
    marginBottom: 20,
    marginHorizontal: 8,
  },
  footer: {
    borderTopColor: "#f5f5f5",
    borderTopWidth: 1,
    backgroundColor: "#fff",
    bottom: 0,
    padding: 10,
    display: "flex",
    flexDirection: "row",
  },
  btnContainerAddItem: {
    flex: 1,
  },
  btnAddItem: {
    flex: 1,
    borderRadius: 5,
    marginLeft: 9,
    backgroundColor: Colors.PRIMARY,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 14,
  },
  btnTextItemAdd: {
    flex: 1,
    color: "#fff",
    fontSize: 15,
    alignContent: "center",
    width: "auto",
  },
});
