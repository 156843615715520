import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    containerIcons:{
      position: "absolute",
      top: "50px",
      right: "20px",
      height: "90px",
      display: "flex",
      justifyContent: "space-around"
    },
    icon: {
      shadowColor: "#000",
      shadowOffset: {
      width: 0,
      height: 12,
      },
      shadowOpacity: 0.58,
      shadowRadius: 16.00,
      elevation: 24,
      borderRadius: "15px",
     },
  })