import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  TextInput,
  TouchableWithoutFeedback,
} from "react-native";
import Modal from "modal-react-native-web";
import Colors from "../Colors/";
import NeighborhoodsWhats from "../MsgWhats/NeighborhoodsWhats";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useColor } from "../../contexts/colors";

export default  function ModalNullNeightborhood({company,visible, onClose}) {
    const [text, setText] = useState("")
    const {buttonColor, buttonTextColor} = useColor();
    
  return (
    <Modal
    animationType="slide"
    transparent={true}
    visible={visible}
  >
    <View style={styles.container}>
      <TouchableWithoutFeedback
        // onPress={() => {
        //   onClose();
        // }}
      >
        <View style={styles.overlayModal}></View>
      </TouchableWithoutFeedback>
      <View style={styles.modalView}>
          <TouchableOpacity
            onPress={() => {onClose(false)}}
            visible={visible}
          >
          <IoIosCloseCircleOutline  style={{fontSize: 25, marginTop: 20, color: buttonColor}} />
          </TouchableOpacity>
          
        <Text style={styles.textDescription}>
          Qual seu bairro?
          {/* {verifyItemSelected(product)} */}
        </Text>

        <View>
          <View style={[styles.btnOptionItem]}>
            <TextInput onChangeText={setText}  value={text} style={styles.textInputAdress} underlineColor='black'/>
          </View>
          <TouchableOpacity
           onPress={() => {
            NeighborhoodsWhats(company.phone_whatsapp,text)
        }}

            style={[styles.btn,  {backgroundColor: buttonColor}]}
          >
            <Text style={{ color: buttonTextColor }}>Enviar</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  </Modal>
  )
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    overlayModal: {
      flex: 1,
      backgroundColor: "rgba(0,0,0,0.5)",
    },
    modalView: {
      backgroundColor: "#fff",
      paddingHorizontal: 15,
    },
    textDescription: {
      fontSize: 20,
      textAlign: "center",
      padding: 20,
      marginBottom: 5,
      borderBottomColor: "#ddd",
      borderBottomWidth: 1,
    },
    btnOptionItem: {
      borderBottomWidth: 1,
      borderColor: "#ddd",
      padding: 40,
      alignItems: "center",
    },
    btnOptionItemText: {
      color: Colors.PRIMARY,
      fontSize: 18,
    },
    btn: {
      width: "100%",
      height: 50,
      borderRadius: 5,
      backgroundColor: Colors.PRIMARY,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 20,
      marginBottom: 10,
    },
    textInputAdress: {
        borderBottomWidth:1,
        borderColor: 'gray',
        fontSize: 20,
        textAlign: "center",
        padding: 5
        
    },
    IconClose: {
        fontSize: 20
    }
  });
  