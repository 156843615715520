import React, {useState, useEffect} from 'react';
import { 
    View, 
    Text,
    TouchableOpacity,
    StyleSheet,
    TouchableWithoutFeedback
    } from 'react-native';
import Modal from 'modal-react-native-web';
import Colors from '../Colors';
import { IoIosCloseCircleOutline } from 'react-icons/io';


export default function ModalHour({ visible, onClose}){
    return(
        <>
            <Modal 
                animationType="slide"
                trasparent={false} 
                visible={visible}
             >
                <View style={styles.container}>
                    <TouchableWithoutFeedback>
                        <View style={styles.overlayModal}></View>
                    </TouchableWithoutFeedback>
                    <View style={styles.modalView}>
                    <TouchableOpacity 
                     onPress={() => {onClose()}}
                    > 
                        <IoIosCloseCircleOutline
                            style={{ fontSize: 25,
                                 marginTop: 15, 
                                 color: Colors.PRIMARY}}
                        />
                    </TouchableOpacity>
                            <View style={styles.containerData}>
                                <Text style={styles.textDay}>Terça Feira</Text>
                                <Text style={styles.textHour}>
                                    das 07:00 as 20:00
                                </Text>
                            </View>
                    </View>
                </View>
            </Modal>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
      },
      overlayModal: {
        height: "100%",
        flex: 1,
        backgroundColor: "rgba(0,0,0,0.5)",
      },
      modalView: {
        height: "100%",
        backgroundColor: "#fff",
        paddingHorizontal: 15,
        paddingVertical: 20,
      },
    
      btnOptionItem: {
        borderBottomWidth: 1,
        borderColor: "#ddd",
        padding: 40,
        alignItems: "center",
      },
      btnOptionItemText: {
        color: Colors.PRIMARY,
        fontSize: 18,
      },
      IconClose: {
        fontSize: 20,
      },
      containerData: {
        flexDirection: "row",
        justifyContent: "space-between",
        borderBottomColor: "#ddd",
        borderBottomWidth: 1,
      },
      textDay: {
        fontSize: 12,
        textAlign: "center",
        padding: 20,
        marginBottom: 5,
    
        fontWeight: "500",
      },
      textHour: {
        fontSize: 12,
        textAlign: "center",
        padding: 20,
        marginBottom: 5,
        color: Colors.PRIMARY,
      },
});