import React, { useState } from "react";
import { View, Text, TextInput } from "react-native-web";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { TouchableWithoutFeedback, TouchableOpacity } from "react-native";
import Modal from "modal-react-native-web";
import { styles } from "./styles";
import { maskCpf, maskCreditCard, maskExpiry } from "../../utils/masks";
import apiDelivery from "../../Api/apiDelivery";
import { toast, ToastContainer } from "react-toastify";

export default function NewCard({ toggleVisible, visible, setSearchCard }) {
  const [cpf, setCpf] = useState("");
  const [cvc, setCvc] = useState("");
  const [name, setName] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focused, setFocused] = useState("");
  const [number, setNumber] = useState("");

  const cleanDateCard = () => {
    setCvc("");
    setExpiry("");
    setName("");
    setNumber("");
    setCpf("");
  };

  const checkCardBanner = (cardnumber) => {
    var cardnumber = cardnumber.replace(/[^0-9]+/g, "");

    var cards = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      master: /^5[1-5][0-9]{14}/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      amex: /^3[47][0-9]{13}/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}/,
      aura: /^(5078\d{2})(\d{2})(\d{11})$/,
    };

    for (var flag in cards) {
      if (cards[flag].test(cardnumber)) {
        return flag;
      }
    }
    return false;
  };

  const verifyCard = () => {
    if (cpf.length === 0 || cpf.length < 11) {
      toast.dark("Informe o cpf");
      return false;
    }
    if (name.length === 0) {
      toast.dark("Informe o nome do titular");
      return false;
    }
    if (number.length === 0 || number < 15) {
      toast.dark("Informe o número do cartão");
      return false;
    }
    if (expiry.length < 5) {
      toast.dark("Data de vencimento está inválida");
      return false;
    }
    if (cvc.length === 0) {
      toast.dark("Informe a código de segurança do cartão(CVV)");
      return false;
    }
    return true;
  };

  const checkDateCardUser = () => {
    const [month, year] = expiry.split("/");
    const fullYear = `20${year}`

    const data = {
      card_number: number.replace(/[^0-9]+/g, ""),
      holder: name,
      expiration_year: fullYear,
      expiration_month: month,
      cvc: cvc,
      brand: checkCardBanner(number),
    };
    apiDelivery
      .post("credit-card/validate", data)
      .then((status) => {
        if (status) {
          toggleVisible();
          return saveDataCardUser();
        }
      })
      .catch((error) => {
        const { message } = error.response.data;
        if (message === "Invalid parameters")
          return toast.dark("Parâmetros inválidos");
      });
  };

  const saveDataCardUser = () => {
    let list = JSON.parse(localStorage.getItem("history_card")) || [];
    list = list.map((item) => ({ ...item, active: false }));
    const data = {
      cpf: cpf,
      card_number: number.replace(/[^0-9]+/g, ""),
      holder: name,
      expiration_year: expiry.split("/")[1],
      expiration_month: expiry.split("/")[0],
      cvc: cvc,
      active: true,
      brand: checkCardBanner(number),
    };
    list.push(data);
    localStorage.setItem("history_card", JSON.stringify(list));
    setSearchCard(true);
    cleanDateCard();
  };

  return (
    <Modal animationType="slide" transparent={true} visible={visible}>
      <ToastContainer hideProgressBar={true} pauseOnHover />
      <View style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            toggleVisible();
          }}
        >
          <View style={styles.overlayModal}></View>
        </TouchableWithoutFeedback>

        <View style={styles.modalView}>
          <TouchableOpacity onPress={() => {
            toggleVisible()
          }}>
            <View style={styles.closeButton}>
              X
            </View>
          </TouchableOpacity>

          <Cards
            cvc={cvc}
            expiry={expiry}
            name={name}
            focused={focused}
            number={number}
            placeholders={{ name: "Seu nome aqui" }}
            locale={{ valid: "Validade" }}
          />
          <View>
            <View style={styles.containerInput}>
              <Text style={styles.labelInput}>CPF *</Text>
              <TextInput
                style={styles.input}
                value={cpf}
                placeholder={"###.###.###-##"}
                onChange={(e) => {
                  setCpf(maskCpf(e.nativeEvent.text));
                }}
              />
              <Text style={styles.labelInput}>Número *</Text>
              <TextInput
                style={styles.input}
                maxLength={19}
                onFocus={() => setFocused("number")}
                value={number}
                placeholder={"#### #### #### ####"}
                onChange={(e) => {
                  setNumber(maskCreditCard(e.nativeEvent.text));
                }}
              />
              <Text style={styles.labelInput}>Nome *</Text>
              <TextInput
                style={styles.input}
                onFocus={() => setFocused("name")}
                value={name}
                placeholder={"SEU NOME"}
                onChange={(e) => setName(e.target.value.toUpperCase())}
              />
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 20
                }}
              >
                <View style={{width: "40%"}}>
                  <Text style={[styles.labelInput, { marginRight: 10 }]}>
                    Validade *
                  </Text>
                  <TextInput
                    maxLength={5}
                    style={[styles.input]}
                    onFocus={() => setFocused("expiry")}
                    value={expiry}
                    placeholder={"##/##"}
                    onChange={(e) => {
                      setExpiry(maskExpiry(e.target.value));
                    }}
                  />
                </View>
                <View style={{width: "50%"}}>
                  <Text style={[styles.labelInput]}>
                    Código de Segurança *
                  </Text>
                  <TextInput
                    maxLength={3}
                    style={[styles.input]}
                    onFocus={() => setFocused("cvc")}
                    value={cvc}
                    placeholder={"###"}
                    onChange={(e) => setCvc(e.target.value)}
                  />
                </View>
              </View>
              <TouchableOpacity
                onClick={() => {
                  if (verifyCard()) return checkDateCardUser();
                }}
              >
                <View style={styles.confirmationButton}>
                  <Text style={{ color: "#FFF" }}>Adicionar</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>

        <TouchableWithoutFeedback
          onPress={() => {
            toggleVisible();
          }}
        >
          <View style={styles.overlayModal}></View>
        </TouchableWithoutFeedback>
      </View>
    </Modal>
  );
}
